//new api config
/* newBaseUrl = "http://13.234.37.125:8080";
newBaseUrl_oem = "http://13.233.122.53:8080";
gbrServiceUrl = "http://um.gbrservice.com";
proxyUrl = "http://proxy.gbrservice.com"; */

let ConsumerAdmin;
let AssetDeveloper;

let newBaseUrlOEM,

/*start of rakshit booking apis*/
newTransportReqUrl, 


/*end of rakshit booking apis*/
  newBaseUrl_oem,
  proxyUrl,
  gbrServiceUrl,
  newBaseUrl,
  SocketIOURL,
  baseUrl,
  newBookingUrl
  
  ;

if (process.env.REACT_APP_DEVELOPMENT_ENV === "production") {
  baseUrl = "https://registry.gbrservice.com/";
  newBaseUrlOEM = "https://content.gbrservice.com/";
  newBaseUrl_oem = "https://content.gbrservice.com/";
  proxyUrl = "https://content.gbrservice.com/";
  gbrServiceUrl = "https://iam.gbrservice.com/";
  newBaseUrl = "https://iam.gbrservice.com/";
  //newBaseUrl = "https://http://15.206.178.137:8080/";
  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
} else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging") {
  baseUrl = "https://registrystaging.gbrservice.com/";
  newBaseUrlOEM = "https://proxystaging.gbrservice.com/";
  newBaseUrl_oem = "https://proxystaging.gbrservice.com/";
  proxyUrl = "https://proxystaging.gbrservice.com/";
  gbrServiceUrl = "https://iamstaging.gbrservice.com/";
  newBaseUrl = "https://iamstaging.gbrservice.com/";

  /*start of rakshit booking apis*/
  newTransportReqUrl= 'https://logistic.gbrapp.com/'

  /*end of rakshit booking apis*/

  newBookingUrl= "https://booking.gbrapp.com/";
  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
}

/* 
let baseUrl = "http://54.210.242.209:8080/";

let newBaseUrl = 'http://44.197.120.123:8080/';
let newBaseUrl_oem = 'http://54.167.97.129:8080/';
let gbrServiceUrl = 'http://44.197.120.123:8080/';
let proxyUrl = 'http://54.167.97.129:8080/'; */
let ApplicationSpecificGwUrl = 'https://proxystaging.gbrservice.com/';
let OpUserServiceUrl = 'https://iamstaging.gbrservice.com/';
let AgriBusinessRegistryServiceUrl = 'https://registrystaging.gbrservice.com/';
let tradingServer = 'https://proxystaging.gbrservice.com';
let qrcodeBaseUrl = 'https://qrstaging.gbrservice.com/';
let liveMonitorUrl = "https://track.gbrapp.com/";
let socketUrl = 'https://track.gbrapp.com/';
let assetCurrentLocationUrl = "https://trackstaging.gbrservice.com/";
let liveEnvUrl = "https://datastaging.gbrservice.com/";
let trip_planner_instance = "http://35.89.191.242:8077/";
//invoice server
let invoiceBaseURL = "https://billstaging.gbrservice.com/";
let tempBaseUrl = "https://gendoc.gbrapp.com/";
// request rfq document
let docRequestBaseUrl = "https://order.gbrapp.com/";
// let docRequestBaseUrl = 'http://172.16.1.28:8081/';
//device cloud server
let deviceCouldServerUrl = "https://proxystaging.gbrservice.com/";

//local host server
let localHostUrl = "https://order.gbrapp.com/";
let devicesURL = "https://devicestaging.gbrservice.com/";
let tradeHubProxyUrl = "https://order.gbrapp.com/";

window.appConfig = {
  getAllUserTypes: OpUserServiceUrl + "OpUserService/getUserTypes/",
  getExecutiveDashboardData:
    newBaseUrl_oem + "AnalyticsProxy/getDashboardData/",
  getrequestdetails: tradeHubProxyUrl + "tradeHubProxy/",
  getallquotesbasedonid: tradeHubProxyUrl + "tradeHubProxy/get-all-quotes?id=",
  updaterfqstatus: tradeHubProxyUrl + "tradeHubProxy/update-rfq-status?id=",
  getallrequestidsbasedonverticalsandcategory:
    tradeHubProxyUrl + "tradeHubProxy/get-all-request-ids?",
  procurerequest: tradeHubProxyUrl + "tradeHubProxy/procure-request?request-type=",
  settingsInfoBasedonParent: newBaseUrl_oem + "QMS-Gw/settings/",
  getConsumerUserTypeRoleProfile: OpUserServiceUrl + "OpUserService/getRole/",
  getConsumerUserTypeRole: OpUserServiceUrl + "OpUserService/getRoleKeycloak/",
  getGrandParent: OpUserServiceUrl + "OpUserService/getParent/",
  productlistbasedoncategorysubcategory:
    newBaseUrl_oem + "AgriProductProxy/getproductlist/",
  productlistbasedonusernamecategory:
    newBaseUrl_oem + "AgriProductProxy/productlist/",
  requestInfoBasedonRequestid:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/getRegisterRequest/",
  upgradeRoleRequest:
    AgriBusinessRegistryServiceUrl + "AgriBusinessRegistryService/register/",
  updateRegistrationRequest:
    baseUrl + "RegisterGateway/updateRegistrationRequest/",
  SocketIOURL: "https://devicestaging.gbrservice.com/",
  //device cloud server and firmware apis
  updatefirmware: deviceCouldServerUrl + "Data-Management-Gw/updateModel",
  getfirmwareList:
    deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/getFirmwareList",
  getRoleResourceAssets: newBaseUrl_oem + "EAP/siteAssets/",
  getRoleResources: newBaseUrl + "AuthBackEndGw/getResources/",
  getRoleNameofOperator: gbrServiceUrl + "OpUserService/getRole/",
  deleteFirmware:
    deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/deleteFirmware",
  deleteFirmwareFile:
    deviceCouldServerUrl + "Cloud-Storage-Gw/deletePartnerFirmwareImageObject",
  addFirmwareFile:
    deviceCouldServerUrl + "Cloud-Storage-Gw/uploadPartnerFirmwareImageObject",
  addFirmwareMetadata:
    deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/addFirmwareMetadata",
  getCloudServer: deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/dcslist",
  devicePublish:
    deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/publishDCS",
  updateDeviceCloudStatus:
    deviceCouldServerUrl + "OP-BackEnd-Gateway/opgateway/updateDCSStatus",
  deleteDeviceCloud:
    deviceCouldServerUrl +
    "OP-BackEnd-Gateway/opgateway/deleteDeviceCloudServer",
  assetPointDelete: proxyUrl + "EAP/deletePoint/",
  updatePointName: proxyUrl + "EAP/updatePointInfo/",
  createSiteType: proxyUrl + "EAP/createSiteType/",
  deleteSiteType: proxyUrl + "EAP/deleteSiteType/",
  /* Points in IAA Management */
  //"getSolutionDevices":newBaseUrl_oem+"DMBackEndGw/DMDevice/getSolutionDevices/",
  getSolutionDevices: devicesURL + "admin/deviceprofile/",
  mapPointWithNode: newBaseUrl_oem + "EAP/mapPointWithEndNode/",
  unmapPointWithNode: newBaseUrl_oem + "EAP/unmapPointFromEndNode/",
  createPoint: newBaseUrl_oem + "EAP/createPoint/",
  getPoints: newBaseUrl_oem + "EAP/getpoints/",
  // asset distribution start
  createOrder: localHostUrl + "orderProxy/createOrder",
  getAllRequestTypes: localHostUrl + "orderProxy/getAllRequestTypes",
  getAllOrderIds: localHostUrl + "orderProxy/getAllOrderIds",

  // end
  /* USerTypes */
  createUserType: OpUserServiceUrl + "OpUserService/createUserType/",
  deleteUserType: OpUserServiceUrl + "OpUserService/deleteUserType/",
  /* End of UserTypes */
  /* invoice genration code */
  saveOrGetListDocuments: `${tempBaseUrl}docs/v1/`,
  genrateDocument: `${tempBaseUrl}gendoc/gateway/v1/`,
  // document rfq request post
  documentRfqRequest: `${docRequestBaseUrl}tradeHubProxy`,
  createQutation: `${invoiceBaseURL}gendocApi/v1/`,
  createPurchaseOrder: `${invoiceBaseURL}gendocApi/v1/`,
  createProformaInvoice: `${invoiceBaseURL}gendocApi/v1/`,
  createTaxInvoice: `${invoiceBaseURL}gendocApi/v1/`,
  createDeliveryChallan: `${invoiceBaseURL}gendocApi/v1/`,
  createDeliveryReceipt: `${invoiceBaseURL}gendocApi/v1/`,
  getVehicleTripDetails: liveMonitorUrl + "tripplanner/",
  updateDocumentStatus: baseUrl + "ContractGw/updateDocumentStatus/",
  getSignedAgreement: baseUrl + "ContractGw/getSignedAgreement/",
  updateRegistrationRequestStatus:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/updateRegistrationRequestStatus/",
  requestStatusBasedonRequestid:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/getRegisterRequestStatus/",
  getUsersRoleRequests:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/registeredUserlist/",
  getSuperRoles: OpUserServiceUrl + "OpUserService/getRoleList/",
  getRegisterAccountRequests:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/registeredUsers/",
  getOrderStatus: newBaseUrl_oem + "BookingProxy/order/",
  buyurlproduct: newBaseUrl_oem + "AgriProductProxy/updateBuyUrl/",
  updateproductinfodata: newBaseUrl_oem + "AgriProductProxy/updateInfoUrl/",
  productitemstatus: newBaseUrl_oem + "AgriProductProxy/updateItemStatus/",
  unmapproductitemwithsite: newBaseUrl_oem + "AgriProductProxy/unmapSite/",
  mapproductitemwithsite: newBaseUrl_oem + "AgriProductProxy/mapSite/",
  /*product */
  getBookingRequests: proxyUrl + "BookingProxy/bookingRequests/",
  sendBookingRequest: proxyUrl + "BookingProxy/bookingRequest/",
  getParturationInfo: proxyUrl + "EAP/getParturitionInfo/",
  getNutritionInfo: proxyUrl + "EAP/getNutritionInfo/",
  getAssetFeedback: newBaseUrl_oem + "QMS-Gw/assetFeedbacks/",
  getAssetHealthInfo: proxyUrl + "EAP/getHealthRecordInfo/",
  createSiteIssue: newBaseUrl_oem + "QMS-Gw/createIssue/",

  /* Market apis */
  DeleteProductItem: newBaseUrl_oem + "AgriProductProxy/productItem/",
  GetProductItems: newBaseUrl_oem + "AgriProductProxy/productItems/",
  ProductVideoURL: newBaseUrl_oem + "AgriProductProxy/updateProductVideoUrl/",
  UpdateLabCertificateURL: newBaseUrl_oem + "AgriProductProxy/updateLabUrl/",
  UpdateHistoryURL: newBaseUrl_oem + "AgriProductProxy/updateHistoryUrl/",
  DecrementItemCount: newBaseUrl_oem + "AgriProductProxy/decrementItemCount/",
  IncrementItemCount: newBaseUrl_oem + "AgriProductProxy/incrementItemCount/",
  UpdateItemCount: newBaseUrl_oem + "AgriProductProxy/updateItemCount/",
  UpdateProductRating: newBaseUrl_oem + "AgriProductProxy/updateRatings/",
  UpdateProductCost: newBaseUrl_oem + "AgriProductProxy/updateProductCost/",
  UpdateProductDiscount:
    newBaseUrl_oem + "AgriProductProxy/updateDiscountPrice/",
  UpdateProductStatus: newBaseUrl_oem + "AgriProductProxy/updateStatus/",
  UpdateProductQrURL: newBaseUrl_oem + "AgriProductProxy/updateProductQRCode/",
  UpdateProductCertificateURL: newBaseUrl_oem + "AgriProductProxy/updateQAUrl/",
  UpdateProductImageURL:
    newBaseUrl_oem + "AgriProductProxy/updateProductImageUrl/",
  UploadProductCertificate:
    newBaseUrl_oem + "AgriProductProxy/uploadProductCertificate/",
  UploadProductContent:
    newBaseUrl_oem + "AgriProductProxy/uploadProductContent/",
  /* End of market apis */

  getSitesBasedOnEmail: newBaseUrl_oem + "EAP/sitelist/",

  /*//////////////////Start of logistic-Manager by Biplob:*/ /////////////////////

  //newly added apis...........

  updateRouteName: liveMonitorUrl + "tripplanner/",
  updateLocationTag: liveMonitorUrl + "tripplanner/",
  getDetailsofRouteId: liveMonitorUrl + "tripplanner/",
  getDetailsOfTripId: liveMonitorUrl + "tripplanner/",
  tripSearch: liveMonitorUrl + "tripplanner/",
  getOrderStatus: newBaseUrl_oem + "BookingProxy/order/",
  createModel: newBaseUrl_oem + "EAP/addModel/",

  //end of newly added apis.................
  getAssetsCountInfo: newBaseUrl_oem + "EAP/getAssetsCount/",
  getDeviceCountInfo: newBaseUrl_oem + "DMBackEndGw/DMDevice/getDevicesCount/",
  getDriversInfo: OpUserServiceUrl + "OpUserService/getUsersCount/",
  getTripPlannerInfo: liveMonitorUrl + "tripplanner/",
  getLocationInfo: liveMonitorUrl + "tripplanner/",
  getRouteInfo: liveMonitorUrl + "tripplanner/",
  getTripInfo: liveMonitorUrl + "tripplanner/",
  addLocationData: liveMonitorUrl + "tripplanner/",
  getOrderListInfo: proxyUrl + "BookingProxy/allBookings/consumer2/",
  getVehicleList: proxyUrl + "EAP/getSolutionAssets/",
  getDrivers: OpUserServiceUrl + "OpUserService/getAllConsumerUsers/",
  getRoute: liveMonitorUrl + "tripplanner/",
  createBooking: proxyUrl + "BookingProxy/createBooking/consumer2/",
  addRouteApi: liveMonitorUrl + "tripplanner/",
  addTripApi: liveMonitorUrl + "tripplanner/",
  getVehicleLocation: liveMonitorUrl + "livemonitoring/data/live/",
  getVehicleTrip: liveMonitorUrl + "tripplanner/",
  geofenceDetails: liveMonitorUrl + "geofence/",
  createGeofence: liveMonitorUrl + "geofence/",
  delLocation: liveMonitorUrl + "tripplanner/",
  getMonthlyTrips: liveMonitorUrl + "tripplanner/",
  delRoute: liveMonitorUrl + "tripplanner/",
  delTrip: liveMonitorUrl + "tripplanner/",
  transportManagerlist: newBaseUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getAllUsers: newBaseUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getLogisticManagerCount: gbrServiceUrl + "AuthBackEndGw/getUsersCount/",
  getBookingReq: proxyUrl + "BookingProxy/bookingRequest/",
  mapWithTrip: proxyUrl + "BookingProxy/mapBookingIdWithTrip/consumer2/",
  postTripMapRequest: newBaseUrl_oem + "BookingProxy/mapBookingIdWithTrip/",
  postTripUnmapReques: newBaseUrl_oem + "BookingProxy/unmapBookingIdFromTrip/",
  postDriverMapRequest:
    newBaseUrl_oem + "BookingProxy/mapBookingIdWithTransportUser/",
  postDriverUnmapRequest:
    newBaseUrl_oem + "BookingProxy/unmapBookingIdFromTransportUser/",
  getBookingDetails: newBaseUrl_oem + "BookingProxy/getBookingDetails/",
  getAllBookingsDetails: newBaseUrl_oem + "BookingProxy/getAllBooking/",
  lastLocationDetails: liveMonitorUrl + "livemonitoring/data/live/",
  vehicleStatus: liveMonitorUrl + "livemonitoring/data/live/",
  updateGeofenceOfAssets: newBaseUrl_oem + "EAP/updateAssetGeofence/",
  getDetailsOfLocId: liveMonitorUrl + "tripplanner/",

  //Newly added:
  updateGeofenceDetails: liveMonitorUrl + "geofence/",
  delGeofence: liveMonitorUrl + "geofence/",
  geofenceDetailOfAllVehicle: liveMonitorUrl + "geofence/",
  updateTripStatus: liveMonitorUrl + "tripplanner/",
  updateTripDetails: liveMonitorUrl + "tripplanner/",
  getVehicleSchedule: liveMonitorUrl + "tripplanner/",
  getDriverScheduleForLm: liveMonitorUrl + "tripplanner/",


  /* start of rakshit Booking apis*/
getTransportReqDetails: newTransportReqUrl + 'tAndsServer/getRequestDetails/',


  /*End of rakshit booking apis*/

  /*////////////////End of Logistic-Manager //////////////////////*/

  /////////////*start of transportuser by biplob/////////////////////*/

  // start of new rakshit apis(version-1)
  getOrderStatusDetails: tradeHubProxyUrl+ "tradeHubProxy/",
  getOrderStatusApi: tradeHubProxyUrl + "tradeHubProxy/",
  getMyBooking: newBookingUrl + "Bookings/getMyAssignedBookings/consumer2/",
  getDriverOrder: newBookingUrl + `Bookings/getOrders/consumer2/`,
  getAllOrderCount: newBookingUrl + `Bookings/getOrdersCount/consumer2/`,
  updateOrderStatus: tradeHubProxyUrl + "tradeHubProxy/",



// end of rakshit apis(version-1)

// start of rakshit apis (version-2)
getBookingCount: newBookingUrl + 'Bookings/getBookingCount/consumer2/',
getAllBookingIds: newBookingUrl + 'Bookings/getAllBookings/consumer2/',
updateBookingStatus: newBookingUrl + 'Bookings/updateBookingStatus/',
getBookingDetailsBasedOnBookingId: newBookingUrl + 'Bookings/',
updateBookingStatus: newBookingUrl + 'Bookings/updateBookingStatus/',
updateBookingStatusRakshitApi:  newBookingUrl + 'Bookings/updateCurrentBookingStatus/',
getDocLinksForOrder: newBookingUrl + 'Bookings/getBookingDetails?networkId=consumer2',
getLatestOrderStatus: tradeHubProxyUrl +'tradeHubProxy/',


// end of rakshit apis (version-2)
  getTripDetailsDriver: liveMonitorUrl + "tripplanner/consumer2/",
  getDriverSchedule: liveMonitorUrl + "tripplanner/consumer2/",
  getMyorderDetails: newBaseUrl_oem + `TradingGw/getBuyRequestStatus/`,
  PostMyOrderStatus: newBaseUrl_oem + `TradingGw/updateLegacyAssetOrderStatus/`,
  getMyRoute: liveMonitorUrl + `tripplanner/consumer2/`,

  getFinalTripStatus: liveMonitorUrl + `tripplanner/consumer2/`,
  getFinalTripStatus1: liveMonitorUrl + `tripplanner/consumer2/`,

  createNode: newBaseUrlOEM + "EAP/nodeSpecification/",
  mappedAssetsWithNode: newBaseUrlOEM + "EAP/siteAssets/",
  postAccountUpgradationReq: baseUrl + "AgriBusinessRegistryService/register/",
  getAccountUpgradationList:
    baseUrl + "AgriBusinessRegistryService/registeredUserlist/",
  getAccountUpgradationReqStatus:
    baseUrl + "AgriBusinessRegistryService/getRegisterRequestStatus/",
  MapLegacyAssetToNode: newBaseUrl_oem + "EAP/maplegacyassettosite/",
  getTuModels: newBaseUrl_oem + "EAP/models/",
  getVehiclesBasedOnModel: newBaseUrl_oem + 'EAP/getSolutionAssets/',
  // deleteNode:  newBaseUrl_oem+ 'EAP/delete/node/',
  /*///////////////end of transportuser*/ //////////////////

  generateSiteQrCode: newBaseUrl_oem + "QR-Code-Proxy/generateSite/consumer2/",
  /* Start of product apis */
  AgriProductProxey: newBaseUrl_oem + "AgriProductProxy/productInfo/",
  AgriProductProxeyItem: newBaseUrl_oem + "AgriProductProxy/productItem/",
  AgriProductProxeyDelete: newBaseUrl_oem + "AgriProductProxy/product/",
  GetAgriProductProxeyData: newBaseUrl_oem + "AgriProductProxy/products/",
  getAllAssetsBasedonModel: proxyUrl + "EAP/getSolutionAssets/",
  GetAgriProductProxeyDataBasedOnModel:
    newBaseUrl_oem + "AgriProductProxy/allproducts/",
  /* end of product apis */
  siteVetVisitRequests: proxyUrl + "FarmerProxy/vetvisitrequest/",
  siteEnv: proxyUrl + "IOB-Proxy/api/v1/consumer2/solution/",
  operatorOption: proxyUrl + "IOB-Proxy/api/v1/consumer2/solution/",
  marketOwners: proxyUrl + "EAP/getallsites/",
  marketPriceRequests: proxyUrl + "ContentGw/contentlist/",
  AssetCertifiedConsultant:
    gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  contentInfoBasedOnRequestId: proxyUrl + "ContentGw/contentInformation/",
  updateNode: newBaseUrl_oem + "EAP/update/node/",
  deleteNode: newBaseUrl_oem + "EAP/delete/node/",
  assetReports: newBaseUrl_oem + "QMS-Gw/assetReports/",
  getHealthStatusBasedOnAssetId:
    newBaseUrl_oem + "AnalyticsProxy/assetHealthStatus/",
  getAttendanceLocationInfo: newBaseUrl_oem + "EAP/getAttendanceLocationInfo/",
  getSiteReports: newBaseUrl_oem + "QMS-Gw/reports/",
  siteIssue: proxyUrl + "QMS-Gw/issues/",
  getGlobalUsers: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getSHG: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  updateWhitelabelCost: newBaseUrl_oem + "EAP/updateAssetStatus/",
  updateSellingCost: proxyUrl + "EAP/addSellingCost/",
  unmapExistingResourceFromRole:
    OpUserServiceUrl + "OpUserService/deleteResource/",
  getCategoryContentsBasedonCategoryid: proxyUrl + "ContentGw/contentlist/",
  getAllMSMEConsumerUsers:
    OpUserServiceUrl + "OpUserService/getAllConsumerUsers/",
  unmapUserRole: OpUserServiceUrl + "OpUserService/unmapConsumerUserRole/",
  addSiteAlarm: newBaseUrl_oem + "AdvisoryProxy/sitealarms/",
  getSiteAlarms: newBaseUrl_oem + "AdvisoryProxy/sitealarms/",
  getAssetAlarms: newBaseUrl_oem + "AdvisoryProxy/assetalarms/",
  getAssetsBasedonAll: newBaseUrl_oem + "EAP/getReadyforWhitelabelAssets/",

  getRoleResources: OpUserServiceUrl + "OpUserService/getResources/",
  getQrCodeForModel: newBaseUrl_oem + "QR-Code-Proxy/modelqrcode/",
  getQrCodeForAsset: newBaseUrl_oem + "QR-Code-Proxy/assetqrcode/",
  getQrCodeForSite: newBaseUrl_oem + "QR-Code-Proxy/siteqrcode/",
  getProcessHealthStatusExe:
    newBaseUrl_oem + "AnalyticsProxy/getProcessHealthStatus/",
  getAssetHealthStatusExe:
    newBaseUrl_oem + "AnalyticsProxy/getExecutiveAssetsHealthStatus/",
  getAssetStatisticsExe:
    newBaseUrl_oem + "AnalyticsProxy/getExecutiveAssetStatistics/",
  assetCurrentLocation: liveMonitorUrl + "livemonitoring/data/live/",
  addAssetFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  getAssetIssues: newBaseUrl_oem + "QMS-Gw/assetIssues/",
  createAssetIssue: newBaseUrl_oem + "QMS-Gw/createIssue/",
  liveMonitoringData: socketUrl + "livemonitoring/data/live/",
  getProcessHealthStatusEx:
    newBaseUrl_oem + "AnalyticsProxy/getProcessHealthStatus/",
  getAssetHealthStatusEx:
    newBaseUrl_oem + "AnalyticsProxy/getExecutiveAssetsHealthStatus/",
  getAssetStatisticsEx:
    newBaseUrl_oem + "AnalyticsProxy/getExecutiveAssetStatistics/",
  getDashboardDataEx: newBaseUrl_oem + "AnalyticsProxy/getDashboardData/",
  getModels: newBaseUrl_oem + "EAP/getModel/",
  getModelInfo: proxyUrl + "EAP/modeldetails/",
  generateQrCode: qrcodeBaseUrl + "generate",
  generateModelQrCode:
    newBaseUrl_oem + "QR-Code-Proxy/generateModel/consumer2/",
  generateQrCodeWithId:
    newBaseUrl_oem + "QR-Code-Proxy/generateWithId/consumer2/",
  generateBulkQrCodes: qrcodeBaseUrl + "generateBulk",
  downloadQrCode: qrcodeBaseUrl + "download?file=",
  getAccessToken:
    OpUserServiceUrl + "AuthBackEndGw/refresh_token/getaccessToken/",
  createAccessToken: OpUserServiceUrl + "AuthBackEndGw/token/",
  getToken: OpUserServiceUrl + "AuthBackEndGw/getUserToken/",
  resetUserPassword: OpUserServiceUrl + "AuthBackEndGw/resetPassword/",
  forgetUsername: OpUserServiceUrl + "AuthBackEndGw/forgetUsername/",
  forgetPassword: OpUserServiceUrl + "AuthBackEndGw/forgetPassword/",
  registerUser: baseUrl + "RegisterGateway/registerUser/",
  consumerRequestInfo: OpUserServiceUrl + "OpUserService/consumerReqInfo/",
  getConsumerUserType: OpUserServiceUrl + "OpUserService/getUserType/",
  getStepperCounter: OpUserServiceUrl + "OpUserService/userJourneyDetails/",
  getAllAssetsOfAssetDeveloper: proxyUrl + "EAP/getSolutionAssets/",
  getAllRoles: OpUserServiceUrl + "OpUserService/roles/",
  getAllConsumerUsers: OpUserServiceUrl + "OpUserService/consumerUser/",
  getMappedRoles: OpUserServiceUrl + "OpUserService/getUsersRoles/",
  viewAllSites: proxyUrl + "EAP/allsites/",
  deleteContent: newBaseUrl_oem + "ContentGw/content/",
  createMapping: newBaseUrl_oem + "EAP/maplegacyassettosite/",
  createUnMapping: newBaseUrl_oem + "EAP/unmapSiteAsset/",
  assetStatusUpdate: proxyUrl + "EAP/updateAssetStatus/",
  assetDelete: proxyUrl + "EAP/deleteAsset/",
  getAllSiteTypes: proxyUrl + "EAP/getSiteTypes/",
  getUnsignedAgreements: proxyUrl + "ContentGw/contentList/",
  viewAllTenderSites: proxyUrl + "EAP/allsites/",
  mappedTenderLegacyAssets: newBaseUrl_oem + "EAP/siteAssets/",
  getAllTenderAssetsData: proxyUrl + "EAP/getSolutionAssets/",
  assetMapingtoTenderSite: newBaseUrl_oem + "EAP/maplegacyassettosite/",
  getAllTenderSiteTypes: proxyUrl + "EAP/getSiteTypes/",
  createTenderSite: proxyUrl + "OP-BackEnd-Gateway/opgateway/createsite/",
  createSiteWithNodeId:
    proxyUrl + "OP-BackEnd-Gateway/opgateway/sitecreationinfo/",
  viewListOfOperators: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getRole: newBaseUrl + "AuthBackEndGw/getRole/",
  getRoleResourceId: gbrServiceUrl + "AuthBackEndGw/getResources/",
  getAssetsOfSite: proxyUrl + "EAP/siteAssets/",
  viewListOfDeveloperQA: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getDevRole: gbrServiceUrl + "AuthBackEndGw/getRole/",
  getDevRoleResourceId: gbrServiceUrl + "AuthBackEndGw/getResources/",
  getDevAssetsOfSite: proxyUrl + "EAP/siteAssets/",
  viewListOfExecutives: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getConsumerInfoRequest:
    AgriBusinessRegistryServiceUrl + "AgriBusinessRegistryService/userInfo/",
  "Production-Team": gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getTeamRole: gbrServiceUrl + "AuthBackEndGw/getRole/",
  getTeamRoleResourceId: gbrServiceUrl + "AuthBackEndGw/getResources/",
  viewListProductionManagers:
    gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  viewListOfProductionTeams:
    gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getProductManagerRole: gbrServiceUrl + "AuthBackEndGw/getRole/",
  getRoleProductionManagersResourceId:
    gbrServiceUrl + "AuthBackEndGw/getResources/",
  viewListOfFarmers: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getFarmerNodes: proxyUrl + "EAP/nodeSpecifications/",
  allApprovalRequests: proxyUrl + "FarmerProxy/allapprovalrequest/",
  allApprovalRequestsByNode: proxyUrl + "FarmerProxy/approvalrequests/",
  allGrowRequests: proxyUrl + "FarmerProxy/allgrowrequest/",
  allGrowRequestsByNode: proxyUrl + "FarmerProxy/growrequests/",
  allSellRequests: proxyUrl + "FarmerProxy/allsellrequest/",
  allSellRequestsByNode: proxyUrl + "FarmerProxy/sellrequests/",
  allLoanRequests: proxyUrl + "FarmerProxy/allloanrequest/",
  allLoanRequestsByNode: proxyUrl + "FarmerProxy/loanrequests/",
  allVetVisitRequests: proxyUrl + "FarmerProxy/allvetvisitrequest/",
  allVetVisitRequestsByNode: proxyUrl + "FarmerProxy/vetvisitrequests/",
  allBuyInputRequests: proxyUrl + "FarmerProxy/allbuyinputrequest/",
  allBuyInputRequestsByNode: proxyUrl + "FarmerProxy/buyinputrequests/",
  allStoreRequests: proxyUrl + "FarmerProxy/allstorerequest/",
  allStoreRequestsByNode: proxyUrl + "FarmerProxy/storerequests/",
  allAdvisoryRequests: proxyUrl + "FarmerProxy/alladvisoryrequest/",
  allAdvisoryRequestsByNode: proxyUrl + "FarmerProxy/advisoryrequests/",
  setNodeStatus: proxyUrl + "EAP/updateNodeStatus/",
  viewFarmerInfo: OpUserServiceUrl + "OpUserService/consumerReqInfo/",
  approvalReqStatus: proxyUrl + "FarmerProxy/updateApprovalRequest/",
  deleteContentSubCategory: proxyUrl + "ContentGw/deleteContentSubCategory/",
  growReqStatus: proxyUrl + "FarmerProxy/updateGrowRequest/",
  sellReqStatus: proxyUrl + "FarmerProxy/updateSellRequest/",
  LoanReqStatus: proxyUrl + "FarmerProxy/updateLoanRequest/",
  VetReqStatus: proxyUrl + "FarmerProxy/updateVetVisitRequest/",
  BuyReqStatus: proxyUrl + "FarmerProxy/updateBuyInputRequest/",
  StoreReqStatus: proxyUrl + "FarmerProxy/updateStoreRequest/",
  viewSettingsInfo: newBaseUrl_oem + "QMS-Gw/getSettingsInfo/",
  mappedLegacyAssets: newBaseUrl_oem + "EAP/siteAssets/",
  getAllAssetsData: proxyUrl + "EAP/getSolutionAssets/",
  assetMapingtoSite: newBaseUrl_oem + "EAP/maplegacyassettosite/",
  siteDeleteHandler: proxyUrl + "OP-BackEnd-Gateway/opgateway/deletesite/",
  siteRating: newBaseUrl_oem + "QMS-Gw/ratings/",
  createSite: proxyUrl + "OP-BackEnd-Gateway/opgateway/sitecreation/",
  addSiteRating: newBaseUrl_oem + "QMS-Gw/ratings/",
  siteInfo: newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/siteInfo/",
  updateSiteInfo: proxyUrl + "EAP/updateSiteInfo/",
  viewAllFarms: proxyUrl + "EAP/getallsites/",
  viewAllFarmRelatedAssets: newBaseUrl_oem + "EAP/siteAssets/",
  viewFarmMapRelatedAssets: newBaseUrl_oem + "EAP/siteAssets/",
  farmsDropDownList: proxyUrl + "EAP/getallsites/",
  getConsumerNodeInfo: proxyUrl + "EAP/nodeSpecifications/",
  updateConsumerNodeStatus: proxyUrl + "EAP/updateNodeStatus/",
  viewNodeStatus: proxyUrl + "EAP/getNodeStatus/",
  createConsumerUserOfIndividual: gbrServiceUrl + "AuthBackEndGw/userCreation/",
  deleteConsumerUserIndividual: gbrServiceUrl + "AuthBackEndGw/userDeletion/",
  cancelGrowRequest: proxyUrl + "FarmerProxy/cancelRequest/",
  cancelSellRequest: proxyUrl + "FarmerProxy/cancelRequest/",
  cancelLoanRequest: proxyUrl + "FarmerProxy/cancelRequest/",
  cancelVetRequest: proxyUrl + "FarmerProxy/cancelRequest/",
  resetConsumerUserPasswordIndividual:
    OpUserServiceUrl + "AuthBackEndGw/createCredentials/",
  updateConsumerUserInfoIndividual:
    newBaseUrl + "AuthBackEndGw/updateConsumerUserInfo/",
  ViewAssetOnboardings: newBaseUrl_oem + "EAP/getReadyForOnboardAssets/",
  updateReadyForOnboardAssetStatus: newBaseUrl_oem + "EAP/updateAssetStatus/",
  AttendanceLocationHistory: newBaseUrl_oem + "EAP/getAttendanceLocations/",
  NewAttendanceLocation: newBaseUrl_oem + "EAP/addAttendanceLocation/",
  DeleteNewAttendanceLocation: newBaseUrl_oem + "EAP/deleteAttendanceLocation/",
  UpdateAttendanceLocation: newBaseUrl_oem + "EAP/updateAttendanceLocation/",
  getBirthDetails: newBaseUrl_oem + "EAP/getBirthInfos/",
  getBirthInfo: newBaseUrl_oem + "EAP/getBirthInfo/",
  addBirthInfo: newBaseUrl_oem + "EAP/addBirthInfo/",
  deleteBirthInfo: newBaseUrl_oem + "EAP/deleteBirthInfo/",
  updateBirthInfo: newBaseUrl_oem + "EAP/updateBirthInfo/",
  getHealthRecords: newBaseUrl_oem + "EAP/getHealthRecord/",
  addHealthRecords: newBaseUrl_oem + "EAP/addHealthRecord/",
  deleteHealthRecord: newBaseUrl_oem + "EAP/deleteHealthRecord/",
  updateHealthRecord: newBaseUrl_oem + "EAP/updateHealthRecord/",
  getNutrition: newBaseUrl_oem + "EAP/getNutritions/",
  addNutrition: newBaseUrl_oem + "EAP/addNutrition/",
  deleteNutrition: newBaseUrl_oem + "EAP/deleteNutrition/",
  updateNutrition: newBaseUrl_oem + "EAP/updateNutritionInfo/",
  getWeighingDetails: newBaseUrl_oem + "EAP/getWeighingDetails/",
  addWeighingDetails: newBaseUrl_oem + "EAP/addWeighingDetails/",
  deleteWeighingDetails: newBaseUrl_oem + "EAP/deleteWeighingDetails/",
  updateWeighingDetails: newBaseUrl_oem + "EAP/updateWeighingDetails/",
  getParturitionDetails: newBaseUrl_oem + "EAP/getParturitionDetails/",
  addParturitionDetails: newBaseUrl_oem + "EAP/addParturitionDetails/",
  deleteParturitionDetails: newBaseUrl_oem + "EAP/deleteParturitionDetails/",
  updateParturitionDetails: newBaseUrl_oem + "EAP/updateParturitionInfo/",
  getMortalityDetails: newBaseUrl_oem + "EAP/getMortalityDetails/",
  addMortalityDetails: newBaseUrl_oem + "EAP/addMortalityDetails/",
  deleteMortalityDetails: newBaseUrl_oem + "EAP/deleteMortalityDetails/",
  updateMortalityDetails: newBaseUrl_oem + "EAP/updateMortalityInfo/",
  getAssetOptionalStatusDetails:
    newBaseUrl_oem + "EAP/getAssetOperationalStatus/",
  deleteAssetOperationalStatusDetails:
    newBaseUrl_oem + "EAP/deleteAssetOperationalStatus/",
  updateAssetOperationalStatusDetails:
    newBaseUrl_oem + "EAP/updateAssetOperationalStatusInfo/",
  getLegacyAssetInfo: newBaseUrl_oem + "EAP/solutionAssetInfo/",
  getSellingCostDetails: proxyUrl + "EAP/getSellingCostDetails/",
  getSitePatrollingDetails: proxyUrl + "EAP/getPatrollingInfo/",
  deleteSitePatrolingDetails: newBaseUrl_oem + "EAP/deletePatrollingDetails/",
  updateSitePatrolingDetails: newBaseUrl_oem + "EAP/updatePatrollingInfo/",
  getAssetPatrollingDetails: proxyUrl + "EAP/getPatrollingInfo/",
  deleteAssetPatrolingDetails: newBaseUrl_oem + "EAP/deletePatrollingDetails/",
  updateAssetPatrolingDetails: newBaseUrl_oem + "EAP/updatePatrollingInfo/",
  siteFeedbackDetails: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  assetFeedbackDetails: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  siteIssueCreate: proxyUrl + "QMS-Gw/createIssue/",
  getSiteIssueData: proxyUrl + "QMS-Gw/issues/",
  dailyReportDetails: newBaseUrl_oem + "EAP/dailyReportList/",
  createDailyReport: newBaseUrl_oem + "EAP/dailyReport/",
  getGroupNutritionData: proxyUrl + "EAP/getNutritions/",
  addNutritionData: proxyUrl + "EAP/addNutrition/",
  deleteGroupNutrition: proxyUrl + "EAP/deleteNutrition/",
  updateGroupNutrition: proxyUrl + "EAP/updateNutritionInfo/",
  siteAttendanceLocationHistory: newBaseUrl_oem + "EAP/getAttendanceLocations/",
  siteNewAttendanceLocation: newBaseUrl_oem + "EAP/addAttendanceLocation/",
  siteDeleteNewAttendanceLocation:
    newBaseUrl_oem + "EAP/deleteAttendanceLocation/",
  siteUpdateAttendanceLocation:
    newBaseUrl_oem + "EAP/updateAttendanceLocation/",
  siteGrowRequests: proxyUrl + "FarmerProxy/growrequest/",
  addGrowRequest: proxyUrl + "FarmerProxy/growRequest/",
  siteGrowReqStatus: proxyUrl + "FarmerProxy/updateGrowRequest/",
  siteCancelGrowReqStatus: proxyUrl + "FarmerProxy/cancelRequest/",
  siteSellRequests: proxyUrl + "FarmerProxy/sellrequest/",
  siteSellReqStatus: proxyUrl + "FarmerProxy/updateSellRequest/",
  siteCancelSellReqStatus: proxyUrl + "FarmerProxy/cancelRequest/",
  siteLoanRequests: proxyUrl + "FarmerProxy/loanrequest/",
  siteLoanReqStatus: proxyUrl + "FarmerProxy/updateLoanRequest/",
  siteCancelLoanReqStatus: proxyUrl + "FarmerProxy/cancelRequest/",
  siteVetVisitReqStatus: proxyUrl + "FarmerProxy/updateLoanRequest/",
  siteCancelVetVisitReqStatus: proxyUrl + "FarmerProxy/cancelRequest/",
  assetIssueCreate: proxyUrl + "QMS-Gw/createIssue/",
  getAssetIssueData: proxyUrl + "QMS-Gw/assetIssues/",
  getNodeInfoBasedOnNodeId: proxyUrl + "EAP/getNodeInfo/",
  getMyCategoryTender: proxyUrl + "ContentGw/contentlist/",
  getListOfCategorys: proxyUrl + "ContentGw/getCategories/",
  getCategoryRelatedSubCategories:
    proxyUrl + "ContentGw/getAllContentSubCategories/",

  getConsumerUserInfoForAD: baseUrl + "RegisterGateway/userInfo/",
  getSellerInfo: baseUrl + "RegisterGateway/userInfo/",
  getAllSites: proxyUrl + "EAP/allsites/",
  getCategory: proxyUrl + "ContentGw/getCategoryId/",
  getSubCategoriesForAD: proxyUrl + "ContentGw/getAllContentSubCategories/",
  getAllContentsList: proxyUrl + "ContentGw/contentList/",
  getCategoryTender: proxyUrl + "ContentGw/contentlist/",
  getTendersByDistricts: proxyUrl + "ContentGw/getSiteContent/",
  getTendersByOrganization: proxyUrl + "ContentGw/contentList/",
  getWhiteLabelAssetsForAD: proxyUrl + "EAP/getSolutionAssets/",
  createLegacyAsset: proxyUrl + "EAP/createAsset/",
  createContentSubCategory: proxyUrl + "ContentGw/contentsubcategory/",
  createAssetOEM:
    AgriBusinessRegistryServiceUrl + "RegisterGateway/registerUser/",
  createConsumerUser:
    AgriBusinessRegistryServiceUrl + "RegisterGateway/userCreation/",
  resetConsumerUserPassword:
    OpUserServiceUrl + "AuthBackEndGw/createUserCredential/",
  updateConsumerUserInfo:
    OpUserServiceUrl + "OpUserService/updateConsumerUserInfo/",
  deleteConsumerUser:
    AgriBusinessRegistryServiceUrl + "RegisterGateway/userDeletion/",
  getParentId: OpUserServiceUrl + "OpUserService/getParentId/",
  getAllDistricts: proxyUrl + "EAP/allsites/",
  createContent: proxyUrl + "ContentGw/content/",
  uploadKYC: newBaseUrl_oem + "Contract-Upload-Gw/uploadDocument/",
  sendEmail: proxyUrl + "NotificationUA/sendEmail/",
  sendSMS: proxyUrl + "NotificationUA/sendSms/",
  updateWhiteLabelStatus: proxyUrl + "TradingGw/updatewhitelabelRequest/",
  updateNodeStatus: proxyUrl + "EAP/updateNodeStatus/",
  addNodeToFarmer: proxyUrl + "EAP/nodeSpecification/",
  addGrowerRequest: proxyUrl + "FarmerProxy/growRequest/",
  addSellRequest: proxyUrl + "FarmerProxy/sellRequest/",
  addLoanRequest: proxyUrl + "FarmerProxy/loanRequest/",
  addVetRequest: proxyUrl + "FarmerProxy/vetvisitRequest/",
  getAssetOEMSofConsumerAdmin:
    newBaseUrl + "AuthBackEndGw/getAllConsumerUsers/",
  customerSupport: newBaseUrl_oem + "QMS-Gw/settings/",
  getLogisticProviders: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getAssetDistributors: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getAssetSellers: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getAllAssetRequests: proxyUrl + "TradingGw/getbuyrequests/",
  getClinicAdmins: gbrServiceUrl + "AuthBackEndGw/getAllConsumerUsers/",
  getAllWhiteLabelRequests: newBaseUrl_oem + "TradingGw/getwhitelabelrequests/",
  getAssetOEMInfo: OpUserServiceUrl + "OpUserService/consumerReqInfo/",
  getLogisticProviderInfo: baseUrl + "RegisterGateway/userInfo/",
  getAssetHealthStatus: newBaseUrl_oem + "AnalyticsProxy/assetHealthStatus/",
  getProcessHealthStatus:
    newBaseUrl_oem + "AnalyticsProxy/processHealthStatus/",
  getComplaints: newBaseUrl_oem + "QMS-Gw/getComplaint/",
  getKYCDocuments: baseUrl + "ContractGw/getKycDocument/",
  getAllUnsignedAgreementsForAD: baseUrl + "ContractGw/getDocument/",
  getAllSignedAgreementsForAD: baseUrl + "ContractGw/getDocument/",
  trackTheOrder: proxyUrl + "TradingGw/getbuyrequests/",
  getNodeInfo: proxyUrl + "EAP/nodeSpecifications/",
  getFarmerNodeInfo:
    AgriBusinessRegistryServiceUrl +
    "AgriBusinessRegistryService/consumerUserRequestInfo/",
  viewNodesOfFarmer: proxyUrl + "EAP/nodeSpecifications/",
  getRoleProfileBasedOnRoleName: gbrServiceUrl + "AuthBackEndGw/getResources/",
  createCustomerCare: newBaseUrl_oem + "QMS-Gw/addSettingsInfo/",
  updateAssetRequestStatusForAd: proxyUrl + "TradingGw/updateBuyRequest/",
  createRole: OpUserServiceUrl + "OpUserService/roleCreation/",
  updateCustomerCare: newBaseUrl_oem + "QMS-Gw/updateSettingsInfo/",
  deleteCustomerCare: newBaseUrl_oem + "QMS-Gw/deleteSettingsInfo/",
  deleteRole: OpUserServiceUrl + "OpUserService/deleteConsumerRole/",
  deleteTenderSite: proxyUrl + "OP-BackEnd-Gateway/opgateway/deletesite/",
  updateRole: OpUserServiceUrl + "OpUserService/roleUpdate/",
  updateRoleOfUser:
    OpUserServiceUrl + "OpUserService/updateMappedConsumerRole/",
  mapRole: OpUserServiceUrl + "OpUserService/mapUserRole/",
  uploadAgreementForAD: newBaseUrl_oem + "Contract-Upload-Gw/updDocument/",
  uploadAgreement: proxyUrl + "Cloud-Storage-Gw/content/",
  getFarmUpdates: proxyUrl + "ContentGw/contentlist/",
  getFarmUpdatesFromAll: proxyUrl + "ContentGw/contentlist/",
  getCategoriesBasedOnOEM: proxyUrl + "ContentGw/getAllCategories/",
  getSubCategoriesForOEM: proxyUrl + "ContentGw/getAllContentSubCategories/",
  getUserJourney: baseUrl + "OpUserService/userJourneyDetails/",
  addAssetOparationalStatusDetails:
    newBaseUrl_oem + "EAP/addOperationalStatus/",
  addSellingCostDetails: proxyUrl + "EAP/addSellingCost/",
  addSitePatrolingDetails: newBaseUrl_oem + "EAP/addPatrollingDetails/",
  addAssetPatrolingDetails: newBaseUrl_oem + "EAP/addPatrollingDetails/",

  //"getWhiteLabelStatus": newBaseUrl_oem+"TradingGw/whitelabelstatus/",

  getSolutionsIdList:
    newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/solutions/",
  getAssetVerticalsIdList: newBaseUrl_oem + "EAP/getAssetVerticals/",
  getModelsList: newBaseUrl_oem + "EAP/models/",
  getCategoryTypesList: newBaseUrl_oem + "EAP/categoryType/",
  getAssetTypesList: newBaseUrl_oem + "EAP/assetType/",
  getSitesList: newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/sites/",
  getSitesAssetsList: newBaseUrl_oem + "EAP/siteAssets/",
  getAssetInfo: newBaseUrl_oem + "EAP/solutionAssetInfo/",
  sendWhitelabelRequest: newBaseUrl_oem + "TradingGw/whitelabelRequest/",
  getSolutionAssets: newBaseUrl_oem + "EAP/getSolutionAssets/",
  getSolutionFeedbacks: newBaseUrl_oem + "QMS-Gw/feedbacks/",
  getSiteComplaints: newBaseUrl_oem + "QMS-Gw/complaints/",
  getDashboardData: newBaseUrl_oem + "AnalyticsProxy/getAssetOEMDashboardData/",
  getAssetsCount: newBaseUrl_oem + "AnalyticsProxy/getAssetsCount/",
  //"getWhiteLabelAsserts": newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
  getAssetOEMData: newBaseUrl_oem + "AnalyticsProxy/getAssetOEMData/",
  getProfitSummary: newBaseUrl_oem + "AnalyticsProxy/getProfitSummary/",
  getStatementSummary: newBaseUrl_oem + "AnalyticsProxy/getStatementSummary/",
  getProfitStatementSummary:
    newBaseUrl_oem + "AnalyticsProxy/getProfitStatementSummary/",
  getAllSolutionsSite:
    newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/solutionSites/",
  getAllAssociatedSolutionSites: newBaseUrl_oem + "EAP/associatedSites/",
  getWalletBalance: newBaseUrl_oem + "Wallet-Payment-Gw/walletBalance/",
  getAllSolutions:
    newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/enterprise/allSolutions/",
  getAllAssociatedSolutions: newBaseUrl_oem + "EAP/associatedAssetDevelopers/",
  getBankDetails: newBaseUrl_oem + "Wallet-Payment-Gw/bankDetails/",
  // "getKycDocument": newBaseUrl_oem+"ContractGw/getKycDocument/",
  getKycDocument: baseUrl + "ContractGw/getKycDocument/",
  addBankDetails: newBaseUrl_oem + "Wallet-Payment-Gw/bankdetails/",

  paymentHistory: newBaseUrl_oem + "Wallet-Payment-Gw/paymentHistory/",
  // "getFarmUpdates": newBaseUrl_oem+"ContentGw/getapps/",
  registerConsumerUser: baseUrl + "RegisterGateway/registerConsumerUser/",
  // Done by Srini
  myAssetsList: newBaseUrl_oem + "EAP/OEMAssets/",
  getSitesDetails:
    newBaseUrl_oem + "OP-BackEnd-Gateway/opgateway/getSiteDetails/",
  getAssetDetails: newBaseUrl_oem + "EAP/assetDetails/",
  getWhiteLabelAssets: newBaseUrl_oem + "AnalyticsProxy/getsites/", //These are sites only
  getGrowerDetails: baseUrl + "ApplicationSpecificGw/assetdeveloperDetails/",
  getModelDetails: newBaseUrl_oem + "AnalyticsProxy/getModelDetails/",
  whiteLabelAssetsBasedonSite:
    newBaseUrl_oem + "EAP/getReadyforWhitelabelAsset/",
  whiteLabelAssetsBasedonAssetDeveloper:
    newBaseUrl_oem + "EAP/getReadyforWhitelabelAssets/",
  whiteLabelAssetsBasedonModel:
    newBaseUrl_oem + "EAP/readyforWhitelabelAssets/",
  addToCart: newBaseUrl_oem + "TradingGw/investmentCart/",
  findCartItems: newBaseUrl_oem + "TradingGw/investmentCart/",
  deleteAssetFromCart: newBaseUrl_oem + "TradingGw/investmentCart/",
  deleteModelFromCart: newBaseUrl_oem + "TradingGw/investmentCart/",
  getApps: newBaseUrl_oem + "ContentGw/getapps/",
  userFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  siteFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  assetFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  modelFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",
  schemeFeedback: newBaseUrl_oem + "QMS-Gw/recordFeedback/",

  uploadKyc: newBaseUrl_oem + "Contract-Upload-Gw/uploadDocument/",
  likeCount: newBaseUrl_oem + "ContentGw/updatelikecount/",
  deleteNominee: newBaseUrl + "OpUserServer/userserver/deleteNominee/",
  getNomineeList: OpUserServiceUrl + "OpUserService/nomineeDetails/",
  updateNominee: OpUserServiceUrl + "OpUserService/updateNomineeDetails/",
  addNominee: OpUserServiceUrl + "OpUserService/addNominee/",
  updateUserInfo: OpUserServiceUrl + "OpUserService/updateConsumerUser/",
  updateBankDetails: newBaseUrl_oem + "Wallet-Payment-Gw/updateBankdetails/",
  getUserImag: OpUserServiceUrl + "OpUserService/getUserImage/",
  uploadUSerImage: OpUserServiceUrl + "OpUserService/uploadImage/",
  getInvestedModels: newBaseUrl_oem + "EAP/investedmodels/",
  addMoneyToWallet: newBaseUrl_oem + "Wallet-Payment-Gw/moneyToWallet/",
  withdrawMoneyFromWallet:
    newBaseUrl_oem + "Wallet-Payment-Gw/withdrawMoneyFromWallet/",
  getSignedAgreements: baseUrl + "ContractGw/getDocument/",
  getOrderList: newBaseUrl_oem + "TradingGw/oemwhitelabelstatus/",

  preMatureSale: newBaseUrl_oem + "EAP/updateAssetStatus/",
  createPaymentLink: newBaseUrl_oem + "Wallet-Payment-Gw/payment_links/",
  makePayment: newBaseUrl_oem + "TradingGw/schemeWhitelabelRequest/",
  bookNowPayment: newBaseUrl_oem + "TradingGw/whitelabelRequest/",
  bankStatus: newBaseUrl_oem + "Wallet-Payment-Gw/getBankStatus/",

  // storage manager
  UpdateStorageStatus: newBaseUrl_oem + `EAP/updateSiteStatus/consumer2/`,
  GetStatusOfStorage: newBaseUrl_oem + `EAP/siteInformation/`,
  getAttendenceManager: newBaseUrl_oem + `EAP/getAttendanceLocationInfo/`,
  getFeedbackManager: newBaseUrl_oem + `QMS-Gw/assetFeedbacks/`,
  getProductItemsOfSites: newBaseUrl_oem + "AgriProductProxy/items/consumer2/",

  addAttendanceLocation: proxyUrl + "EAP/attendanceLocation/",
};
