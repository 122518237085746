import React, { useRef, useState, useEffect } from "react";
import { Button, Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { GoogleMap, Polyline, Marker, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
  width: "80vw",
  height: "70vh",
};

const defaultCenter = {
  lat: 28.679079, // Default latitude
  lng: 77.06971, // Default longitude
};

const Map = ({
  location = [], // Default empty array
  MarkerMap = [], // Default empty array
  handleMapBack,
  cardData,
  startTripBtn,
  showBackButton,
}) => {
  const mapRef = useRef();
  const [tripCompleted, setTripCompleted] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null); // To track the selected marker

  const mapLoad = (ref) => {
    mapRef.current = ref;

    // Automatically fit bounds to markers
    if (MarkerMap.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      MarkerMap.forEach((marker) => {
        const lat = parseFloat(marker.lat);
        const lng = parseFloat(marker.lng);
        if (!isNaN(lat) && !isNaN(lng)) {
          bounds.extend({ lat, lng });
        }
      });
      ref.fitBounds(bounds);
    }
  };

  const getFinalTripStatus = async () => {
    console.log("Trip Started");
  };

  const getFinalTripStatus1 = async () => {
    console.log("Trip Ended");
    setTripCompleted(true);
  };

  const handleCloseMessage = () => {
    setTripCompleted(false);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  return (
    <>
      {showBackButton && (
        <Button
          sx={{ margin: "1rem" }}
          variant="outlined"
          onClick={handleMapBack}
        >
          Back to Bookings
        </Button>
      )}

      {startTripBtn && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={getFinalTripStatus}
            sx={{ marginRight: 2 }}
          >
            START TRIP
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={getFinalTripStatus1}
          >
            END TRIP
          </Button>
        </Box>
      )}

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter} // Initial center
        zoom={4} // Default zoom
        onLoad={mapLoad}
      >
        {/* Render Polylines if location data exists */}
        {location.length > 0 && (
          <Polyline
            path={location}
            geodesic={true}
            options={{
              strokeColor: "#187bcd",
              strokeOpacity: 5.75,
              strokeWeight: 7,
            }}
          />
        )}

        {/* Render Markers only if MarkerMap has valid data */}
        {MarkerMap.length > 0 &&
          MarkerMap.map((item, index) => {
            const lat = parseFloat(item.lat);
            const lng = parseFloat(item.lng);
            if (!isNaN(lat) && !isNaN(lng)) {
              return (
                <Marker
                  key={index}
                  position={{ lat, lng }}
                  onClick={() => handleMarkerClick(item)} // Add click handler
                >
                  {/* Tooltip-like info window */}
                  {selectedMarker === item && (
                    <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                      <div>
                        <h3>{item.title || "FROM"}</h3> {/* Show title or custom text */}
                        <p>{item.description || "Details about the location"}</p> {/* Optional description */}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            }
            return null;
          })}
      </GoogleMap>

      {/* Trip Completion Snackbar */}
      <Snackbar
        open={tripCompleted}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 9999,
        }}
      >
        <MuiAlert
          onClose={handleCloseMessage}
          severity="success"
          elevation={6}
          variant="filled"
          sx={{
            minWidth: "300px",
            textAlign: "center",
          }}
        >
          Your trip is completed successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default React.memo(Map);
