import HTTP_Client from 'axios';
import axios from 'axios';

HTTP_Client.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 404) {
      throw new Error(`${err.config.url} not found`);
    }

    if (err.response.status === 401) {
      const excludApis = [
        'getConsumerUserType',
        'getConsumerUserTypeRole',
        'registerUser',
        'refresh_token',
      ];
      let shouldRedirect = true;
      for (let i = 0; i < excludApis.length; i++) {
        if (err.request.responseURL.indexOf(excludApis[i]) !== -1) {
          shouldRedirect = false;
          break;
        } else {
          continue;
        }
      }

      if (shouldRedirect) {
        //alert("Session expired, please login");
        doLogoutAndRedirect();
      }

      // throw new Error(`Session expired, please login.`);
      throw new Error(`Invalid Username or Password`);
    }
    throw err;
  }
);

const getAppConfig = () => window.AppConfigData;

const isLoggedIn = () => {
  try {
    let _enc = localStorage.getItem('CBN');
    let loginInfoString = atob(_enc);
    let loginInfo = JSON.parse(loginInfoString);
    if (loginInfo.token) return true;
    return false;
  } catch (e) {
    return false;
  }
};

const setLocalData = (data) => {
  localStorage.removeItem('CBN');
  try {
    localStorage.setItem('CBN', btoa(JSON.stringify(data)));
    return true;
  } catch (e) {
    let defaultData = {
      token: '',
      user: {},
    };
    localStorage.setItem('CBN', btoa(JSON.stringify(defaultData)));
    console.error(e);
    return false;
  }
};

const getLocalData = () => {
  try {
    let _enc = localStorage.getItem('CBN');
    let loginInfoString = atob(_enc);
    let loginInfo = JSON.parse(loginInfoString);

    return loginInfo;
  } catch (e) {
    return {
      token: '',
      user: {},
    };
  }
};

const doLogout = () => {doLogoutAndRedirect();}

const doLogoutAndRedirect = () => {
  localStorage.removeItem("CBN");
  localStorage.removeItem("userType");
  localStorage.removeItem("UserRole");
  window.location.href = "/auth/3/signin";
};
async function getRefreshToken() {
  try {
    if (localStorage.hasOwnProperty("CBN")) {
      let loginInfo;
      const _enc = localStorage?.getItem("CBN");

      if (_enc !== null) {
        const loginInfoString = atob(_enc);
        loginInfo = JSON.parse(loginInfoString);
        if (loginInfo?.refreshToken !== undefined) {
          try {
            const res = await HTTP_Client({
              method: "post",
              url: `${getAppConfig().ApiUrlMapp.getAccessToken}${getAppConfig().Realm}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                clientid: "admin-cli",
                refreshtoken: loginInfo?.refreshToken,
              },
            });

            if (res.status === 200) {
              if (res.data) {
                const newAccessToken = res.data.access_token;
                if (newAccessToken) {
                  loginInfo.access_token = newAccessToken;
                  loginInfo.refreshToken = res.data.refresh_token;
                  setLocalData(loginInfo);
                  return newAccessToken;
                } else {
                  console.error("Invalid response format: Missing access_token");
                  throw new Error("Internal server error: Unexpected response format");
                }
              } else {
                console.error("Invalid response format: Missing data");
                throw new Error("Internal server error: Unexpected response format");
              }
            } else {
              console.error(`Unexpected status code: ${res.status}`);
              console.error("Response data:", res.data);
              throw new Error(`Internal server error: Unexpected status code ${res.status}`);
            }
          } catch (error) {
            console.error("Error in axios request:", error);
            throw new Error(`Internal server error: ${error.message}`);
          }
        }
      } else {
        console.log("localStorage does not have data");
      }
    } else {
      console.log("Local data not found.");
    }
  } catch (error) {
    doLogoutAndRedirect();
    console.error("Error in getRefreshToken function:", error);
    throw error;
  }
}
const getToken = () => {
  try {
    let _enc = localStorage.getItem('CBN');
    let loginInfoString = atob(_enc);
    let loginInfo = JSON.parse(loginInfoString);
    return loginInfo.token;
  } catch (e) {
    return '';
  }
};

//axios

/* invoice api axios instance */
const createAxiosInstance = (endpoint) => {
  return axios.create({
    baseURL: `${getAppConfig().ApiUrlMapp[endpoint]}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};
const createDocumentInstance = (endpoint, orgid, suborgid, userName) => {
  const generateBoundary = () => {
    return (
      '--------------------------' + Math.random().toString(16).substring(2)
    );
  };
  return axios.create({
    baseURL: getAppConfig().ApiUrlMapp[endpoint],
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${getToken()}`,
      networkid: window.AppConfigData.EnterpriseId,
      orgid: orgid,
      suborgid: suborgid,
      username: userName,
    },
  });
};
const createInvoiceInstance = (endpoint, orgid, suborgid, userName) => {
  const generateBoundary = () => {
    return (
      '--------------------------' + Math.random().toString(16).substring(2)
    );
  };
  return axios.create({
    baseURL: getAppConfig().ApiUrlMapp[endpoint],
    headers: {
      'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,
      Authorization: `Bearer ${getToken()}`,
      networkid: window.AppConfigData.EnterpriseId,
      orgid: orgid,
      suborgid: suborgid,
      username: userName,
    },
  });
};
const createAxiosInstances = (
  endpoint,
  orgid = '',
  suborgid = '',
  username
) => {
  return axios.create({
    baseURL: getAppConfig().ApiUrlMapp[endpoint],
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      networkid: window.AppConfigData.EnterpriseId,
      orgid: orgid,
      suborgid: suborgid,
      username: username,
    },
  });
};

const createAxiosDocumentInstances = (endpoint, parentId='', username='') => {
  return axios.create({
    baseURL: getAppConfig().ApiUrlMapp[endpoint],
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'network-id': window.AppConfigData.EnterpriseId,
      'parent-id': parentId,
      username: username
    },
  });
};

const getConsumerUserType = (parentId, username) => {
  console.log('**************0000000000000000000000*****', parentId, username);
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getConsumerUserType}${
        getAppConfig().Realm
      }/${parentId}/${username}`,

      //"http://13.234.37.125:8080/AuthBackEndGw/getUserType/consumer2"+username,

      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.usertype);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);

        return reject('Internal server error, please try again.');
      })
  );
};
const getStepperCounter = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getStepperCounter
      }${parentId}/${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);

        return reject('Internal server error, please try again.');
      })
  );
};
const getConsumerUserInfo = (parentid, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.consumerRequestInfo
      }${parentid}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error.');

        // return reject("Internal server error, please try again.");
      })
  );
};
const getAllAssetsOfAssetDeveloper = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAllAssetsOfAssetDeveloper
      }${parentId}/${username}`,
  
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.assets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getConsumerUserInfoForAD = (username, consumerusername) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getConsumerUserInfoForAD}${
        getAppConfig().Realm
      }/${username}/${consumerusername}`,
      //"http://13.234.37.125:8080/RegisterGateway/userInfo/consumer2/"+username +"/" +consumerusername,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error.');

        // return reject("Internal server error, please try again.");
      })
  );
};
const getSellerInfo = (parentId, seller) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSellerInfo}${
        getAppConfig().Realm
      }/${parentId}/${seller}`,
      //"http://13.234.37.125:8080/RegisterGateway/userInfo/consumer2/consumer2/"+seller,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error.');

        // return reject("Internal server error, please try again.");
      })
  );
};
const createAccessToken = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.createAccessToken}${
        getAppConfig().EnterpriseId
      }`,
      data: data,
      Accept: 'application/json',
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        const signupData = {
          token: res.data.access_token,
          refreshToken: res.data.refresh_token,
          expires_in: res.data.expires_in,
          refresh_expires_in: res.data.refresh_expires_in,
          scope: res.data.scope,
          session_state: res.data.session_state,
          token_type: res.data.token_type,
        };

        setLocalData(signupData);
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};
const doLogin = (parentid, username, password) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.getToken}${parentid}`,
      data: {
        username,
        password,
      },
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          return reject(res.data.message);
        }

        if (res.data.error) {
          return reject(res.data.error_description);
        }
        const loginData = {
          notification: 'Login successfully.',
          user: {
            type: 6,
            username: username,
            parentId: parentid,
          },
          token: res.data.access_token,
          refreshToken: res.data.refresh_token,
          expires_in: res.data.expires_in,
          refresh_expires_in: res.data.refresh_expires_in,
          scope: res.data.scope,
          session_state: res.data.session_state,
          token_type: res.data.token_type,
        };
        setLocalData(loginData);
        return resolve(true);
      })
      .catch((e) => {
        return reject('Invalid password or username.');
      })
  );
};
setInterval(getRefreshToken, 179000);
const doSignup = (data) => {
  data.usertype = getAppConfig().UserType;
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.registerUser}${
        getAppConfig().EnterpriseId
      }/${getAppConfig().ClientId}/${getAppConfig().ClientSecretKey}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Account already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const doFarmerSignup = (data, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.registerConsumerUser}${parentId}/${
        getAppConfig().ClientId
      }/${getAppConfig().ClientSecretKey}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject('Internal server error, please try again.');
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAllUserTypes = (parentId, username, token) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAllUserTypes
      }${parentId}/${username}`,

      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.usertypes);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetVerticalsIdList = (parentId) => {
  console.log('parent id in main:', parentId);
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetVerticalsIdList}${
        getAppConfig().Realm
      }/${parentId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        console.log('verticals in main:', res);
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assetVerticals);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getCategoryTypesList = (verticalsId, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getCategoryTypesList}${
        getAppConfig().Realm
      }/${parentId}/${verticalsId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.categoryTypes);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetTypesList = (verticalsId, categoryType, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetTypesList}${
        getAppConfig().Realm
      }/${parentId}/${verticalsId}/${categoryType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assetTypes);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getModelsList = (verticalsId, categoryType, assetType, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getModelsList}${
        getAppConfig().Realm
      }/${parentId}/${verticalsId}/${categoryType}/${assetType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.models);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getTuModels = (userName,verticalsId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getModelsList}${
        getAppConfig().Realm
      }/${userName}/${verticalsId}/tempo/tempo`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.models);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllSites = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllSites}${
        getAppConfig().Realm
      }/${username}`,
      // "http://proxy.gbrservice.com/EAP/allsites/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.sites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getCategory = (parentid, Categoryname) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getCategory}${
        getAppConfig().Realm
      }/${parentid}/${Categoryname}`,
      //"http://proxy.gbrservice.com/ContentGw/getCategoryId/consumer2/"+parentid +"/" +Categoryname,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.categoryid);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSubCategoriesForAD = (username, selectedCategoryId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSubCategoriesForAD}${
        getAppConfig().Realm
      }/${username}/${selectedCategoryId}`,
      //"http://proxy.gbrservice.com/ContentGw/getAllContentSubCategories/consumer2/"+username+"/"+selectedCategoryId,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentsubcategories);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAllContentsList = (username, categoryid, subCategory) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllContentsList}${
        getAppConfig().Realm
      }/${username}/${categoryid}/${subCategory}`,
      //"http://proxy.gbrservice.com/ContentGw/contentList/consumer2/"+username+"/"+categoryid+"/"+subCategory,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getCategoryTender = (parentid, categoryid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getCategoryTender
      }${parentid}/category/${categoryid}`,

      //"http://proxy.gbrservice.com/ContentGw/contentlist/"+parentid+"/category/"+categoryid,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getTendersByDistricts = (dist) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getTendersByDistricts}${
        getAppConfig().Realm
      }/${dist}`,
      //"http://proxy.gbrservice.com/ContentGw/getSiteContent/consumer2/"+dist,

      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getTendersByOrganization = (username, org) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getTendersByOrganization}${
        getAppConfig().Realm
      }/${username}/${org}`,
      //"http://proxy.gbrservice.com/ContentGw/contentlist/consumer2/"+username+"/"+org,

      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const deleteContent = (username, catgId, tenderId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteContent}${
        getAppConfig().Realm
      }/${username}/${catgId}/${tenderId}`,
      // `http://13.233.122.53:8080/ContentGw/content/consumer2/${username}/${catgId}/${tenderId}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getWhiteLabelAssetsForAD = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getWhiteLabelAssetsForAD}${
        getAppConfig().Realm
      }/${username}`,
      //"http://proxy.gbrservice.com/EAP/getSolutionAssets/consumer2/"+username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetsBasedonAll = (
  modelId,
  selectedVerticalsId,
  selectedCategoryId,
  selectedAssertType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetsBasedonAll}${
        getAppConfig().Realm
      }/${modelId}/${selectedVerticalsId}/${selectedCategoryId}/${selectedAssertType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.readyForWhitelabelAssets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const createLegacyAsset = (data, username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: 'consumer2',
        suborgid:username
      },
      url: `${getAppConfig().ApiUrlMapp.createLegacyAsset}${
        getAppConfig().Realm
      }/${username}/${assetId}`,
      
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Asset already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createContentSubCategory = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createContentSubCategory}${
        getAppConfig().Realm
      }`,
      // "http://proxy.gbrservice.com/ContentGw/contentsubcategory/consumer2",
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('subcategory already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createAssetOEM = (data, username, email) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${getAppConfig().ApiUrlMapp.createAssetOEM}${
        getAppConfig().Realm
      }/${getAppConfig().ClientId}/${getAppConfig().ClientSecretKey}`,
      //"http://13.234.37.125:8080/RegisterGateway/registerUser/consumer2/30c667be-1aa3-4f0d-94be-a4176d93558a/6511ea61-2b7a-47f1-b548-0397c68828ad",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data.error);
        }
        return resolve(res.data.message);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('AssetOEM already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createConsumerUser = (
  data,
  username,
  consumerUsername,
  consumerEmail,
  token
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createConsumerUser}${
        getAppConfig().ConsumerAdmin
      }/${username}/${consumerUsername}/${consumerEmail}`,
      //"http://um.gbrservice.com/AuthBackEndGw/userCreation/consumer2"+"/"+username+"/"+consumerUsername+"/"+consumerEmail,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Consumer user already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const resetConsumerUserPassword = (data, username, userId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.resetConsumerUserPassword}${
        getAppConfig().Realm
      }/${username}/${userId}`,
      //"http://13.234.37.125:8080/AuthBackEndGw/createUserCredential/consumer2/"+username+"/"+userId,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data.error);
        }
        return resolve(res.data.message);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('try different password');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const updateConsumerUserInfo = (
  data,
  username,
  consumerUserId,
  consumerUserName
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateConsumerUserInfo}${
        getAppConfig().Realm
      }/${username}/${consumerUserId}/${consumerUserName}`,
      //"http://13.234.37.125:8080/AuthBackEndGw/updateConsumerUserInfo/consumer2/"+username+"/"+consumerUserId+"/"+consumerUserName,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data.error);
        }
        return resolve(res.data.message);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteConsumerUser = (username, consumerUsername, consumerUserId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteConsumerUser}${
        getAppConfig().Realm
      }/${username}/${consumerUsername}`,
      //"http://um.gbrservice.com/AuthBackEndGw/userDeletion/consumer2/"+username+"/"+consumerUsername,
      data: consumerUserId,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data.error);
        }
        return resolve(res.data.message);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Consumer user already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const getParentId = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getParentId}${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.parentid);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAllDistricts = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllDistricts}${
        getAppConfig().Realm
      }/${username}`,
      //"http://proxy.gbrservice.com/EAP/allsites/consumer2" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.sites);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const createContent = (formdata, parentid) => {
  console.log('parent id form create content', formdata, parentid);
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.createContent}${parentid}`,
      //"http://proxy.gbrservice.com/ContentGw/content/"+parentid,
      data: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content name already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadKYC = (username, docType, formdata) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.uploadKYC}${
        getAppConfig().Realm
      }/${username}/${docType}`,
      //"http://13.233.122.53:8080/Contract-Upload-Gw/uploadDocument/consumer2/"+username+"/"+docType,
      data: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const createMapping = (username, mapAssetId, mapCategoryType, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createMapping}${
        getAppConfig().Realm
      }/${username}/${mapAssetId}/${mapCategoryType}`,
      //`http://13.233.122.53:8080/EAP/maplegacyassettosite/consumer2/${username}/${mapAssetId}/${mapCategoryType}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const createUnMapping = (assetId, categoryType, data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createUnMapping}${
        getAppConfig().Realm
      }/${username}/${assetId}/${categoryType}`,
      // `http://13.233.122.53:8080/EAP/unmapSiteAsset/consumer2/${username}/${assetId}/${categoryType}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject(e.data['ERROR MESSAGE']);
      })
  );
};

const assetStatusUpdate = (assetIdVal, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetStatusUpdate}${
        getAppConfig().Realm
      }/${assetIdVal}`,
      //`http://proxy.gbrservice.com/EAP/updateAssetStatus/consumer2/${assetIdVal}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data['ERROR MESSAGE']);
      })
      .catch((e) => {
        return reject(e.data['SUCCESS MESSAGE']);
      })
  );
};
const sendEmail = (email, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.sendEmail}${
        getAppConfig().Realm
      }/${email}`,
      //"http://proxy.gbrservice.com/NotificationUA/sendEmail/consumer2/"+email,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const sendSMS = (mobileNumber, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.sendSMS}${
        getAppConfig().Realm
      }/+91${mobileNumber}`,
      //"http://proxy.gbrservice.com/NotificationUA/sendSms/consumer2/+91" +mobileNumber,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const updateWhiteLabelStatus = (
  username,
  assetdeveloperid,
  assetid,
  modelid,
  data
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateWhiteLabelStatus}${
        getAppConfig().Realm
      }/${username}/${assetdeveloperid}/${assetid}/${modelid}`,
      //"http://proxy.gbrservice.com/TradingGw/updatewhitelabelRequest/consumer2/"+username+"/"+assetdeveloperid+"/"+assetid+"/"+modelid,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const updateNodeStatus = (consumerUserName, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateNodeStatus}${
        getAppConfig().Realm
      }/${consumerUserName}`,
      //"http://proxy.gbrservice.com/EAP/updateNodeStatus/consumer2/" +consumerUserName,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addNodeToFarmer = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addNodeToFarmer}${
        getAppConfig().Realm
      }`,
      //"http://proxy.gbrservice.com/EAP/addNodeSpecification/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addGrowerRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addGrowerRequest}${
        getAppConfig().Realm
      }`,
      //"http://proxy.gbrservice.com/FarmerProxy/growRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addSellRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addSellRequest}${getAppConfig().Realm}`,
      // "http://proxy.gbrservice.com/FarmerProxy/sellRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addLoanRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addLoanRequest}${getAppConfig().Realm}`,
      //"http://proxy.gbrservice.com/FarmerProxy/loanRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addVetRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addVetRequest}${getAppConfig().Realm}`,
      // "http://proxy.gbrservice.com/FarmerProxy/vetvisitRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addBuyInputRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addBuyInputRequest}${
        getAppConfig().Realm
      }`,
      // "http://proxy.gbrservice.com/FarmerProxy/vetvisitRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addStoreRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addStoreRequest}${
        getAppConfig().Realm
      }`,
      // "http://proxy.gbrservice.com/FarmerProxy/vetvisitRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Node already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addAdvisoryRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addAdvisoryRequest}${
        getAppConfig().Realm
      }`,
      // "http://proxy.gbrservice.com/FarmerProxy/vetvisitRequest/"+getAppConfig().Realm,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['requestid']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const assetDelete = (
  assetIds,
  assetTypes,
  modelIds,
  assetverticalIds,
  categoryTypes,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDelete}${
        getAppConfig().Realm
      }/${username}/${assetIds}/${assetTypes}/${modelIds}/${assetverticalIds}/${categoryTypes}`,
      //`http://proxy.gbrservice.com/EAP/deleteAsset/consumer2/${username}/${assetIds}/${assetTypes}/${modelIds}/${assetverticalIds}/${categoryTypes}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getAssetOEMSofConsumerAdmin = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetOEMSofConsumerAdmin}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/AssetOEM`,
      //"http://13.234.37.125:8080/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/AssetOEM",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const customerSupport = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.customerSupport}${
        getAppConfig().Realm
      }/${username}`,
      // "http://13.233.122.53:8080/QMS-Gw/getSettingsInfo/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.SettingInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getLogisticProviders = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getLogisticProviders}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/LogisticsProvider`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/LogisticsProvider",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetDistributors = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetDistributors}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/AssetDistributor`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/AssetDistributor",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetSellers = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetSellers}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/AssetSeller`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/AssetSeller",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllAssetRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllAssetRequests}${
        getAppConfig().Realm
      }/${username}`,
      //"http://proxy.gbrservice.com/TradingGw/getbuyrequests/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.buyrequests);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllRoles = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllRoles}${parentId}/${username}`,
      //"http://um.gbrservice.com/AuthBackEndGw/roles/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.roles);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getClinicAdmins = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getClinicAdmins}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/ClinicAdmin`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/ClinicAdmin",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllConsumerUsers = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAllConsumerUsers
      }${parentId}/${username}`,
      // "http://um.gbrservice.com/AuthBackEndGw/consumerUser/consumer2/" +username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllWhiteLabelRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllWhiteLabelRequests}${
        getAppConfig().Realm
      }/${username}`,
      //"http://13.233.122.53:8080/TradingGw/getwhitelabelrequests/consumer2/" +  username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.whitelabelrequests);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetOEMInfo = (assetOEM) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetOEMInfo}${
        getAppConfig().Realm
      }/${assetOEM}`,
      //"http://13.234.37.125:8080/RegisterGateway/consumerRequestInfo/consumer2/" +assetOEM,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getLogisticProviderInfo = (logicProvider) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getLogisticProviderInfo}${
        getAppConfig().Realm
      }/${logicProvider}`,
      //"http://13.234.37.125:8080/RegisterGateway/userInfo/consumer2/consumer2/" +logicProvider,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetHealthStatus = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetHealthStatus}${
        getAppConfig().Realm
      }/${username}`,
      //"http://13.233.122.53:8080/AnalyticsProxy/assetHealthStatus/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.assets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getProcessHealthStatus = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getProcessHealthStatus}${
        getAppConfig().Realm
      }/${username}`,
      //"http://13.233.122.53:8080/AnalyticsProxy/processHealthStatus/consumer2/" +  username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.processHealth);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getComplaints = (assetOEM) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getComplaints}${
        getAppConfig().Realm
      }/${assetOEM}/Site`,
      //"http://13.233.122.53:8080/QMS-Gw/getComplaint/consumer2/"+assetOEM+"/Site",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.complaints);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getKYCDocuments = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getKYCDocuments}${
        getAppConfig().Realm
      }/${username}`,
      //"http://13.233.122.53:8080/ContractGw/getKycDocument/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.documents);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getUserKYCDocuments = (username, user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getKYCDocuments}${
        getAppConfig().Realm
      }/${username}/${user}`,
      //"http://13.233.122.53:8080/ContractGw/getKycDocument/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.documents);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllUnsignedAgreementsForAD = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllUnsignedAgreementsForAD}${
        getAppConfig().Realm
      }/AssetDeveloperAgreementDocument`,
      //"http://13.233.122.53:8080/ContractGw/getDocument/consumer2/AssetDeveloperAgreementDocument",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.documents);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllSignedAgreementsForAD = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllSignedAgreementsForAD}${
        getAppConfig().Realm
      }/${username}/AssetDeveloperAgreementDocument`,
      //"http://13.233.122.53:8080/ContractGw/getDocument/consumer2/"+username+"/AssetDeveloperAgreementDocument",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.documents);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const trackTheOrder = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.trackTheOrder}${
        getAppConfig().Realm
      }/${username}`,
      //"http://proxy.gbrservice.com/TradingGw/getbuyrequests/consumer2/" + username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.buyrequests);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getNodeInfo = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getNodeInfo}${
        getAppConfig().Realm
      }/${username}`,
      //"http://proxy.gbrservice.com/EAP/nodeSpecifications/consumer2/" +username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.nodeSpecification);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getFarmerNodeInfo = (farmername) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getFarmerNodeInfo}${
        getAppConfig().Realm
      }/${farmername}`,
      //"http://13.234.37.125:8080/RegisterGateway/consumerUserRequestInfo/"+getAppConfig().Realm +"/"+farmername,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const viewNodesOfFarmer = (farmername) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.viewNodesOfFarmer}${
        getAppConfig().Realm
      }/${farmername}`,
      //"http://proxy.gbrservice.com/EAP/getNodeSpecification/"+getAppConfig().Realm +"/" +farmername,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.nodeSpecification);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
/* Tender Site Managemnet */

const viewAllSites = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewAllSites}${parentId}/${username}`,
      //`http://proxy.gbrservice.com/EAP/allsites/consumer2/${username}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getAllSiteTypes = (parentId,username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAllSiteTypes}${getAppConfig().EnterpriseId}/${parentId}/${username}`,
      //`http://proxy.gbrservice.com/EAP/getSiteTypes/consumer2/consumer2`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getRoleProfileBasedOnRoleName = (username, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getRoleProfileBasedOnRoleName}${
        getAppConfig().Realm
      }/${username}/${roleName}`,
      //"http://um.gbrservice.com/AuthBackEndGw/getResources/consumer2/"+username+"/"+roleName,
    })
      .then((res) => {
        return resolve(res.data['role']);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Of Settings Info */
const createCustomerCare = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.createCustomerCare}${
        getAppConfig().Realm
      }`,
      // "http://13.233.122.53:8080/QMS-Gw/addSettingsInfo/consumer2/",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Info already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const settingsInfoBasedonParent = (user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.settingsInfoBasedonParent}${
        getAppConfig().Realm
      }/${user}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.SettingInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const updateAssetRequestStatusForAd = (data, id, assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.updateAssetRequestStatusForAd}${
        getAppConfig().Realm
      }/${id}/${assetid}`,
      //"http://35.154.32.232:8080/TradingServer/updateBuyRequest/" +id +"/" + assetid,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Info already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createRole = (data, username, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.createRole}${
        getAppConfig().Realm
      }/${username}/${roleName}`,
      //"http://um.gbrservice.com/AuthBackEndGw/roleCreation/consumer2/"+username+"/" +roleName,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Role already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const updateCustomerCare = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.updateCustomerCare}${
        getAppConfig().Realm
      }`,
      //"http://13.233.122.53:8080/QMS-Gw/updateSettingsInfo/consumer2",
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const deleteCustomerCare = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.deleteCustomerCare}${
        getAppConfig().Realm
      }/${username}`,
      //"http://13.233.122.53:8080/QMS-Gw/deleteSettingsInfo/consumer2/" + username,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const deleteRole = (username, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.deleteRole}${
        getAppConfig().Realm
      }/${username}/${roleName}`,
      //"http://um.gbrservice.com/AuthBackEndGw/deleteConsumerRole/consumer2/"+username+"/"+roleName,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const deleteTenderSite = (username, siteName, siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.deleteTenderSite}${
        getAppConfig().Realm
      }/${username}/${siteName}/${siteId}`,
      //"http://proxy.gbrservice.com/OP-BackEnd-Gateway/opgateway/deletesite/consumer2/"+username+"/"+siteName+"/"+siteId,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const updateRole = (data, username, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.updateRole}${
        getAppConfig().Realm
      }/${username}/${roleName}`,
      //"http://um.gbrservice.com/AuthBackEndGw/roleUpdate/consumer2/"+username+"/"+roleName,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const updateRoleOfUser = (username, consumerUserName, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.updateRoleOfUser}${
        getAppConfig().Realm
      }/${username}/${consumerUserName}/${roleName}`,
      //"http://um.gbrservice.com/AuthBackEndGw/updateMappedConsumerRole/consumer2/"+username+"/"+consumerUserName+"/"+roleName,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const mapRole = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.mapRole}${
        getAppConfig().Realm
      }/${username}`,
      // "http://um.gbrservice.com/AuthBackEndGw/mapUserRole/consumer2/" + username,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getMappedRoles = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMappedRoles}${parentId}/${username}`,
      //"http://um.gbrservice.com/AuthBackEndGw/getUsersRoles/consumer2/" +
      username,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.userroles);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadAgreementForAD = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.uploadAgreementForAD}${
        getAppConfig().Realm
      }/${username}/AssetDeveloperAgreementDocument`,
      //"http://13.233.122.53:8080/Contract-Upload-Gw/updDocument/consumer2/"+username +"/AssetDeveloperAgreementDocument",
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadAgreementForInvestor = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.uploadAgreementForAD}${
        getAppConfig().Realm
      }/${username}/InvestorAgreementDocument`,
      //"http://13.233.122.53:8080/Contract-Upload-Gw/updDocument/consumer2/"+username +"/AssetDeveloperAgreementDocument",
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
/* AssetOEM Role */

const addToCart = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addToCart}${getAppConfig().Realm}`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Asset already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const likeCount = (data, contentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.likeCount}${
        getAppConfig().Realm
      }/${contentId}`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};

const userFeedback = (data, username, userType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.userFeedback}${
        getAppConfig().Realm
      }/${username}/${userType}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};

const assetDeveloperFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDeveloperFeedback}${
        getAppConfig().Realm
      }/${username}/AssetDeveloper`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const siteFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteFeedback}${
        getAppConfig().Realm
      }/${username}/Site`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const assetFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetFeedback}${
        getAppConfig().Realm
      }/${username}/Asset`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const modelFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.modelFeedback}${
        getAppConfig().Realm
      }/${username}/Model`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const schemeFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.schemeFeedback}${
        getAppConfig().Realm
      }/${username}/Model`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadKyc = (bodyFormData, username, kycType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.uploadKyc}${
        getAppConfig().Realm
      }/${username}/${kycType}`,
      data: bodyFormData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadAgreement = (formdata) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },

      url: `${getAppConfig().ApiUrlMapp.uploadAgreement}${
        getAppConfig().Realm
      }`,
      //"http://proxy.gbrservice.com/Cloud-Storage-Gw/content/consumer2",
      data: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};

const getKycDocument = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getKycDocument}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.documents); //done by srini
        // return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSignedAgreements = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSignedAgreements}${
        getAppConfig().Realm
      }/${username}/InvestorAgreementDocument`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.documents);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getUnsignedAgreements = (assetDeveloperId, categoryId, Subcategory) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getUnsignedAgreements}${
        getAppConfig().Realm
      }/${assetDeveloperId}/${categoryId}/${Subcategory}`,
      //`http://proxy.gbrservice.com/ContentGw/contentList/consumer2/` +assetDeveloperId + "/" +categoryId +"/" +Subcategory,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList); //Content Gw(proposed API )integration
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getBankDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getBankDetails}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.bankDetails);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const paymentHistory = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.paymentHistory}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const doResetPwd = (parentId,username) => {
  const email = username;
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${getAppConfig().ApiUrlMapp.forgetPassword}${parentId}/${username}/${email}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data["SUCCESS_MESSAGE"]);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};
const doChangePwd = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.resetUserPassword}${
        getAppConfig().Realm
      }`,
      data,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getGrowerList = (payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.resetUserPassword}${
        getAppConfig().Realm
      }`,
      data: payload,
    })
      .then((res) => {
        if (
          res.status !== 200 ||
          res.status !== 201 ||
          res.status !== 202 ||
          res.status !== 203 ||
          res.status !== 204
        ) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getNomineeList = (username) => {
  const email = username;
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getNomineeList}${
        getAppConfig().Realm
      }/${email}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.nomineeDetails);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const addNominee = (username, payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addNominee}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        /* 'Access-Control-Allow-Origin': '*',*/
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const addMoneyToWallet = (addmoneyData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addMoneyToWallet}${
        getAppConfig().Realm
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: addmoneyData,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const withdrawMoneyFromWallet = (withdrawmoneyData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      // url: `${getAppConfig().ApiUrlMapp.withdrawMoneyFromWallet}${getAppConfig().Realm}`,
      url: ' http://proxy.gbrservice.com/Wallet-Payment-Gw/withdrawFromWallet/consumer2',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: withdrawmoneyData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        let er = JSON.stringify(e);
        let err = er.slice(12, 47);
        if (err === 'Request failed with status code 403') {
          return reject('Bank account not verified');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createPaymentLink = (paymentLinkData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.createPaymentLink}${
        getAppConfig().Realm
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: paymentLinkData,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const makePayment = (makePaymentData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.makePayment}${getAppConfig().Realm}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: makePaymentData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const bookNowPayment = (bookNowPaymentData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.bookNowPayment}${getAppConfig().Realm}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: bookNowPaymentData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Asset already booked and is in processing...');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const getUserImag = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getUserImag}${parentId}/${username}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.imageInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const updateUserInfo = (username, userid, payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      url: `${getAppConfig().ApiUrlMapp.updateUserInfo}${
        getAppConfig().Realm
      }/${userid}/${username}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const updateBankDetails = (payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.updateBankDetails}${
        getAppConfig().Realm
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const addBankDetails = (addBankData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addBankDetails}${getAppConfig().Realm}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: addBankData,
    })
      .then((res) => {
        if (res.status !== 409) {
          console.error(res.data);
          return reject(res.data.message);
        } else if (res.status !== 406) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteNominee = (username, nomineeIdentifier) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.deleteNominee}${
        getAppConfig().Realm
      }/${username}/${nomineeIdentifier}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 204) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const updateNominee = (updateNomineeData, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.updateNominee}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: updateNomineeData,
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 204) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getWhiteLabelAssets = (
  selectedVerticalsId,
  selectedCategory,
  selectedAssertTypes
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getWhiteLabelAssets}${
        getAppConfig().Realm
      }/${selectedVerticalsId}/${selectedCategory}/${selectedAssertTypes}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.sites);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getApps = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getApps}${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }

        return resolve(res.data.apps);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

/* need to modify category id and subcategory */
const getFarmUpdates = (assetdeveloperid, categoryId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getFarmUpdates}${
        getAppConfig().Realm
      }/contentcreator/${assetdeveloperid}/${categoryId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }

        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getFarmUpdatesFromAll = (categoryId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getFarmUpdatesFromAll}${
        getAppConfig().Realm
      }/category/${categoryId}`,
      // "http://proxy.gbrservice.com/ContentGw/contentsList/consumer2/77F8Fvoyoz/FARM",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }

        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const whiteLabelAssetsBasedonSite = (
  siteId,
  selectedVerticalsId,
  selectedCategory,
  selectedAssertTypes
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.whiteLabelAssetsBasedonSite}${
        getAppConfig().Realm
      }/${siteId}/${selectedVerticalsId}/${selectedCategory}/${selectedAssertTypes}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        // return resolve(res.data.getWhiteLabelAssets);//by srini
        return resolve(res.data.readyForWhitelabelAssets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const whiteLabelAssetsBasedonAssetDeveloper = (
  assetDeveloperId,
  selectedVerticalsId,
  selectedCategory,
  selectedAssertTypes
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.whiteLabelAssetsBasedonAssetDeveloper}${
        getAppConfig().Realm
      }/${assetDeveloperId}/${selectedVerticalsId}/${selectedCategory}/${selectedAssertTypes}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.readyForWhitelabelAssets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const whiteLabelAssetsBasedonModel = (
  modelId,
  selectedVerticalsId,
  selectedCategory,
  selectedAssertTypes
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.whiteLabelAssetsBasedonModel}${
        getAppConfig().Realm
      }/${modelId}/${selectedVerticalsId}/${selectedCategory}/${selectedAssertTypes}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.readyForWhitelabelAssets);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const myAssetsList = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.myAssetsList}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetDetails = (
  assetdeveloperid,
  assetVerticalId,
  category,
  assetType,
  modelid,
  assetid
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetDetails}${
        getAppConfig().Realm
      }/${assetdeveloperid}/${assetVerticalId}/${category}/${assetType}/${modelid}/${assetid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getSitesDetails = (siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSitesDetails}${
        getAppConfig().Realm
      }/${siteId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const findCartItems = (adminId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.findCartItems}${
        getAppConfig().Realm
      }/${adminId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data.investmentCart);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getGrowerDetails = (assetdeveloperid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getGrowerDetails}${
        getAppConfig().Realm
      }/${assetdeveloperid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
        //return resolve(res.data[1].ratings);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteAssetFromCart = (adminid, modelID, assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      url: `${getAppConfig().ApiUrlMapp.deleteAssetFromCart}${
        getAppConfig().Realm
      }/${adminid}/${modelID}/${assetid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getModelDetails = (assetdeveloperid, modelid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getModelDetails}${
        getAppConfig().Realm
      }/${assetdeveloperid}/${modelid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);

          return reject(res.data.message);
        }
        return resolve(res.data[0].modeldetail);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getInvestedModels = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getInvestedModels}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);

          return reject(res.data.message);
        }
        return resolve(res.data.models);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getGrowerIdList = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSolutionsIdList}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.solutions);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getCategoriesBasedOnOEM = (assetDeveloperId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getCategoriesBasedOnOEM}${
        getAppConfig().Realm
      }/${assetDeveloperId}`,
      //"http://proxy.gbrservice.com/ContentGw/getAllCategories/consumer2/" + assetDeveloperId,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }

        return resolve(res.data.categories);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSubCategoriesForOEM = (assetDeveloperId, categoryId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSubCategoriesForOEM}${
        getAppConfig().Realm
      }/${assetDeveloperId}/${categoryId}`,
      //"http://proxy.gbrservice.com/ContentGw/getAllContentSubCategories/consumer2/"+assetDeveloperId+"/"+categoryId,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentsubcategories);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSitesList = (growerId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSitesList}${
        getAppConfig().Realm
      }/${growerId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.sites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSitesAssetsList = (growerId, siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSitesAssetsList}${
        getAppConfig().Realm
      }/${growerId}/${siteId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetInfo = (growerId, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetInfo}${
        getAppConfig().Realm
      }/${growerId}/${assetId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assetInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const sendWhitelabelRequest = (payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.sendWhitelabelRequest}${
        getAppConfig().Realm
      }`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: { ...payload, consumeradminid: getAppConfig().Realm },
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const preMatureSale = (payload, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.preMatureSale}${
        getAppConfig().Realm
      }/${assetId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getSolutionAssets = (reqPayload) => {
  const {
    growerId,
    assetverticalId,
    assetType,
    categoryType,
    //modelId,
  } = reqPayload;
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSolutionAssets}${
        getAppConfig().Realm
      }/${growerId}/${assetType}/${assetverticalId}/${categoryType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSolutionFeedbacks = (growerId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSolutionFeedbacks}${
        getAppConfig().Realm
      }/${growerId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.feedbacks);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getSiteComplaints = (growerId, siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSiteComplaints}${
        getAppConfig().Realm
      }/${growerId}/${siteId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.complaints);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getDashboardData = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getDashboardData
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data[0].Count);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetsCount = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetsCount}${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data[0].Count);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getWhiteLabelAsserts = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getWhiteLabelAsserts}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.whitelabelAssets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetOEMData = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetOEMData}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data[0].Count);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getProfitSummary = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getProfitSummary
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getWalletBalance = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getWalletBalance}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getUserJourney = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getUserJourney}${
        getAppConfig().Realm
      }/${username}`,
      //"http://um.gbrservice.com/RegisterGateway/userJourneyDetails/consumer2/consumer2/assetsOEM@consumer2.com",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getStatementSummary = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getStatementSummary}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getProfitStatementSummary = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getProfitStatementSummary}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getOrderList = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getOrderList}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.whitelabelstatus);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllSolutionsSite = (solutionId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllSolutionsSite}${
        getAppConfig().Realm
      }/${solutionId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.sites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllAssociatedSolutionSites = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllAssociatedSolutionSites}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.sites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAllSolutions = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAllSolutions
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.solutions);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAllAssociatedSolutions = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllAssociatedSolutions}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assetdevelopers);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const bankStatus = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.bankStatus}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
/* Tender Site Managemnet */
const viewAllTenderSites = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewAllTenderSites}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/EAP/allsites/consumer2/${username}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const mappedTenderLegacyAssets = (siteid, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.mappedTenderLegacyAssets}${
        getAppConfig().Realm
      }/${username}/${siteid}`,
      //`http://13.233.122.53:8080/EAP/siteAssets/consumer2/${username}/${siteid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAllTenderAssetsData = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAllTenderAssetsData}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/EAP/getSolutionAssets/consumer2/${username}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const assetMapingtoTenderSite = (username, assId, Cat, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetMapingtoTenderSite}${
        getAppConfig().Realm
      }/${username}/${assId}/${Cat}`,
      // `http://13.233.122.53:8080/EAP/maplegacyassettosite/consumer2/${username}/${assId}/${Cat}`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Info already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const getAllTenderSiteTypes = (siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAllTenderSiteTypes}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/EAP/getSiteTypes/consumer2/consumer2`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const createTenderSite = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createTenderSite}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/OP-BackEnd-Gateway/opgateway/createsite/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Site already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
/* End Of Tender Site Managemnet */
/* Site Management */
const createSiteWithNodeId = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createSiteWithNodeId}${
        getAppConfig().Realm
      }`,
      // `http://proxy.gbrservice.com/OP-BackEnd-Gateway/opgateway/sitecreationinfo/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        console.log('error code', e);
        if (e == 'Error: Request failed with status code 406') {
          /* eslint eqeqeq: 0 */
          return reject('node not approved.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
/* End of Site Managent */
/* View Operators */
const viewListOfOperators = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListOfOperators}${
        getAppConfig().Realm
      }/${username}/Operator`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/Operator`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getRole = (parentId, name) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getRole}${
        getAppConfig().Realm
      }/${parentId}/${name}`,
    })
      .then((res) => {
        if (res.status !== 200 || res.status === 601) {
          return reject(res.data['ERROR_MESSAGE']);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 601') {
          /* eslint eqeqeq: 0 */
          return reject('Role not found');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const getRoleResourceId = (role, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getRoleResourceId}${
        getAppConfig().Realm
      }/${username}/${role}`,
    })
      .then((res) => {
        return resolve(res.data.role.resources);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAssetsOfSite = (username, setDefaultResource) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetsOfSite}${
        getAppConfig().Realm
      }/${username}/${setDefaultResource}`,
      //`http://proxy.gbrservice.com/EAP/siteAssets/consumer2/${username}/${setDefaultResource}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Of View Operators */

/* View Developers QA's */
const viewListOfDeveloperQA = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListOfDeveloperQA}${
        getAppConfig().Realm
      }/${username}/DeveloperQA`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/DeveloperQA`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getDevRole = (name) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDevRole}${
        getAppConfig().Realm
      }/${name}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getRole/consumer2/${name}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getDevRoleResourceId = (role, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDevRoleResourceId}${
        getAppConfig().Realm
      }/${username}/${role}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getResources/consumer2/${username}/${role}`,
    })
      .then((res) => {
        return resolve(res.data.role.resources);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getDevAssetsOfSite = (username, setDefaultResource) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDevAssetsOfSite}${
        getAppConfig().Realm
      }/${username}/${setDefaultResource}`,
      // `http://proxy.gbrservice.com/EAP/siteAssets/consumer2/${username}/${setDefaultResource}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* View End Of Developers QA's */

/* View Executives */
const viewListOfExecutives = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListOfExecutives}${
        getAppConfig().Realm
      }/${username}/Executive`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/Executive`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getConsumerInfoRequest = (name, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getConsumerInfoRequest}${
        getAppConfig().Realm
      }/${username}/${name}`,
      //`http://13.234.37.125:8080/RegisterGateway/userInfo/consumer2/${username}/${name}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* View End Of Executives */

/* View Production Teams */
const viewListOfProductionTeams = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListOfProductionTeams}${
        getAppConfig().Realm
      }/${username}/Production-Team`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/Production-Team`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getTeamRole = (name) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getTeamRole}${
        getAppConfig().Realm
      }/${name}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getRole/consumer2/${name}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getTeamRoleResourceId = (role, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getTeamRoleResourceId}${
        getAppConfig().Realm
      }/${username}/${role}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getResources/consumer2/${username}/${role}`,
    })
      .then((res) => {
        return resolve(res.data.role.resources);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Of View Production Teams */

/* View Production Managers */
const viewListProductionManagers = (username, userType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListProductionManagers}${
        getAppConfig().Realm
      }/${username}/${userType}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/Production-Manager`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getProductManagerRole = (name) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getProductManagerRole}${
        getAppConfig().Realm
      }/${name}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getRole/consumer2/${name}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getRoleProductionManagersResourceId = (role, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getRoleProductionManagersResourceId}${
        getAppConfig().Realm
      }/${username}/${role}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getResources/consumer2/${username}/${role}`,
    })
      .then((res) => {
        return resolve(res.data.role.resources);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Of View Production Managers */

/* View Farmers */
const viewListOfFarmers = (username, userType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewListOfFarmers}${
        getAppConfig().Realm
      }/${username}/${userType}`,
      //`http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/${username}/Farmer`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getFarmerNodes = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getFarmerNodes}${parentId}/${username}`,
      //`http://proxy.gbrservice.com/EAP/getNodeSpecification/`+getAppConfig().Realm+"/"+username,
    })
      .then((res) => {
        return resolve(res.data.nodeSpecification);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allApprovalRequests = (farmername) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allApprovalRequests}${
        getAppConfig().Realm
      }/${farmername}`,
      //`http://proxy.gbrservice.com/FarmerProxy/approvalrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allApprovalRequestsByNode = (farmername, destination, nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allApprovalRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${nodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/approvalrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allGrowRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allGrowRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/growrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allGrowRequestsByNode = (farmername, destination, nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allGrowRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${nodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/growrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const allSellRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allSellRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/sellrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allSellRequestsByNode = (farmername, destination, nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allSellRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${nodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/sellrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allLoanRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allLoanRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/loanrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allLoanRequestsByNode = (farmername, destination, NodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allLoanRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${NodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/loanrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allVetVisitRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allVetVisitRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/vetvisitrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allVetVisitRequestsByNode = (farmername, destination, NodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allVetVisitRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${NodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/vetvisitrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allBuyInputRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allBuyInputRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/buyinputrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allBuyInputRequestsByNode = (farmername, destination, NodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allBuyInputRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${NodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/buyinputrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allStoreRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allStoreRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/storerequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allStoreRequestsByNode = (farmername, destination, NodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allStoreRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${NodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/storerequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allAdvisoryRequests = (farmername, destination) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allAdvisoryRequests}${
        getAppConfig().Realm
      }/${farmername}/${destination}`,
      //`http://proxy.gbrservice.com/FarmerProxy/advisoryrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const allAdvisoryRequestsByNode = (farmername, destination, NodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.allAdvisoryRequestsByNode}${
        getAppConfig().Realm
      }/${farmername}/${destination}/${NodeId}`,
      //`http://proxy.gbrservice.com/FarmerProxy/advisoryrequest/consumer2/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const setNodeStatus = (nodename, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.setNodeStatus}${
        getAppConfig().Realm
      }/${nodename}`,
      //`http://proxy.gbrservice.com/EAP/updateNodeStatus/consumer2/${nodename}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const viewFarmerInfo = (farmername) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewFarmerInfo}${
        getAppConfig().Realm
      }/${farmername}`,
      //`http://13.234.37.125:8080/RegisterGateway/consumerUserRequestInfo/${getAppConfig().Realm}/${farmername}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const approvalReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.approvalReqStatus}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateApprovalRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteContentSubCategory = (username, subcategoryId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteContentSubCategory}${
        getAppConfig().Realm
      }/${username}/${subcategoryId}`,
      //`http://proxy.gbrservice.com/ContentGw/deleteContentSubCategory/consumer2/`+username+"/"+subcategoryId,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const growReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.growReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateGrowRequest/consumer2`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const sellReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.sellReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateSellRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const LoanReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.LoanReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateLoanRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const VetReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.VetReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateVetVisitRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const BuyReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.BuyReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateBuyInputRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const StoreReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.StoreReqStatus}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateStoreRequest/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/* End Of View Farmers */

/* Settings Info */
const viewSettingsInfo = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewSettingsInfo}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/QMS-Gw/getSettingsInfo/consumer2/${username}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Of Settings Info */
const settingsInfo = (user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.settingsInfo}${
        getAppConfig().Realm
      }/${user}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.SettingInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const mappedLegacyAssets = (siteid, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.mappedLegacyAssets}${
        getAppConfig().Realm
      }/${username}/${siteid}`,
      //`http://13.233.122.53:8080/EAP/siteAssets/consumer2/${username}/${siteid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAllAssetsData = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAllAssetsData}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/EAP/getSolutionAssets/consumer2/${username}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const assetMapingtoSite = (username, assId, Cat, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetMapingtoSite}${
        getAppConfig().Realm
      }/${username}/${assId}/${Cat}`,
      //`http://13.233.122.53:8080/EAP/maplegacyassettosite/consumer2/${username}/${assId}/${Cat}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteDeleteHandler = (siteid, sitename, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteDeleteHandler}${
        getAppConfig().Realm
      }/${username}/${sitename}/${siteid}`,
      //`http://proxy.gbrservice.com/OP-BackEnd-Gateway/opgateway/deletesite/consumer2/${username}/${sitename}/${siteid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteRating = (siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteRating}${
        getAppConfig().Realm
      }/${siteId}/Site`,
      //`http://13.233.122.53:8080/QMS-Gw/ratings/consumer2/${siteId}/Site`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const createSite = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createSite}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/OP-BackEnd-Gateway/opgateway/sitecreation/consumer2`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addSiteRating = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addSiteRating}${
        getAppConfig().Realm
      }/Site`,
      //`http://13.233.122.53:8080/QMS-Gw/ratings/consumer2/Site`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteInfo = (siteid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteInfo}${
        getAppConfig().Realm
      }/${siteid}`,
      //`http://13.233.122.53:8080/OP-BackEnd-Gateway/opgateway/siteInfo/consumer2/${siteid}`,
    })
      .then((res) => {
        console.log('siteProfileinfo main service:', res);
        return resolve(res.data.siteProfile);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getModelInfo = (modelId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getModelInfo}${
        getAppConfig().Realm
      }/${modelId}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateSiteInfo = (siteid, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateSiteInfo}${
        getAppConfig().Realm
      }/${siteid}`,
      //`http://proxy.gbrservice.com/EAP/updateSiteInfo/consumer2/${siteid}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* End Site Management */

/* Farm Site Management */
const viewAllFarms = (username, siteType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewAllFarms}${
        getAppConfig().Realm
      }/${username}/${siteType}`,
      //`http://proxy.gbrservice.com/EAP/getallsites/consumer2/${username}/Farm`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const viewAllFarmRelatedAssets = (username, farmid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewAllFarmRelatedAssets}${
        getAppConfig().Realm
      }/${username}/${farmid}`,
      //`http://13.233.122.53:8080/EAP/siteAssets/consumer2/${username}/${farmid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const viewFarmMapRelatedAssets = (username, farmid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewFarmMapRelatedAssets}${
        getAppConfig().Realm
      }/${username}/${farmid}`,
      //`http://13.233.122.53:8080/EAP/siteAssets/consumer2/${username}/${farmid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const farmsDropDownList = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.farmsDropDownList}${
        getAppConfig().Realm
      }/${username}/Farm`,
      //`http://proxy.gbrservice.com/EAP/getallsites/consumer2/${username}/Farm`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/*Common Consumer Users Api's */
const getConsumerNodeInfo = (consumerUserName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getConsumerNodeInfo}${
        getAppConfig().Realm
      }/${consumerUserName}`,
    
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        networkid:'consumer2',
        suborgid:consumerUserName
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        } else if (res.data.nodeSpecification.length <= 0) {
          return reject('No Data Found.');
        }
        return resolve(res.data.nodeSpecification);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const updateConsumerNodeStatus = (nodeId, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateConsumerNodeStatus}${
        getAppConfig().Realm
      }/${nodeId}`,

      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const viewNodeStatus = (nodeid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.viewNodeStatus}${
        getAppConfig().Realm
      }/${nodeid}`,
      //`http://proxy.gbrservice.com/EAP/getNodeStatus/`+getAppConfig().Realm+"/"+nodeid,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const createConsumerUserOfIndividual = (
  data,
  consumerUsername,
  usernameValue,
  consumerEmail
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createConsumerUserOfIndividual}${
        getAppConfig().Realm
      }/${consumerUsername}/${usernameValue}/${consumerEmail}`,
      //`http://um.gbrservice.com/AuthBackEndGw/userCreation/consumer2/${consumerUsername}/${usernameValue}/${consumerEmail}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Consumer user already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const deleteConsumerUserIndividual = (
  username,
  consumerUsername,
  consumerUserId
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteConsumerUserIndividual}${
        getAppConfig().Realm
      }/${username}/${consumerUsername}`,
      //`http://um.gbrservice.com/AuthBackEndGw/userDeletion/consumer2/${username}/${consumerUsername}`,
      data: consumerUserId,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Consumer user already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const cancelGrowRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelGrowRequest
      }${destination}/${source}/Grow`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${destination}/${source}/Grow`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const cancelSellRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelSellRequest
      }${destination}/${source}/Sell`,
      // `http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${destination}/${source}/Sell`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const cancelLoanRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelLoanRequest
      }/${destination}/${source}/Loan`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${destination}/${source}/Loan`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const cancelVetRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelVetRequest
      }${destination}/${source}/VetVisit`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${destination}/${source}/VetVisit`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const resetConsumerUserPasswordIndividual = (data, username, userId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.resetConsumerUserPasswordIndividual}${
        getAppConfig().Realm
      }/${username}/${userId}`,
      //`http://13.234.37.125:8080/AuthBackEndGw/createCredential/consumer2/${userId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('try different password');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const updateConsumerUserInfoIndividual = (
  username,
  data,
  consumerUserId,
  consumerUserName
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateConsumerUserInfoIndividual}${
        getAppConfig().Realm
      }/${username}/${consumerUserId}/${consumerUserName}`,
      // `http://13.234.37.125:8080/AuthBackEndGw/updateConsumerUserInfo/consumer2/${username}/${consumerUserId}/${consumerUserName}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Consumer user already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
/*End Of Common Consumer Users Api's */
/* ****************** Events ********************* */

const ViewAssetOnboardings = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.ViewAssetOnboardings}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getReadyForOnboardAssets/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateReadyForOnboardAssetStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateReadyForOnboardAssetStatus}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateAssetStatus/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const AttendanceLocationHistory = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.AttendanceLocationHistory}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getAttendanceLocations/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const NewAttendanceLocation = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.NewAttendanceLocation}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addAttendanceLocation/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const DeleteNewAttendanceLocation = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.DeleteNewAttendanceLocation}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteAttendanceLocation/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const UpdateAttendanceLocation = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateAttendanceLocation}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateAttendanceLocation/consumer2`,
      data: data,
    })
      .then((res) => {
        // if (res.status !== 204) {
        //   return reject(res.data);
        // }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getBirthDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getBirthDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getBirthInfos/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getBirthInfo = (username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getBirthInfo}${
        getAppConfig().Realm
      }/${username}/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getBirthInfos/consumer2/${assetId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const addBirthInfo = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addBirthInfo}${getAppConfig().Realm}`,
      //`http://13.233.122.53:8080/EAP/addBirthInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteBirthInfo = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteBirthInfo}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteBirthInfo/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateBirthInfo = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateBirthInfo}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateBirthInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getHealthRecords = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getHealthRecords}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getHealthRecord/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addHealthRecords = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addHealthRecords}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addHealthRecord/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteHealthRecord = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteHealthRecord}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteHealthRecord/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateHealthRecord = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateHealthRecord}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateHealthRecord/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getNutrition = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getNutrition}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getNutritions/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
// const getNutritionInfo = (assetId) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${getAppConfig().ApiUrlMapp.getNutritionInfo}${
//         getAppConfig().Realm
//       }/${assetId}`,
//       //`http://13.233.122.53:8080/EAP/getNutritions/consumer2/${username}`,
//     })
//       .then((res) => {
//         if (res.status !== 200) {
//           return reject(res.data);
//         }
//         return resolve(res.data);
//       })
//       .catch((e) => {
//         return reject(e.data);
//       })
//   );
// };
const getParturationInfo = (assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getParturationInfo}${
        getAppConfig().Realm
      }/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getNutritions/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addNutrition = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addNutrition}${getAppConfig().Realm}`,
      //`http://13.233.122.53:8080/EAP/addNutrition/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteNutrition = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteNutrition}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteNutrition/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateNutrition = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateNutrition}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateNutritionInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getWeighingDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getWeighingDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getWeighingDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addWeighingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addWeighingDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addWeighingDetails/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteWeighingDetails = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteWeighingDetails}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteWeighingDetails/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateWeighingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateWeighingDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateWeighingDetails/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getParturitionDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getParturitionDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getParturitionDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addParturitionDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addParturitionDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addParturitionDetails/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteParturitionDetails = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteParturitionDetails}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteParturitionDetails/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateParturitionDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateParturitionDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateParturitionInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getMortalityDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getMortalityDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getMortalityDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addMortalityDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addMortalityDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addMortalityDetails/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteMortalityDetails = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteMortalityDetails}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteMortalityDetails/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateMortalityDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateMortalityDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateMortalityInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAssetOptionalStatusDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetOptionalStatusDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getAssetOperationalStatus/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addAssetOparationalStatusDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addAssetOparationalStatusDetails}${
        getAppConfig().Realm
      }`,
      //"http://13.233.122.53:8080/EAP/addOperationalStatus/consumer2",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteAssetOperationalStatusDetails = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteAssetOperationalStatusDetails}${
        getAppConfig().Realm
      }/${assetid}`,
      //`http://13.233.122.53:8080/EAP/deleteAssetOperationalStatus/consumer2/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateAssetOperationalStatusDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateAssetOperationalStatusDetails}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateAssetOperationalStatusInfo/consumer2`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getLegacyAssetInfo = (username, assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getLegacyAssetInfo}${
        getAppConfig().Realm
      }/${username}/${assetid}`,
      //`http://13.233.122.53:8080/EAP/solutionAssetInfo/consumer2/${username}/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getSellingCostDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getSellingCostDetails}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/EAP/getSellingCostDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addSellingCostDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addSellingCostDetails}${
        getAppConfig().Realm
      }`,
      //"http://proxy.gbrservice.com/EAP/addSellingCost/consumer2",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getSitePatrollingDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getSitePatrollingDetails}${
        getAppConfig().Realm
      }/${username}/Site`,
      //`http://13.233.122.53:8080/EAP/getPatrollingDetails/consumer2/${username}/Site`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addSitePatrolingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addSitePatrolingDetails}${
        getAppConfig().Realm
      }/Site`,
      //"http://13.233.122.53:8080/EAP/addPatrollingDetails/consumer2/Site",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteSitePatrolingDetails = (siteid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteSitePatrolingDetails}${
        getAppConfig().Realm
      }/${siteid}/Site`,
      //`http://13.233.122.53:8080/EAP/deletePatrollingDetails/consumer2/${siteid}/Site`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateSitePatrolingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateSitePatrolingDetails}${
        getAppConfig().Realm
      }/Site`,
      //`http://13.233.122.53:8080/EAP/updatePatrollingInfo/consumer2/Site`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAssetPatrollingDetails = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetPatrollingDetails}${
        getAppConfig().Realm
      }/${username}/Asset`,
      //`http://13.233.122.53:8080/EAP/getPatrollingDetails/consumer2/${username}/Asset`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addAssetPatrolingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addAssetPatrolingDetails}${
        getAppConfig().Realm
      }/Asset`,
      //"http://13.233.122.53:8080/EAP/addPatrollingDetails/consumer2/Asset",
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteAssetPatrolingDetails = (assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteAssetPatrolingDetails}${
        getAppConfig().Realm
      }/${assetid}/Asset`,
      //`http://13.233.122.53:8080/EAP/deletePatrollingDetails/consumer2/${assetid}/Asset`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateAssetPatrolingDetails = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateAssetPatrolingDetails}${
        getAppConfig().Realm
      }/Asset`,
      //`http://13.233.122.53:8080/EAP/updatePatrollingInfo/consumer2/Asset`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteFeedbackDetails = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteFeedbackDetails}${
        getAppConfig().Realm
      }/${username}/Site`,
      //`http://13.233.122.53:8080/QMS-Gw/recordFeedback/consumer2/${username}/Site`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const assetFeedbackDetails = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetFeedbackDetails}${
        getAppConfig().Realm
      }/${username}/Asset`,
      //`http://13.233.122.53:8080/QMS-Gw/recordFeedback/consumer2/${username}/Asset`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/* ****************** End Of Events ********************* */
/* Feedback */
const operatorFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDeveloperFeedback}${
        getAppConfig().Realm
      }/${username}/Operator`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const executiveFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDeveloperFeedback}${
        getAppConfig().Realm
      }/${username}/Executive`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const developerQAFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDeveloperFeedback}${
        getAppConfig().Realm
      }/${username}/DeveloperQA`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};
const productionManagersFeedback = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetDeveloperFeedback}${
        getAppConfig().Realm
      }/${username}/ProductionManager`,
      data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        return reject('Internal server error, please try again.');
      })
  );
};

/* End Feed back */
/************** 22/09/2022*************** */

const siteIssueCreate = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteIssueCreate}${
        getAppConfig().Realm
      }/${username}/Site`,
      //`http://proxy.gbrservice.com/QMS-Gw/createIssue/${getAppConfig().Realm}/${username}/Site`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getSiteIssueData = (username, siteid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getSiteIssueData}${
        getAppConfig().Realm
      }/${username}/${siteid}`,
      //`http://proxy.gbrservice.com/QMS-Gw/issues/${getAppConfig().Realm}/${username}/${siteid}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.issues);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const dailyReportDetails = (resourceName, id) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.dailyReportDetails}${
        getAppConfig().Realm
      }/${resourceName}/${id}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.dailyReport);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const createDailyReport = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createDailyReport}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/dailyReport/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getGroupNutritionData = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getGroupNutritionData}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/EAP/getNutritions/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addNutritionData = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addNutritionData}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/EAP/addNutrition/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const deleteGroupNutrition = (siteid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteGroupNutrition}${
        getAppConfig().Realm
      }/${siteid}`,
      //`http://proxy.gbrservice.com/EAP/deleteNutrition/${getAppConfig().Realm}/${siteid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const updateGroupNutrition = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateGroupNutrition}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/EAP/updateNutritionInfo/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteAttendanceLocationHistory = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteAttendanceLocationHistory}${
        getAppConfig().Realm
      }/${username}`,
      //`http://13.233.122.53:8080/EAP/getAttendanceLocations/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteNewAttendanceLocation = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteNewAttendanceLocation}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/addAttendanceLocation/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteDeleteNewAttendanceLocation = (siteid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteDeleteNewAttendanceLocation}${
        getAppConfig().Realm
      }/${siteid}`,
      //`http://13.233.122.53:8080/EAP/deleteAttendanceLocation/${getAppConfig().Realm}/${siteid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteUpdateAttendanceLocation = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteUpdateAttendanceLocation}${
        getAppConfig().Realm
      }`,
      //`http://13.233.122.53:8080/EAP/updateAttendanceLocation/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        // if (res.status !== 204) {
        //   return reject(res.data);
        // }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteGrowRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteGrowRequests}${
        getAppConfig().Realm
      }/${username}`,
      // `http://proxy.gbrservice.com/FarmerProxy/growrequest/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addGrowRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addGrowRequest}${getAppConfig().Realm}`,
      //`http://proxy.gbrservice.com/FarmerProxy/growRequest/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/************** End Of 22/09/2022*************** */

/************** 26/09/2022*************** */
const siteGrowReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteGrowReqStatus}${
        getAppConfig().Realm
      }`,
      // `http://proxy.gbrservice.com/FarmerProxy/updateGrowRequest/${getAppConfig().Realm}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteCancelGrowReqStatus = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteCancelGrowReqStatus}${
        getAppConfig().Realm
      }/${username}/Grow`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${getAppConfig().Realm}/${username}/Grow`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteSellRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteSellRequests}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/FarmerProxy/sellrequest/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/* const addSellRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `http://proxy.gbrservice.com/FarmerProxy/sellRequest/${getAppConfig().Realm}`,
      data : data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
       return reject(e.data);
      })
  );
}; */

const siteSellReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteSellReqStatus}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateSellRequest/${getAppConfig().Realm}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteCancelSellReqStatus = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteCancelSellReqStatus}${
        getAppConfig().Realm
      }/${username}/Sell`,
      // `http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${getAppConfig().Realm}/${username}/Sell`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteLoanRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteLoanRequests}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/FarmerProxy/loanrequest/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// const siteVetVisitRequests = (username) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${getAppConfig().ApiUrlMapp.siteVetVisitRequests}${
//         getAppConfig().Realm
//       }/${username}`,
//       //`http://proxy.gbrservice.com/FarmerProxy/loanrequest/${getAppConfig().Realm}/${username}`,
//     })
//       .then((res) => {
//         if (res.status !== 200) {
//           return reject(res.data);
//         }
//         return resolve(res.data);
//       })
//       .catch((e) => {
//         return reject(e);
//       })
//   );
// };

const siteLoanReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteLoanReqStatus}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateLoanRequest/${getAppConfig().Realm}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteCancelLoanReqStatus = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteCancelLoanReqStatus}${
        getAppConfig().Realm
      }/${username}/Loan`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${getAppConfig().Realm}/${username}/Loan`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteVetVisitReqStatus = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteVetVisitReqStatus}${
        getAppConfig().Realm
      }`,
      //`http://proxy.gbrservice.com/FarmerProxy/updateLoanRequest/${getAppConfig().Realm}`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const siteCancelVetVisitReqStatus = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteCancelVetVisitReqStatus}${
        getAppConfig().Realm
      }/${username}/VetVisit`,
      //`http://proxy.gbrservice.com/FarmerProxy/cancelRequest/${getAppConfig().Realm}/${username}/Loan`,
      data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const assetIssueCreate = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetIssueCreate}${
        getAppConfig().Realm
      }/${username}/Asset`,
      //`http://proxy.gbrservice.com/QMS-Gw/createIssue/${getAppConfig().Realm}/${username}/Asset`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getAssetIssueData = (siteid, assetid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetIssueData}${
        getAppConfig().Realm
      }/${siteid}/${assetid}`,
      //`http://proxy.gbrservice.com/QMS-Gw/assetIssues/${getAppConfig().Realm}/${getAppConfig().Realm}/${siteid}/${assetid}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        console.log('asset issue resp', e);
        if (
          e == 'Error: the server responded with a status of 404 (Not Found)'
        ) {
          /* eslint eqeqeq: 0 */
          return reject('Data Not Found');
        } else return reject('Internal server error, please try again.');
      })
  );
};

/************** End Of 26/09/2022*************** */
const getNodeInfoBasedOnNodeId = (nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getNodeInfoBasedOnNodeId}${
        getAppConfig().Realm
      }/${nodeId}`,
      //`http://proxy.gbrservice.com/EAP/getNodeInfo/consumer2/`+nodeId,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/******************** 14/10/2022 ************** */
const getMyCategoryTender = (username, categoryid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMyCategoryTender}${
        getAppConfig().Realm
      }/contentowner/${username}/${categoryid}`,
      //`http://proxy.gbrservice.com/ContentGw/contentlist/${getAppConfig().Realm}/contentowner/${username}/${categoryid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getListOfCategorys = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getListOfCategorys}${
        getAppConfig().Realm
      }/${getAppConfig().EnterpriseId}`,
      //`http://proxy.gbrservice.com/ContentGw/getCategories/${getAppConfig().Realm}/${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.categoryList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getCategoryRelatedSubCategories = (username, catid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getCategoryRelatedSubCategories}${
        getAppConfig().Realm
      }/${username}/${catid}`,
      //`http://proxy.gbrservice.com/ContentGw/getAllContentSubCategories/${getAppConfig().Realm}/${username}/${catid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentsubcategories);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
/******************** End Of 14/10/2022 ************** */
const generateQrCode = (qrdata) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.generateQrCode}`,
      data: qrdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Qr-Code already generated for this Asset');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const generateSiteQrCode = (
  qrdata,
  orgId,
  subOrgId,
  parentId,
  username,
  userType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        orgid: orgId,
        suborgid: subOrgId,
        username: username,
        networkid: getAppConfig().EnterpriseId,
      },
      url: `${
        getAppConfig().ApiUrlMapp.generateSiteQrCode
      }${parentId}/${username}/${userType}`,
      data: qrdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (
          e == 'Error: Request failed with status code 406' ||
          e == 'Error: Request failed with status code 409'
        ) {
          /* eslint eqeqeq: 0 */
          return reject('Qr-Code already generated');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const generateModelQrCode = (
  qrdata,
  orgId,
  subOrgId,
  parentId,
  username,
  userType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        orgid: orgId,
        suborgid: subOrgId,
        username: username,
        networkid: getAppConfig().EnterpriseId,
      },
      url: `${
        getAppConfig().ApiUrlMapp.generateModelQrCode
      }${parentId}/${username}/${userType}`,
      data: qrdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Qr-Code already generated for this Asset');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const generateQrCodeWithId = (
  qrdata,
  orgId,
  subOrgId,
  parentId,
  username,
  userType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        orgid: orgId,
        suborgid: subOrgId,
        username: username,
        networkid: getAppConfig().EnterpriseId,
      },
      url: `${
        getAppConfig().ApiUrlMapp.generateQrCodeWithId
      }${parentId}/${username}/${userType}`,
      data: qrdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (
          e == 'Error: Request failed with status code 406' ||
          e == 'Error: Request failed with status code 409'
        ) {
          /* eslint eqeqeq: 0 */
          return reject('Qr-Code already generated for this Asset');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const generateBulkQrCodes = (qrdata) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${getAppConfig().ApiUrlMapp.generateBulkQrCodes}`,
      data: qrdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};
const downloadQrCode = (filename) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.downloadQrCode}${filename}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};
const getQrCodeForAsset = (username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getQrCodeForAsset}${
        getAppConfig().Realm
      }/${username}/${assetId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR_MESSAGE']);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getQrCodeForSite = (username, siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getQrCodeForSite}${
        getAppConfig().Realm
      }/${username}/${siteId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR_MESSAGE']);
        }
        return resolve(res.data['siteqrcodelink']);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getQrCodeForModel = (parentId, modelId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getQrCodeForModel}${
        getAppConfig().Realm
      }/${parentId}/${modelId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR_MESSAGE']);
        }
        return resolve(res.data['modelqrcodeurl']);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};
const getProcessHealthStatusEx = (username, assetVertical, catg, assetType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getProcessHealthStatusEx
      }${username}/${assetVertical}/${catg}/${assetType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetHealthStatusEx = (
  username,
  assetVertical,
  catg,
  assetType,
  siteid
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAssetHealthStatusEx
      }${username}/${assetVertical}/${catg}/${assetType}/${siteid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetStatisticsEx = (username, assetVertical, catg, assetType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAssetStatisticsEx
      }${username}/${assetVertical}/${catg}/${assetType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getDashboardDataEx = (username, assetVertical, catg) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getDashboardDataEx
      }${username}/${assetVertical}/${catg}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const liveMonitoringData = (parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.liveMonitoringData
      }tenant$/${parentId}/vehicle/list`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const updateOperatorProfileInfo = (parentId, username, profileData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'put',
      url: `${
        getAppConfig().ApiUrlMapp.updateOperatorProfileInfo
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: profileData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getRoleNameofOperator = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${getAppConfig().ApiUrlMapp.getRoleNameofOperator}${
        getAppConfig().Realm
      }/${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.role);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};

const getRoleResources = (parentId, roleName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${getAppConfig().ApiUrlMapp.getRoleResources}${
        getAppConfig().Realm
      }/${parentId}/${roleName}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        console.log("role resou res in main:", res);
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};

const getRoleResourceAssets = (parentId, resourceId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getRoleResourceAssets}${parentId}/${resourceId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const siteIssue = (parentId, resourceId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.siteIssue}${
        getAppConfig().Realm
      }/${parentId}/${resourceId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.issues);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetHealthStatusExe = (
  username,
  assetVertical,
  catg,
  assetType,
  siteid
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${
        getAppConfig().ApiUrlMapp.getAssetHealthStatusExe
      }${username}/${assetVertical}/${catg}/${assetType}/${siteid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};
const getAssetStatisticsExe = (parentId, assetVertical, catg, assetType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAssetStatisticsExe
      }${parentId}/${assetVertical}/${catg}/${assetType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.ListOfSites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getProcessHealthStatusExe = (
  username,
  assetVertical,
  catg,
  assetType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getProcessHealthStatusExe
      }${username}/${assetVertical}/${catg}/${assetType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const createSiteIssue = (username, issueData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.createSiteIssue}${
        getAppConfig().Realm
      }/${username}/Site`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: issueData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const addSiteAlarm = (parentId, alarmData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addSiteAlarm}${parentId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: alarmData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Alarm already submitted.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addAssetAlarm = (parentId, alarmData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAssetAlarm}${parentId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: alarmData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Alarm already submitted.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const getSiteReports = (parentId, resourceId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSiteReports}${
        getAppConfig().Realm
      }/${parentId}/${resourceId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.reports);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getSiteAlarms = (parentId, resourceId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getSiteAlarms
      }${parentId}/${resourceId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.sitealarms);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getAssetAlarms = (parentId, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetAlarms}${parentId}/${assetId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.assetalarms);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getExecutiveDashboardData = (verticalsId, categoryType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getExecutiveDashboardData}${
        getAppConfig().Realm
      }/${verticalsId}/${categoryType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        /* if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification); */
        return reject(e);
      })
  );
};
const addAttendanceLocation = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAttendanceLocation}${
        getAppConfig().Realm
      }`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Attendance already submitted.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const createAssetIssue = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.createAssetIssue}${
        getAppConfig().Realm
      }/${username}/Asset`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetIssues = (parentId, siteId, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAssetIssues}${
        getAppConfig().Realm
      }/${parentId}/${siteId}/${assetId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.issues);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const addAssetHealth = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAssetHealth}${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Asset Health already submitted.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const addAssetReport = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAssetReport}${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const addPatrol = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addPatrol}${
        getAppConfig().Realm
      }/Asset`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const dailyReport = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.dailyReport}${getAppConfig().Realm}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const addAssetFeedback = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAssetFeedback}${
        getAppConfig().Realm
      }/${username}/Asset`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const cancelBuyInputRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelBuyInputRequest
      }${destination}/${source}/BuyInput`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const cancelStoreRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.cancelStoreRequest
      }${destination}/${source}/Store`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const cancelAdvisoryRequest = (destination, source, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.cancelAdvisoryRequest}${
        getAppConfig().Realm
      }/${destination}/${source}/Advisory`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};


const getConsumerUserTypeRole = async (parentId, username) => {
  try {
    const axiosInstance = createAxiosInstance("getConsumerUserTypeRole");
    const response = await axiosInstance.get(
      `${parentId}/${parentId}/${username}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const operatorOption = (
  data,
  siteId,
  assetId,
  optionType,
  parentId,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.operatorOption}${
        getAppConfig().Realm
      }/site/${siteId}/asset/${assetId}/${optionType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        parentid: parentId,
        networkid: window.AppConfigData.EnterpriseId,
        username: username,
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['code']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetMortality = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getAssetMortality
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        console.log('mortality in main service:', res);
        return resolve(res.data.mortality);
      })
      .catch((e) => {
        // if (e.response && e.response.data && e.response.data.notification)
        return reject(e.response.data);

        // return reject("Internal server error, please try again.");
      })
  );
};
const addAssetMortality = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.addAssetMortality}${
        getAppConfig().Realm
      }`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Mortality already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const recordCompliance = (data, username, complianceType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.recordCompliance}${
        getAppConfig().Realm
      }/${username}/${complianceType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Compliance already exist.');
        } else reject('Internal server error, please try again.');
      })
  );
};
const recordReport = (data, username, reportType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.recordReport}${
        getAppConfig().Realm
      }/${username}/${reportType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const siteEnv = (envData, siteId, parentId, username) => {
    return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.siteEnv}${
        getAppConfig().Realm
      }/site/${siteId}/PL`,
      headers: {
        'Content-Type': 'application/json',
        parentid: parentId,
        networkid: window.AppConfigData.EnterpriseId,
        Authorization: `Bearer ${getToken()}`,
        username: username,
      },
      data: envData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['code']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getGlobalUsers = (globalUser) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getGlobalUsers}${
        getAppConfig().EnterpriseId
      }/${getAppConfig().EnterpriseId}/${globalUser}`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/tProvider",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getGlobalUsersFromOutside = (globalUser,token) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getGlobalUsers}${
        getAppConfig().EnterpriseId
      }/${getAppConfig().EnterpriseId}/${globalUser}`,
      //"http://um.gbrservice.com/AuthBackEndGw/getAllConsumerUsers/consumer2/consumer2/LogisticsProvider",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const assetCurrentLocation = (parentId, username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.assetCurrentLocation}${
        getAppConfig().Realm
      }/tenant/${username}/asset/${assetId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const communityUsersInfo = (parentId, logicProvider) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.communityUsersInfo}${
        getAppConfig().Realm
      }/${parentId}/${logicProvider}`,
      //"http://13.234.37.125:8080/RegisterGateway/userInfo/consumer2/consumer2/" +logicProvider,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        console.log('resp without phone number:', res.data);
        return resolve(res.data.requestInfo);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getSHG = (username, userType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSHG}${
        getAppConfig().Realm
      }/${username}/${userType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const unmapExistingResourceFromRole = (parentId, roleName, resourceId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.unmapExistingResourceFromRole}${
        getAppConfig().Realm
      }/${parentId}/${roleName}/${resourceId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const getAssetEnvInfo = (username, assetId, query) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getAssetEnvInfo
      }${username}/${assetId}/properties?${query}`,
      //`http://54.185.221.117:8902/ems/livedata/${username}/${assetId}/properties?${query}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getSiteEnvInfo = (username, siteId, query) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getSiteEnvInfo
      }${username}/site/${siteId}/properties?${query}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getMortalityInfo = (assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getMortalityInfo}${
        getAppConfig().Realm
      }/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getMortalityDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const assetFeedbacks = (parentId, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetFeedbacks}${
        getAppConfig().Realm
      }/${parentId}/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getPatrollingDetails/consumer2/${username}/Asset`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.feedbacks);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getAttendanceLocationInfo = (assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAttendanceLocationInfo}${
        getAppConfig().Realm
      }/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getAttendanceLocations/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getHealthStatusBasedOnAssetId = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getHealthStatusBasedOnAssetId}${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const assetReports = (username, siteId, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.assetReports}${
        getAppConfig().Realm
      }/${username}/${siteId}/${assetId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data.reports);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteNode = ( nodeId, farmerName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid:'consumer2',
        suborgid:""
      },
      url: `${getAppConfig().ApiUrlMapp.deleteNode}${
        getAppConfig().Realm
      }/node/${nodeId}/farmer/${farmerName}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.error);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const updateNode = (data, nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateNode}${
        getAppConfig().Realm
      }/node/${nodeId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
const contentInfoBasedOnRequestId = (requestid, parentid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.contentInfoBasedOnRequestId
      }${parentid}/${requestid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data.contentInfo);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const AssetCertifiedConsultant = (parentid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.AssetCertifiedConsultant
      }${parentid}/consumer2/AssetCertifiedConsultant`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const marketPriceRequests = (parentid, contentOwner) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.marketPriceRequests
      }${parentid}/${contentOwner}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const marketOwners = (parentid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.marketOwners
      }${parentid}/${parentid}/Physical-Market`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR MESSAGE']);
        }
        return resolve(res.data.sites);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const siteVetVisitRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.siteVetVisitRequests}${
        getAppConfig().Realm
      }/${username}`,
      //`http://proxy.gbrservice.com/FarmerProxy/loanrequest/${getAppConfig().Realm}/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};
/* Start of product apis */

const getModelProductsBasedOnContentSubcategory = (
  parentId,
  username,
  verticalId,
  category,
  assetType,
  model,
  contentcategory,
  contentsubcategory
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.GetAgriProductProxeyDataBasedOnModel}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${verticalId}/${category}/${assetType}/${model}/${contentcategory}/${contentsubcategory}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};

const getAllAssetsBasedonModel = (
  username,
  assetType,
  modelId,
  assetverticalId,
  categoryType
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllAssetsBasedonModel}${
        getAppConfig().Realm
      }/${username}/${assetType}/${modelId}/${assetverticalId}/${categoryType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        networkid: window.AppConfigData.EnterpriseId,
        suborgid: `${username}`
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteModelProductItem = (
  parentId,
  contentCreator,
  productId,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      url: `${getAppConfig().ApiUrlMapp.AgriProductProxeyDelete}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${productId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const generateQRCodeForProduct = (parentId, qrData, userType, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.GenerateProductQRCode}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${userType}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      data: qrData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
/* End of product apis */
/* Market component apis */
const addModelProduct = (
  parentid,
  username,
  getCategoriesList,
  getSubCategoryForForm,
  modelProductData
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.AgriProductProxey}${
        getAppConfig().EnterpriseId
      }/${parentid}/${username}/${getCategoriesList}/${getSubCategoryForForm}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      data: modelProductData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getModelProducts = (parentId, username, getCategoriesList) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.GetAgriProductProxeyData
      }${parentId}/${username}/${getCategoriesList}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};

const addModelProductItem = (parentId, itemData, businessId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.AgriProductProxeyItem}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      data: itemData,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const uploadProductContents = (formdata, parentid, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UploadProductContent}${
        getAppConfig().EnterpriseId
      }/${parentid}/${username}`,
      data: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const uploadProductCertificate = (formdata, parentid, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UploadProductCertificate}${
        getAppConfig().EnterpriseId
      }/${parentid}/${username}`,
      data: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductImageURL = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductImageURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductCertificateURL = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductCertificateURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductQRURL = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductQrURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateStatusOfProduct = (
  username,
  parentId,
  businessId,
  productId,
  urlData
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductStatus}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductDiscountedPrice = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductDiscount}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductCost = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductCost}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductRating = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateProductRating}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductItemCount = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateItemCount}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const incrementItemCount = (parentId, businessId, productId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.IncrementItemCount}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: 1,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const decrementItemCount = (parentId, businessId, productId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.DecrementItemCount}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: 1,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateProductHistoryURL = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateHistoryURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const updateLabCertificate = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateLabCertificateURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const productVideo = (parentId, businessId, productId, urlData, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.ProductVideoURL}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const getProductItems = (parentId, businessId, productId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },

      url: `${getAppConfig().ApiUrlMapp.GetProductItems}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};

const deleteProductItem = (
  parentId,
  businessId,
  productId,
  itemid,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.DeleteProductItem}${
        getAppConfig().EnterpriseId
      }/${parentId}/${businessId}/${productId}/${itemid}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};
const uploadUSerImage = (username, payload) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.uploadUSerImage}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

const getModelProductsBasedOnModel = (
  parentId,
  username,
  verticalId,
  category,
  assetType,
  model,
  contentcategory
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.GetAgriProductProxeyDataBasedOnModel}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${verticalId}/${category}/${assetType}/${model}/${contentcategory}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};

/* End of Market apis */

/*start of logistic-manager APIS*/

//assetCountInfo:

const getAssetsCountInfo = (userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `consumer2`,
        suborgid: userName
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetsCountInfo}${
        getAppConfig().Realm
      }/${userName}`,
      
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//  getDeviceCountInfo....

const getDeviceCountInfo = (userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDeviceCountInfo}${
        getAppConfig().Realm
      }/${userName}`,
      //`http://13.233.122.53:8080/EAP/getMortalityDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// //  getDriversInfo....

const getDriversInfo = (userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDriversInfo}${
        getAppConfig().Realm
      }/${userName}/Logistics-TransportUser`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//tripplannerInfo:

const getTripPlannerInfo = (parentId, userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getTripPlannerInfo}${
        getAppConfig().Realm
      }/${userName}/info/summary`,
      // http://54.185.221.117:8902/tripplanner/consumer2/srinivasulu@altiux.com/info/summary
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//getmonthly trips:

const getMonthlyTrips = (parentId, userName) => {
  // console.log(userName, "geofencedetails...................");
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getMonthlyTrips}${
        getAppConfig().Realm
      }/${userName}/info/monthlyTrips`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getBookingReq = (userName) => {
  // console.log(userName, "geofencedetails...................");
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getBookingReq}${
        getAppConfig().Realm
      }/${userName}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
//getlogisticmanagerCount:

const getLogisticManagerCount = (userName) => {
  // console.log(userName, "geofencedetails...................");
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getLogisticManagerCount}${
        getAppConfig().Realm
      }/${userName}/Logistics-Manager`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
//update route tag:
const updateRouteName = (parentId, userName, data, routeId) => {
  // console.log('adfjskfj1', username, data);
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateRouteName}${
        getAppConfig().Realm
      }/${userName}/route/${routeId}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//update locationtag:
const updateLocation = (parentId, username, data, locId) => {
  // console.log('adfjskfj1', username, data);
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateLocationTag}${
        getAppConfig().Realm
      }/${username}/location/${locId}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//getLocationInfo:

export const getLocationInfo = async (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getLocationInfo}${
        getAppConfig().Realm
      }/${username}/location/list`,
  
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//ADD LOCATION.........
const addLocationData = (parentId,username, data) => {
  console.log('temdata................', data, username);

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },

      url: `${getAppConfig().ApiUrlMapp.addLocationData}${
        getAppConfig().Realm
      }/${username}/location/add`,

      data: data,
    })
      .then((res) => {
        // if (res.status !== 204) {
        //   console.error(res.data);y

        //   return reject(res.data);
        // }

        return resolve(res);
      })

      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};

//GET DETAILS OF ROUTEID:
export const getDetailsofRouteId = async (parentId, username, routeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getDetailsofRouteId}${
        getAppConfig().Realm
      }/${username}/route/${routeId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//addroute:

const addRouteApi = (parentId, username, data) => {
   return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.addRouteApi}${
        getAppConfig().Realm
      }/${username}/route/add`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);

          return reject(res.data);
        }

        return resolve(res);
      })

      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};

const getRouteInfo = (parentId, userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getRouteInfo}${
        getAppConfig().Realm
      }/${userName}/route/list?start=0&limit=100`,
      
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getTripInfo = (parentId, username, urls) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getTripInfo}${
        getAppConfig().Realm
      }/${username}/trip/list?${urls}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//..........GET DRIVERS............
const getDrivers = (parentId, userType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getDrivers}${
        getAppConfig().Realm
      }/${parentId}/${userType}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.users);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};



const vehicleStatus = (userName, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.vehicleStatus}${
        getAppConfig().Realm
      }/tenant/${userName}/asset/${assetId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getVehicleList = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: 'consumer2',
        suborgid: username 
      },
      url: `${getAppConfig().ApiUrlMapp.getVehicleList}${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const addTripApi = (parentId, username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.addTripApi}${
        getAppConfig().Realm
      }/${username}/trip/add`,
      data: data,
    })
      .then((res) => {
        // if (res.status !== 200) {
        //   return reject(res.data);
        // }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
//UPDATE TRIP DETAILS

const updateTripDetails = (parentId,username, data, tripId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateTripDetails}${
        getAppConfig().Realm
      }/${username}/trip/${tripId}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// UPDATE TRIP STATUS............
const updateTripStatus = (userName, tripId, tripCurrentStatus) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateTripStatus}${
        getAppConfig().Realm
      }/${userName}/trip/status/${tripId}?status=${tripCurrentStatus}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// ....................GEOFENCE......................


//create geofence
const createGeofence = (parentId, username, data, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.createGeofence}${
        getAppConfig().Realm
      }/${username}/geofence/vehicle/${vehicleId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//GET GEOFENCE DETAILS OF A ASSET
const geofenceDetails = (parentId, username, vehicleId) => {
  console.log(username, vehicleId, 'geofencedetails...................');
  

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.geofenceDetails}${
        getAppConfig().Realm
      }/${username}/geofence/vehicle/${vehicleId}`,
    })
      .then((res) => {
        // if (res.status !== 200) {
          return reject(res.data);
        // }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//UPDATE GEOFENCE OF AN ASSET
const updateGeofenceDetails = (parentId,username, data, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateGeofenceDetails}${
        getAppConfig().Realm
      }/${username}/geofence/vehicle/${vehicleId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//UPDATE GEOFENCE TYPE OF AN ASSET:
const updateGeofenceOfAssets = (parentId,username,data, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.updateGeofenceOfAssets}${
        getAppConfig().Realm
      }/${username}/${vehicleId}`,
      data: data,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// DELETE GEOFENCE
const delGeofence = (username, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.delGeofence}${
        getAppConfig().Realm
      }/${username}/geofence/vehicle/${vehicleId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//GEOFENCEDETAILSOF ALL VEHICLE:
const geofenceDetailOfAllVehicle = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.geofenceDetailOfAllVehicle}${
        getAppConfig().Realm
      }/${username}/geofence/vehicles`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const lastLocationDetails = (userName, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.lastLocationDetails}${
        getAppConfig().Realm
      }/tenant/${userName}/asset/${vehicleId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

export const getDetailsOfLocId = async (parentId, userName, locId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getDetailsOfLocId}${
        getAppConfig().Realm
      }/${userName}/location/${locId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};


const createBooking = (data, userName, parentId) => {
  console.log('temdata');

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: window.AppConfigData.EnterpriseId,
        orgid: `${getAppConfig().Realm}`,
        suborgid: userName,
        username: userName,
      },

      url: `${getAppConfig().ApiUrlMapp.createBooking}${
        getAppConfig().Realm
      }/${userName}/LogisticsManager`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);

          return reject(res.data);
        }

        return resolve(res.data);
      })

      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};

const getOrderListInfo = (userName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: window.AppConfigData.EnterpriseId,
        orgid: `${getAppConfig().Realm}`,
        suborgid: userName,
        username: userName,
      },
      url: `${getAppConfig().ApiUrlMapp.getOrderListInfo}${
        getAppConfig().Realm
      }/${userName}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//search trip details
const tripSearch = (parentId, userName, urls) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.tripSearch}${
        getAppConfig().Realm
      }/${userName}/trip/search?${urls}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//Modified on 23-01-2024:
//GET DETAILS OF ONGOING TRIPS FOR THE GIVEN VEHICLE:

const getVehicleTripDetails = (userName, vehicleId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: window.AppConfigData.EnterpriseId,
      },
      url: `${getAppConfig().ApiUrlMapp.getVehicleTripDetails}${
        getAppConfig().Realm
      }/${userName}/trip/vehicle/${vehicleId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//DELETE TRIP"

const delTrip = (parentId, userName, tripid) => {
  console.log(userName, tripid, 'delete TRIP...................');
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.delTrip}${
        getAppConfig().Realm
      }/${userName}/trip/delete/${tripid}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

// BOOKING MANAGEMENT APIS.............

const getOrderStatus = async (parentid, username, orderid) => {
  console.log(parentid,"PARENT ID:::::::::::")
  try {
    const axiosInstance = createAxiosInstances(
      "getOrderStatus",
      parentid,
      username,
      username
    );
    const response = await axiosInstance.get(
      `${getAppConfig().ConsumerAdmin}/${parentid}/${username}/${orderid}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getBookingDetailsApi = async (orgid, parentid, username, bookingId) => {
  try {
    const axiosInstance = createAxiosInstances(
      'getBookingDetails',
      orgid,
      username,
      username
    );
    const response = await axiosInstance.get(
      `${getAppConfig().ConsumerAdmin}/${parentid}/${username}/${bookingId}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

// get all bookings
const getAllBookingDetailsApi = async (orgid, parentid, username) => {
  try {
    const axiosInstance = createAxiosInstances(
      'getAllBookingsDetails',
      orgid,
      username,
      username
    );
    const response = await axiosInstance.get(
      `${getAppConfig().ConsumerAdmin}/${parentid}/${username}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

// map trip list with booking
const postTripMapRequestApi = async (
  parentid,
  username,
  orderid,
  bookingid,
  tripid
) => {
  try {
    const axiosInstance = createAxiosInstances(
      'postTripMapRequest',
      parentid,
      username,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentid}/${username}/${orderid}/${bookingid}/${tripid}`
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//unmap trip list with booking
const postTripUnmappingRequestApi = async (
  parentid,
  username,
  orderid,
  bookingid,
  tripid
) => {
  try {
    const axiosInstance = createAxiosInstances(
      'postTripUnmapReques',
      parentid,
      username,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentid}/${username}/${orderid}/${bookingid}/${tripid}`
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//map driver list with booking
const postDriverMapRequestApi = async (
  parentid,
  username,
  orderid,
  bookingid,
  driverid
) => {
  try {
    const axiosInstance = createAxiosInstances(
      'postDriverMapRequest',
      parentid,
      username,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentid}/${username}/${orderid}/${bookingid}/${driverid}`
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//unmap trip list with booking
const postDriverUnmappingRequestApi = async (
  parentid,
  username,
  orderid,
  bookingid,
  tripid
) => {
  try {
    const axiosInstance = createAxiosInstances(
      'postDriverUnmapRequest',
      parentid,
      username,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentid}/${username}/${orderid}/${bookingid}/${tripid}`
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// end of booking management..........................

const delLocation = (parentId, username, locid) => {
  console.log(username, locid, 'deleteLocation///**********');
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.delLocation}${
        getAppConfig().Realm
      }/${username}/location/delete/${locid}`,
    })
      .then((res) => {
        return resolve(res);
      })

      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};

//get info of particular tripid:
const getDetailsOfTripId = (parentId, userName, tripId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },
      url: `${getAppConfig().ApiUrlMapp.getDetailsOfTripId}${
        getAppConfig().Realm
      }/${userName}/trip/${tripId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//DELETE ROUTE:

const delRoute = (parentId, username, routeid) => {
  console.log(username, routeid, 'deleteLocation///**********');

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${getToken()}`,
        parent: parentId,
      },

      url: `${getAppConfig().ApiUrlMapp.delRoute}${
        getAppConfig().Realm
      }/${username}/route/delete/${routeid}`,
    })
      .then((res) => {
        return resolve(res.data);
      })

      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};


                  /*/////////////////End of logistic-manager APIS*/////////////

                  /*/////////////////////start of TransportUser API'*/////////////////////

                  const getOrderStatusDetails = (username, cardData) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'get',
                        url: `${getAppConfig().ApiUrlMapp.getOrderStatusDetails}get-request-details?id=${cardData}`,
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          username: username,
                          "parent-id": `${getAppConfig().Realm}`,
                          "network-id": `consumer2`,
                          'Content-Type': 'application/json',
                        },
                      })
                        .then((res) => {
                          // if (res.status !== 200) {
                          //   console.error(res.data);
                          //   return reject(res.data);
                          // }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject('Internal server error, please try again.');
                        })
                    );
                  };
                  
                  const getOrderStatusApi = (username, orderId) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'get',
                        url: `${getAppConfig().ApiUrlMapp.getOrderStatusApi}get-request-status?id=${orderId}`,
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          username: username,
                          "parent-id": `${getAppConfig().Realm}`,
                          "network-id": `consumer2`,
                          'Content-Type': 'application/json',
                        },
                      })
                        .then((res) => {
                          // if (res.status !== 200) {
                          //   console.error(res.data);
                          //   return reject(res.data);
                          // }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject('Internal server error, please try again.');
                        })
                    );
                  };

                  const getBookingCount = (username, parentId) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'get',
                        url: `${getAppConfig().ApiUrlMapp.getBookingCount}${
                          getAppConfig().Realm
                        }/${username}`,
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          networkId: `consumer2`,
                          parentId: `${getAppConfig().Realm}`,
                          username: username,
                          'Content-Type': 'application/json',
                        },
                      })
                        .then((res) => {
                          if (res.status !== 200) {
                            console.error(res.data);
                            return reject(res.data);
                          }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject('Internal server error, please try again.');
                        })
                    );
                  };
// RAKSHIT APIS FOR DRIVER (VERSION-2)
const getAllBookingIds = (parentId,username,bpoId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllBookingIds}${
        getAppConfig().Realm
      }/${username}/${bpoId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        networkId:`consumer2`,
        parentId: parentId,
        username: username
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const updateBookingStatusRakshitApi = (username,bookingId, data) => {

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'PATCH',
      url: `${getAppConfig().ApiUrlMapp.updateBookingStatusRakshitApi}${bookingId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        networkId: `consumer2`,
        parentId:  `${getAppConfig().Realm}`,
         username: username
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const updateBookingStatus = (username,detail, data) => {
  console.log(detail, 'reeeeeeeeeeeeeeeeeabzzzzzznan-------------------------');
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'PATCH',
      url: `${getAppConfig().ApiUrlMapp.updateBookingStatus}${detail.bookingId}`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        "networkId": `consumer2`,
        "parentId":  `${getAppConfig().Realm}`,
        username: username
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getBookingDetailsBasedOnBookingId = (parentId,username, cardData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getBookingDetailsBasedOnBookingId}getBookingDetails?networkId=consumer2&bookingId=${cardData}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
       'Content-Type': 'application/json',
       networkId: `consumer2`,

        parentId: parentId,
        username: username
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getDocLinksForOrder = (parentId,username,bpoId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getDocLinksForOrder}&bookingId=${bpoId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
       "networkid": `consumer2`,
       "parentId": parentId,
        "username":username
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);

      })
      
      .catch((e) => {
       
        return reject(e.data);

      })
  );
};

const getLatestOrderStatus = (parentId,username,bpoId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getLatestOrderStatus}get-current-order-status?id=${bpoId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
       "network-id": `consumer2`,
       "parent-id": parentId,
        username:username
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);

      })
      
      .catch((e) => {
       
        return reject(e.data);

      })
  );
};

                                // MAPLEGACYASSETTONODEID

                  const MapLegacyAssetToNode = (userName,assetId,data) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'post',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${getToken()}`,
                    
                        },
                        url:`${getAppConfig().ApiUrlMapp.MapLegacyAssetToNode}${
                          getAppConfig().Realm
                        }/${userName}/${assetId}/tempo`,
                        data: data,
                      })
                        .then((res) => {
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject(e.data);
                        })
                    );
                  };
 

                  //ACCOUNT UPGRADATION APIS:

                  /* POST REQ */
                  const postAccountUpgradationReq = (data) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'post',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${getToken()}`,
                    
                        },
                        url: `${getAppConfig().ApiUrlMapp.postAccountUpgradationReq}${
                          getAppConfig().Realm
                        }`,
                        data: data,
                      })
                        .then((res) => {
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject(e.data);
                        })
                    );
                  };

                  //GET REQ:
                  const getAccountUpgradationList = ( userName) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: "get",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${getToken()}`,
                    
                        },
                        url: `${getAppConfig().ApiUrlMapp.getAccountUpgradationList}${
                          getAppConfig().Realm
                        }/${userName}`,
                      })
                        .then((res) => {
                          if (res.status !== 200) {
                            return reject(res.data);
                          }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject(e.data);
                        })
                    );
                  };


                  //GET DETAILS OF PARTICULAR REQUESTID:

                  const getAccountUpgradationReqStatus = (  requestId) => {
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: "get",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${getToken()}`,
                    
                        },
                        url: `${getAppConfig().ApiUrlMapp.getAccountUpgradationReqStatus}${
                          getAppConfig().Realm
                        }/${requestId}`,
                      })
                        .then((res) => {
                          if (res.status !== 200) {
                            return reject(res.data);
                          }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject(e.data);
                        })
                    );
                  };


                  const getMyBooking = (username, parentId) => {
                    console.log(username, parentId);
                  
                    return new Promise((resolve, reject) =>
                      HTTP_Client({
                        method: 'get',
                        url: `${getAppConfig().ApiUrlMapp.getMyBooking}${parentId}/${username}`,
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          username: username,
                          parentId: `${parentId}`,
                          networkId: `consumer2`,
                          'Content-Type': 'application/json',
                        },
                      })
                        .then((res) => {
                          if (res.status !== 200) {
                            console.error(res.data);
                            return reject(res.data);
                          }
                          return resolve(res.data);
                        })
                        .catch((e) => {
                          return reject('Internal server error, please try again.');
                        })
                    );
                  };

const getDriverScheduleForLm = (parentId,username, urls) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getDriverScheduleForLm}${
        getAppConfig().Realm
      }/${username}/trip/driverSchedule/${urls}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getVehicleSchedule = (parentId,username, urls) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getVehicleSchedule}${
        getAppConfig().Realm
      }/${username}/trip/vehicleSchedule/${urls}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        parent: parentId,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getDriverSchedule = (parentId, urls) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getDriverSchedule}${
        getAppConfig().Realm
      }/trip/driverSchedule/${parentId}?${urls}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        parent: `${getAppConfig().Realm}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getTripDetailsDriver = (trip) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getTripDetailsDriver}${
        getAppConfig().Realm
      }/trip/${trip}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        parent: `${getAppConfig().Realm}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getDriverOrder = (username, cardData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getDriverOrder}${
        getAppConfig().Realm
      }/${username}/${cardData}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        username: username,
        orgid: `${getAppConfig().Realm}`,
        suborgid: ``,
        networkid: `consumer2`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getAllOrderCount = (username, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getAllOrderCount}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        username: username,
        orgid: `${getAppConfig().Realm}`,
        suborgid: ``,
        networkid: `consumer2`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getMyorderDetails = (detail) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMyorderDetails}${
        getAppConfig().Realm
      }/${detail.orderid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const PostMyOrderStatus = (detail, data) => {
  console.log(detail, 'reeeeeeeeeeeeeeeeeabzzzzzznan-------------------------');
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.PostMyOrderStatus}${
        getAppConfig().Realm
      }/${detail.orderid}`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getMyRoute = (routeData) => {
  console.log(routeData, 'eeeeeeeeer12345678899-----------');

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMyRoute}${
        getAppConfig().Realm
      }/route/${routeData.routeId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        parent: `${getAppConfig().Realm}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};



const getFinalTripStatus = (cardData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      url: `${getAppConfig().ApiUrlMapp.getFinalTripStatus}${
        getAppConfig().Realm
      }/trip/status/${cardData}?status=INPROGRESS`,

      headers: {
        Authorization: `Bearer ${getToken()}`,

        parent: `${getAppConfig().Realm}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);

          return reject(res.data);
        }

        return resolve(res.data);
      })

      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const getFinalTripStatus1 = (cardData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      url: `${getAppConfig().ApiUrlMapp.getFinalTripStatus1}${
        getAppConfig().Realm
      }/trip/status/${cardData}?status=COMPLETED`,

      headers: {
        Authorization: `Bearer ${getToken()}`,

        parent: `${getAppConfig().Realm}`,

        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);

          return reject(res.data);
        }

        return resolve(res.data);
      })

      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

const updateOrderStatus = (username,orderId, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'PATCH',
      url: `${getAppConfig().ApiUrlMapp.updateOrderStatus}update-current-order-status?id=${orderId}`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        "network-id": `consumer2`,
        "parent-id":  `${getAppConfig().Realm}`,
          username: username
      },
      data: data,
    })
      .then((res) => {
      
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};
// //DELETE NODE:
// const deleteNode = (data) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: 'post',

//       url: `${getAppConfig().ApiUrlMapp.deleteNode}${
//         getAppConfig().Realm
//       }`,

//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//         "networkid":'consumer2',
//         "suborgid": "",
//         "Content-Type": 'application/json',
//       },
//       data:data,
//     })
//       .then((res) => {
//         if (res.status !== 201) {
//           console.error(res.data);
//           return reject(res.data);
//         }

//         return resolve(res.data);
//       })

//       .catch((e) => {
//         return reject('Internal server error, please try again.');
//       })
//   );
// };
 

//CREATE NODE FOR TRANSPORT USER:
const createNode = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',

      url: `${getAppConfig().ApiUrlMapp.createNode}${
        getAppConfig().Realm
      }`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
        "networkid":'consumer2',
        "suborgid": "",
        "Content-Type": 'application/json',
      },
      data:data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }

        return resolve(res.data);
      })

      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

//GET MAPPED ASSETS TO NODE...
const mappedAssetsWithNode = (username,nodeId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.mappedAssetsWithNode}${
        getAppConfig().Realm
      }/${username}/${nodeId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject('Internal server error, please try again.');
      })
  );
};

// CREATE MODEL

const createModel = (username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.createModel}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Model already exist.');
        } else return reject('Internal server error, please try again.');
      })
  );
};

const getVehiclesBasedOnModel = (
  username,
  
  modelId,
  assetverticalId,
  
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getVehiclesBasedOnModel}${
        getAppConfig().Realm
      }/${username}/tempo/${modelId}/${assetverticalId}/tempo`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        networkid:'consumer2',
        suborgid:`${username}`
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.assets);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};



                          /*///////////////End of TransportUser API's/////////////////*/


const getSitesBasedOnEmail = (parentId, farmerName) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getSitesBasedOnEmail
      }${parentId}/${farmerName}`,
  
    })
      .then((res) => {
        return resolve(res.data.sites);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/* Get username, parentId,org and sub org id */
const checkGrandParent = async (parent, user, second) => {
  console.log('*******************', parent, user, second);

  if (user === window.AppConfigData.EnterpriseId) {
    let orgId, subOrgId;
    try {
      if (second) {
        orgId = await getConsumerUserType(user, second);
      }
    } catch (error) {
      console.error('Error retrieving orgId:', error);
      return null;
    }
    try {
      if (parent === undefined) {
        return;
      }
      subOrgId = (await getConsumerUserType(second, parent)) || '';
    } catch (error) {
      console.error('Error retrieving subOrgId:');
      subOrgId = '';
    }

    const obj = {
      orgId: orgId ? second : '',
      subOrgId: subOrgId ? parent : '',
    };
    return obj;
  }
  const seconParent = user;
  const tempUser = await getGrandParentId(parent, user);
  if (!tempUser) {
    return null; // Stop the recursive chain if tempUser is undefined
  }
  return checkGrandParent(parent, tempUser, seconParent);
};
const getOrgId = async () => {
  const data = JSON.parse(atob(localStorage.getItem('CBN')));
  const { user } = data;
  const { parentId, username } = user;
  const result = await checkGrandParent(parentId, username, '');

  if (result !== null && result !== undefined) {
    const { orgId, subOrgId } = result;
    user.orgid = orgId;
    user.suborgid = subOrgId;
    console.log('*************************', orgId, subOrgId);
  } else {
    console.log('Unable to retrieve orgId and subOrgId.');
  }

  localStorage.setItem('CBN', btoa(JSON.stringify(data)));
};
const getGrandParentId = async (parentId, username) => {
  const axiosInstance = createAxiosInstance('getGrandParent');
  try {
    const { data } = await axiosInstance.get(`${parentId}/${username}`);
    return data;
  } catch (error) {
    console.log('error');
  }
};

// GET USER role
const getConsumerUserTypeRoleProfile = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getConsumerUserTypeRoleProfile
      }${parentId}/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        console.log('getConsumerUserTypeRole Profile in main service:', res);
        return resolve(res.data.role);
      })
      .catch((e) => {
        // if (e.response && e.response.data && e.response.data.notification)
        return reject(e);
        // return reject("Internal server error, please try again.");
      })
  );
};

// get document data based on id
const documentDetailsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  docId
) => {
  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.get(
      `${getAppConfig().ConsumerAdmin}/${parentId}/${username}/getDocById`,
      { params: { docId: docId } }
    );
    return response;
  } catch (error) {
    console.log(`Document not genrated.`);
  }
};

/* get the document list */
const documentListApi = async (parentId, orgid, suborgid, username, type) => {
  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.get(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentId}/${username}/getDocsByCreatorId`,
      { params: { type, docCreatorId: username } }
    );
    return response;
  } catch (error) {
    console.log(`${type} document not found.`);
  }
};

/* save the document api */
const saveDocumentsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  formData
) => {
  console.log('formData', formData);

  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${parentId}/${username}/createDoc`,
      formData,
      {
        params: {
          type: formData.type ? formData.type : formData.reportDataDetails.type,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.message === 'Network Error') {
      console.log(
        'Network connection issue. Please check your internet connection.'
      );
      return 'Network issue, Please connect to internet';
    } else {
      return `${
        formData?.type?.charAt(0)?.toUpperCase() + formData.type.slice(1)
      } document not genrated.`;
    }
  }
};

/* update the document api */
const updateDocumentsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  formData,
  docId,
  type
) => {
  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.put(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentId}/${username}/updateReportDetails`,
      formData,
      {
        params: {
          type,
          docId,
          docCreatorId: username,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.message === 'Network Error') {
      console.log(
        'Network connection issue. Please check your internet connection.'
      );
      return 'Network issue, Please connect to internet';
    } else {
      return `${
        formData.type.charAt(0).toUpperCase() + formData.type.slice(1)
      } document not genrated.`;
    }
  }
};

/* review the document api */
const reviewDocumentsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  formData,
  docId,
  type
) => {
  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.put(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentId}/${username}/reviewedToUpdate`,
      formData,
      {
        params: {
          type,
          docId,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.message === 'Network Error') {
      console.log(
        'Network connection issue. Please check your internet connection.'
      );
      return 'Network issue, Please connect to internet';
    } else {
      return `${
        formData.type.charAt(0).toUpperCase() + formData.type.slice(1)
      } document not genrated.`;
    }
  }
};

// Delete document api
const deleteDocumentsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  docId
) => {
  try {
    const axiosInstance = createDocumentInstance(
      'saveOrGetListDocuments',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.delete(
      `${getAppConfig().ConsumerAdmin}/${parentId}/${username}/deleteDocById`,
      {
        params: {
          docId,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.message === 'Network Error') {
      console.log(
        'Network connection issue. Please check your internet connection.'
      );
      return 'Network issue, Please connect to internet';
    } else {
      return 'Document not deleted.';
    }
  }
};

/* Genrate the document api */
const generateDocumentsApi = async (
  parentId,
  orgid,
  suborgid,
  username,
  formData,
  type
) => {
  console.log('gen', formData);
  try {
    const axiosInstance = createDocumentInstance(
      'genrateDocument',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${parentId}/${username}/${type}`,
      formData
    );
    return response;
  } catch (error) {
    console.log(`${type} document not genrated.`);
  }
};

const createQuotationApi = async (url, orgid, suborgid, formData, username) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createQutation',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${url}/genBillQuotation`,
      formData
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
const createPurchaseOrderApi = async (
  url,
  orgid,
  suborgid,
  formData,
  username
) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createPurchaseOrder',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${url}/genBillpo`,
      formData
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
const createProformaInvoiceApi = async (
  url,
  orgid,
  suborgid,
  formData,
  username
) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createProformaInvoice',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${url}/genBillProformaInvoice`,
      formData
    );
    return response;
  } catch (error) {
    console.log('proforma error', error);
  }
};
const createTaxInvoiceApi = async (
  url,
  orgid,
  suborgid,
  formData,
  username
) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createTaxInvoice',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${getAppConfig().ConsumerAdmin}/${url}/genBillInvoice`,
      formData
    );
    return response;
  } catch (error) {
    console.log('proforma error', error);
  }
};
// Delivery challan
const createDeliveryChallanApi = async (
  parentId,
  username,
  orgid,
  suborgid,
  formData
) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createDeliveryChallan',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentId}/${username}/genBillDeliveryChallan`,
      formData
    );
    return response;
  } catch (error) {
    console.log('challan error', error);
  }
};

// Delivery receipt
const createDeliveryReceiptApi = async (
  parentId,
  username,
  orgid,
  suborgid,
  formData
) => {
  try {
    const axiosInstance = createInvoiceInstance(
      'createDeliveryReceipt',
      orgid,
      suborgid,
      username
    );
    const response = await axiosInstance.post(
      `${
        getAppConfig().ConsumerAdmin
      }/${parentId}/${username}/genBillDeliveryReceipt`,
      formData
    );
    return response;
  } catch (error) {
    console.log('receipt error', error);
  }
};

const unMapProductItem = (parentId, username, productId, itemId, siteId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
        "networkid": `${getAppConfig().EnterpriseId}`,
        "orgid": `${username}`,
        "suborgid":"",
        "username": `${username}`
      },
      url: `${getAppConfig().ApiUrlMapp.unmapproductitemwithsite}${getAppConfig().EnterpriseId}/${parentId}/${username}/${productId}/${itemId}/${siteId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.MESSAGE);
        }
        return resolve(res.data.MESSAGE);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == "Error: Request failed with status code 409") {
          return reject("Content already exist.");
        }
        return reject("Internal server error, please try again.");
      })
  );
};
const productItemStatus = (parentId, username, productId, itemId, statusVal) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "networkid": `${getAppConfig().EnterpriseId}`,
        "orgid": `${username}`,
        "suborgid":"",
        "username": `${username}`
      },
      url: `${getAppConfig().ApiUrlMapp.productitemstatus}${getAppConfig().EnterpriseId}/${parentId}/${username}/${productId}/${itemId}`,
      data: statusVal,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.MESSAGE);
        }
        return resolve(res.data.MESSAGE);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == "Error: Request failed with status code 409") {
          return reject("Content already exist.");
        }
        return reject("Internal server error, please try again.");
      })
  );
};

// document request for rfq

const postRequestQuotation = async (data) => {
  try {
    console.log('logging data', data);
    const { networkId, parentId, username } = data.personalDetails || {};
    const requestType = data.requestType || '';
    const axiosInstance = createAxiosDocumentInstances('documentRfqRequest');
    const queryParams = {
      'request-type': requestType,
      'network-id': networkId,
      'parent-id': parentId,
      username: username,
    };

    const response = await axiosInstance.post('procure-request', data, {
      params: queryParams,
    });

    // const response = await axiosInstance.post(
    //   `procure-request?request-type=${requestType}&network-id=${networkId}&parent-id=${parentId}&username=${username}`,data
    // );

    return response;
  } catch (error) {
    console.log('document error', error);
  }
};

// get document details based on id
const getDocumentsById = async (id, parentId, username) => {
  try {
    const axiosInstance = createAxiosDocumentInstances('documentRfqRequest',parentId, username);
    const queryParams = {
      // 'request-type': requestType,
      id:id,
    };
    const response = await axiosInstance.get(`get-request-details`, {
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.log('error', 'can not get document');
  }
};

const mapProductItem = (
  parentId,
  username,
  productId,
  itemId,
  siteId,
  siteName
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.mapproductitemwithsite}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${productId}/${itemId}/${siteId}/${siteName}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.MESSAGE);
        }
        return resolve(res.data.MESSAGE);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};



/* End username, parentId,org and sub org id */
/* Site Management */
const getNutritionInfo = (assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getNutritionInfo}${
        getAppConfig().Realm
      }/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getNutritions/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getAssetFeedback = (username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetFeedback}${
        getAppConfig().Realm
      }/${username}/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getHealthRecord/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.feedbacks);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getAssetHealthInfo = (assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAssetHealthInfo}${
        getAppConfig().Realm
      }/${assetId}`,
      //`http://13.233.122.53:8080/EAP/getHealthRecord/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
/* Site Management */
const getRegisterAccountRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getRegisterAccountRequests}${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.registeredUsers);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getUsersRoleRequests = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getUsersRoleRequests}${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data.registeredUsers);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getSuperRoles = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${getAppConfig().ApiUrlMapp.getSuperRoles}${
        getAppConfig().Realm
      }/${username}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};
const upgradeRoleRequest = (parentId,data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.upgradeRoleRequest}${parentId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == "Error: Request failed with status code 409") {
          /* eslint eqeqeq: 0 */
          return reject("Role request already exist.");
        } else return reject("Internal server error, please try again.");
      })
  );
};
const requestStatusBasedonRequestid = (parentId,requestId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.requestStatusBasedonRequestid}${parentId}/${requestId}`,
      
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == "Error: Request failed with status code 409") {
          /* eslint eqeqeq: 0 */
          return reject("Role request already exist.");
        } else return reject("Internal server error, please try again.");
      })
  );
};
const updateRegistrationRequestStatus = (data, parentId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateRegistrationRequestStatus}${parentId}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data["ERROR_MESSAGE"]);
        }
        return resolve(res.data["SUCCESS_MESSAGE"]);
      })
      .catch((e) => {
        reject("Internal server error, please try again.");
      })
  );
};
const requestInfoBasedonRequestid = (parentId,requestId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.requestInfoBasedonRequestid}${parentId}/${requestId}`,
      
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == "Error: Request failed with status code 409") {
          /* eslint eqeqeq: 0 */
          return reject("Role request already exist.");
        } else return reject("Internal server error, please try again.");
      })
  );
};
// const getUserKYCDocuments = (username, user) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: "get",
//       url: `${getAppConfig().ApiUrlMapp.getKYCDocuments}${
//         getAppConfig().Realm
//       }/${username}/${user}`,
//       //"http://13.233.122.53:8080/ContractGw/getKycDocument/consumer2/" + username,
//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//         Accept: "application/json",
//       },
//     })
//       .then((res) => {
//         if (res.status !== 200) {
//           console.error(res.data);
//           return reject(res.data);
//         }

//         return resolve(res.data.documents);
//       })
//       .catch((e) => {
//         return reject("Internal server error, please try again.");
//       })
//   );
// };
// const createSiteType = (parentId, username, data) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${getAppConfig().ApiUrlMapp.createSiteType}${
//         getAppConfig().Realm
//       }/${parentId}/${username}`,
//       data: data,
//     })
//       .then((res) => {
//         if (res.status !== 201) {
//           console.error(res.data);
//           return reject(res.data);
//         }
//         return resolve(res.data["SUCCESS MESSAGE"]);
//       })
//       .catch((e) => {
//         reject("Internal server error, please try again.");
//       })
//   );
// };
// const deleteSiteType = (parentId, username, siteType) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: "delete",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${getAppConfig().ApiUrlMapp.deleteSiteType}${
//         getAppConfig().Realm
//       }/${parentId}/${username}/${siteType}`,
//     })
//       .then((res) => {
//         if (res.status !== 204) {
//           console.error(res.data);
//           return reject(res.data);
//         }
//         return resolve(res.data);
//       })
//       .catch((e) => {
//         reject("Internal server error, please try again.");
//       })
//   );
// };

const updateProductInfoURL = (
  parentId,
  businessId,
  productId,
  urlData,
  username
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateproductinfodata}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};
const buyUrlProduct = (parentId, businessId, productId, urlData, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
      url: `${getAppConfig().ApiUrlMapp.buyurlproduct}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${productId}`,
      data: urlData,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errorMessage)
          return reject(e.response.data.errorMessage);
        if (e == 'Error: Request failed with status code 409') {
          return reject('Content already exist.');
        }
        return reject('Internal server error, please try again.');
      })
  );
};
// quote request api's
const orderManagement = (reqType, data, parent, user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${getAppConfig().ApiUrlMapp.procurerequest}${reqType}`,
      data: data,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getQuotesBasedOnAssetVerticals = (
  reqType,
  verId,
  cateId,
  assetType,
  modelId,
  parent,
  user
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getallrequestidsbasedonverticalsandcategory
      }network-id=${
        getAppConfig().EnterpriseId
      }&request-type=${reqType}&rfq-status=&asset-vertical-friendly-name=${verId}&category=${cateId}&legacy-asset-type=${assetType}&model-id=${modelId}&page-no=&page-size=&sort-by=&sort-dir=`,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getQuotesBasedOnCategory = (
  reqType,
  categoryType,
  subCategory,
  parent,
  user
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getallrequestidsbasedonverticalsandcategory
      }network-id=${
        getAppConfig().EnterpriseId
      }&request-type=${reqType}&product-category=${categoryType}&product-sub-category=${subCategory}&product-model-id=&rfq-status=&page-no=&page-size=&sort-by=&sort-dir=`,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const updateRFQStatus = (rfqId, data, parent, user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updaterfqstatus}${rfqId}`,
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res?.data?.rfqStatus);
        }
      })
      .catch((e) => {
        return reject(e?.message);
      })
  );
};

const viewQuotes = (rfqId, parent, user) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getallquotesbasedonid}${rfqId}`,
    })
      .then((res) => {
        if (res.status === 200) {
          return resolve(res?.data?.quotes);
        } else if (res.status === 204) {
          return reject("No data found");
        }
      })
      .catch((e) => {
        return reject(e?.error);
      })
  );
};

const getQuoteDataLoopforProducts = (
  reqType,
  categoryType,
  rfqStatusFilter,
  page,
  rowsPerPage,
  sortBy,
  sortOrder,
  parent,
  user
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getallrequestidsbasedonverticalsandcategory
      }network-id=${
        getAppConfig().EnterpriseId
      }&request-type=${reqType}&product-category=${categoryType}&product-sub-category=&product-model-id=&rfq-status=${rfqStatusFilter}&page-no=${page}&page-size=${rowsPerPage}&sort-by=${sortBy}&sort-dir=${sortOrder}`,
    })
      .then((response) => {
        // Check if the status code is 204 No Content
        if (response.status === 204) {
          // Set transportReqData to an empty array to indicate no data
          return resolve([]);
        } else {
          // Process the data if the status code is not 204
          const quoteIds = response.data.map((item) => item.rfqId);
          const requests = quoteIds.map((rfqId) => {
            return HTTP_Client({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
                "network-id": `${getAppConfig().EnterpriseId}`,
                "parent-id": `${parent}`,
                username: `${user}`,
              },
              url: `${getAppConfig().ApiUrlMapp.getrequestdetails}${rfqId}`,
            })
              .then((res) => res?.data)
              .catch((error) => reject(error));
          });

          Promise.all(requests)
            .then((details) => {
              return resolve(details);
            })
            .catch((error) => {
              return reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getQuoteDataLoopforAssets = (
  reqType,
  rfqStatusFilter,
  assetVerticalName,
  selectedCategory,
  selectedAssertTypes,
  selectedModel,
  page,
  rowsPerPage,
  sortBy,
  sortOrder,
  parent,
  user
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "network-id": `${getAppConfig().EnterpriseId}`,
        "parent-id": `${parent}`,
        username: `${user}`,
      },
      url: `${
        getAppConfig().ApiUrlMapp.getallrequestidsbasedonverticalsandcategory
      }network-id=${
        getAppConfig().EnterpriseId
      }&request-type=${reqType}&rfq-status=${rfqStatusFilter}&asset-vertical-friendly-name=${assetVerticalName}&category=${selectedCategory}&legacy-asset-type=${selectedAssertTypes}&model-id=&page-no=${page}&page-size=${rowsPerPage}&sort-by=${sortBy}&sort-dir=${sortOrder}`,
    })
      .then((response) => {
        // Check if the status code is 204 No Content
        if (response.status === 204) {
          // Set transportReqData to an empty array to indicate no data
          return resolve([]);
        } else {
          // Process the data if the status code is not 204
          const quoteIds = response.data.map((item) => item.rfqId);
          const requests = quoteIds.map((rfqId) => {
            return HTTP_Client({
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
                "network-id": `${getAppConfig().EnterpriseId}`,
                "parent-id": `${parent}`,
                username: `${user}`,
              },
              url: `${getAppConfig().ApiUrlMapp.getrequestdetails}${rfqId}`,
            })
              .then((res) => res?.data)
              .catch((error) => reject(error));
          });

          Promise.all(requests)
            .then((details) => {
              return resolve(details);
            })
            .catch((error) => {
              return reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getContentsBasedonContentCreator = (username, categoryid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: "get",
      url: `${getAppConfig().ApiUrlMapp.getMyCategoryTender}${
        getAppConfig().Realm
      }/contentcreator/${username}/${categoryid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject("Internal server error, please try again.");
      })
  );
};
// end quote request api's

// store manager
const getAttendenceManager = (selectedAssetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getAttendenceManager}${
        getAppConfig().Realm
      }/${selectedAssetId}`,
      //`http://13.233.122.53:8080/EAP/getHealthRecord/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const getFeedbackManager = (Asset) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getFeedbackManager}${
        getAppConfig().Realm
      }/${getAppConfig().Realm}/${Asset}`,
      //`http://13.233.122.53:8080/EAP/getHealthRecord/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const UpdateStorageStatus = (selectedOption, selectedSite) => {
  console.log(selectedOption, selectedSite, 'shivam singh altiux innovation');
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.UpdateStorageStatus}${
        getAppConfig().Realm
      }/${selectedSite}`,
      //`http://13.233.122.53:8080/EAP/addParturitionDetails/consumer2`,
      data: selectedOption,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

const GetStatusOfStorage = (Site) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.GetStatusOfStorage}${
        getAppConfig().Realm
      }/${Site}`,
      //`http://13.233.122.53:8080/EAP/getParturitionDetails/consumer2/${username}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
const getProductItemsOfSites = (parentId, resourceId, username) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${
        getAppConfig().ApiUrlMapp.getProductItemsOfSites
      }${parentId}/${username}/${resourceId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `consumer2`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};
/* UserTypes */
const createUserType = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createUserType}${
        getAppConfig().Realm
      }/${username}`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
const deleteUserType = (username, usertype) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteUserType}${
        getAppConfig().Realm
      }/${username}/${usertype}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
/* End of UserTypes */
const createOrder = (requestType, orderData) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${getAppConfig().ApiUrlMapp.createOrder}/${requestType}`,
      data: orderData,
    })
      .then((res) => {
        if (res.status !== 201) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};
// get all Order apis
const getAllOrderIds = () => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${getAppConfig().ApiUrlMapp.getAllOrderIds}?networkId=${
        getAppConfig().ConsumerAdmin
      }`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

//getAllOrders based on request Type = contract Farming
const getAllOrderIds1 = (requestType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${getAppConfig().ApiUrlMapp.getAllOrderIds}?networkId=${
        getAppConfig().ConsumerAdmin
      }&requestType=${requestType}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};

/* Points in IAA Management */
const createPoint = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createPoint}${
        getAppConfig().Realm
      }/${username}`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
const getPoints = (username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getPoints}${
        getAppConfig().Realm
      }/${username}/${assetId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.points);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getSolutionDevices = (username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getSolutionDevices}${username}/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const unmapPointWithNode = (username, pointId, deviceId, AssetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.unmapPointWithNode}${
        getAppConfig().Realm
      }/${username}/${pointId}/${deviceId}/${AssetId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e == 'Error: Request failed with status code 409') {
          /* eslint eqeqeq: 0 */
          return reject('Device already mapped');
        } else return reject('Internal server error, please try again.');
      })
  );
};
const mapPointWithNode = (username, pointId, deviceId, AssetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.mapPointWithNode}${
        getAppConfig().Realm
      }/${username}/${pointId}/${deviceId}/${AssetId}`,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
const createSiteType = (parentId, username, data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.createSiteType}${
        getAppConfig().Realm
      }/${parentId}/${username}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data['SUCCESS MESSAGE']);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
const deleteSiteType = (parentId, username, siteType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteSiteType}${
        getAppConfig().Realm
      }/${parentId}/${username}/${siteType}`,
    })
      .then((res) => {
        if (res.status !== 204) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
const updatePointName = (data, username, assetId) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updatePointName}${
        getAppConfig().Realm
      }/${username}/${assetId}`,

      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};

const assetPointsDelete = (assetIds, pointId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.assetPointDelete}${
        getAppConfig().Realm
      }/${username}/${assetIds}/${pointId}`,
      //`http://proxy.gbrservice.com/EAP/deleteAsset/consumer2/${username}/${assetIds}/${assetTypes}/${modelIds}/${assetverticalIds}/${categoryTypes}`,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((e) => {
        return reject(e.data);
      })
  );
};


const updateRegistrationRequest = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateRegistrationRequest}${
        getAppConfig().Realm
      }`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data['ERROR_MESSAGE']);
        }
        return resolve(res.data['SUCCESS_MESSAGE']);
      })
      .catch((e) => {
        reject('Internal server error, please try again.');
      })
  );
};
// const upgradeRoleRequest = (parentId, data) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${getAppConfig().ApiUrlMapp.upgradeRoleRequest}${parentId}`,
//       data: data,
//     })
//       .then((res) => {
//         if (res.status !== 200) {
//           console.error(res.data);
//           return reject(res.data.message);
//         }
//         return resolve(res.data);
//       })
//       .catch((e) => {
//         if (e == 'Error: Request failed with status code 409') {
//           /* eslint eqeqeq: 0 */
//           return reject('Role request already exist.');
//         } else return reject('Internal server error, please try again.');
//       })
//   );
// };
const getContentsBasedonCategoryandSubcat = (
  username,
  categoryid,
  subcatname
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMyCategoryTender}${
        getAppConfig().Realm
      }/contentowner/${username}/${categoryid}/${subcatname}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};
const getContentsBasedonCreator = (username, categoryid) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.getMyCategoryTender}${
        getAppConfig().Realm
      }/contentcreator/${username}/${categoryid}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data.contentList);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      })
  );
};

// const requestInfoBasedonRequestid = (parentId, requestId) => {
//   return new Promise((resolve, reject) =>
//     HTTP_Client({
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`,
//       },
//       url: `${
//         getAppConfig().ApiUrlMapp.requestInfoBasedonRequestid
//       }${parentId}/${requestId}`,
//     })
//       .then((res) => {
//         if (res.status !== 200) {
//           console.error(res.data);
//           return reject(res.data.message);
//         }
//         return resolve(res.data);
//       })
//       .catch((e) => {
//         if (e == 'Error: Request failed with status code 409') {
//           /* eslint eqeqeq: 0 */
//           return reject('Role request already exist.');
//         } else return reject('Internal server error, please try again.');
//       })
//   );
// };
const getDeviceCloudList = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getCloudServer}/${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const publishSharedCloud = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.devicePublish}/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const publishDedicatedCloud = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.devicePublish}/${getAppConfig().Realm}`,
      data: data,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const updateDeviceCloud = (
  username,
  deviceCloudServerType,
  tenancyType,
  selectedStatus
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.updateDeviceCloudStatus}/${
        getAppConfig().Realm
      }/${username}/${deviceCloudServerType}/${tenancyType}/${selectedStatus}`,
      data: undefined,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const deleteDeviceCloud = (
  username,
  deviceCloudServerType,
  tenancyType,
  version
) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteDeviceCloud}/${
        getAppConfig().Realm
      }/${username}/${deviceCloudServerType}/${tenancyType}/${version}`,
      data: undefined,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getFirmwareList = (parentId, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      headers: {
        'Content-Type': 'application/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.getfirmwareList}/${
        getAppConfig().Realm
      }/${username}`,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const deleteFirmware = (version) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteFirmware}/${
        getAppConfig().Realm
      }/${version}`,
      data: undefined,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const addFirmwareFile = (formdata, username) => {
  console.log(formdata, 'formdata');

  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addFirmwareFile}/${
        getAppConfig().Realm
      }/${username}`,
      data: formdata,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const addFirmwareMetadata = (data, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.addFirmwareMetadata}/${
        getAppConfig().Realm
      }/${username}`,
      data: data,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const updateFirmware = (data) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'post',
      url: `${getAppConfig().ApiUrlMapp.updatefirmware}/${
        getAppConfig().Realm
      }/${data.modelid}/${data.type}/${data.protocol}`,
      data: data,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const deleteFirmwareFile = (filename, username) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'delete',
      headers: {
        'Content-Type': 'text/xml',
        Authorization: `Bearer ${getToken()}`,
      },
      url: `${getAppConfig().ApiUrlMapp.deleteFirmwareFile}/${
        getAppConfig().Realm
      }/${username}/${filename}`, //need to give the api
      data: undefined,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getQuoteReq = (reqType) => {
  return new Promise((resolve, reject) =>
    HTTP_Client({
      method: 'get',
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${getToken()}`,
      // },
      url: `http://172.16.1.28:8080/tradeHub/get-all-request-ids?network-id=${
        getAppConfig().Realm
      }&request-type=${reqType}`,
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((e) => {
        return reject(e);
      })
  );
};

const getProductsBusinessandCategory = (
  parentId,
  username,
  contentcategory
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.productlistbasedonusernamecategory}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${contentcategory}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};

const getProductsBusinesCategoryandSubcategory = (
  parentId,
  username,
  contentcategory,
  contentsubcategory
) => {
  return new Promise((resolve, reject) => {
    HTTP_Client({
      method: 'get',
      url: `${getAppConfig().ApiUrlMapp.productlistbasedoncategorysubcategory}${
        getAppConfig().EnterpriseId
      }/${parentId}/${username}/${contentcategory}/${contentsubcategory}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        networkid: `${getAppConfig().EnterpriseId}`,
        orgid: `${username}`,
        suborgid: '',
        username: `${username}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.notification)
          return reject(e.response.data.notification);
        return reject('Internal server error, please try again.');
      });
  });
};
export default {
  getGlobalUsersFromOutside,
  getAssetHealthStatusExe,
  getAssetStatisticsExe,
  getProcessHealthStatusExe,
  // quote request api's
  getContentsBasedonContentCreator,
  getQuoteDataLoopforAssets,
  getQuoteDataLoopforProducts,
  viewQuotes,
  updateRFQStatus,
  getQuotesBasedOnCategory,
  getQuotesBasedOnAssetVerticals,
  orderManagement,
  // End quote request api's
  createSiteType,
  getUserKYCDocuments,
  requestInfoBasedonRequestid,
  updateRegistrationRequestStatus,
  requestStatusBasedonRequestid,
  getUsersRoleRequests,
  upgradeRoleRequest,
  getSuperRoles,
  getUsersRoleRequests,
  updateFirmware,
  deleteFirmwareFile,
  addFirmwareMetadata,
  addFirmwareFile,
  deleteFirmware,
  getFirmwareList,
  //device cloud server apis
  deleteDeviceCloud,
  updateDeviceCloud,
  publishDedicatedCloud,
  publishSharedCloud,
  getDeviceCloudList,
  //end of device cloud server apis
  getContentsBasedonCategoryandSubcat,
  getProductsBusinesCategoryandSubcategory,
  getProductsBusinessandCategory,
  getQuoteReq,
  requestInfoBasedonRequestid,
  getContentsBasedonCreator,
  getContentsBasedonCategoryandSubcat,
  upgradeRoleRequest,
  updateRegistrationRequest,
  createOrder,
  getAllOrderIds1,
  updatePointName,
  assetPointsDelete,
  createSiteType,
  deleteSiteType,
  mapPointWithNode,
  unmapPointWithNode,
  getSolutionDevices,
  createPoint,
  getPoints,
  // asset distributor apis
  getAllOrderIds,
  //end
  /* Usertypes */
  createUserType,
  deleteUserType,

  getProductItemsOfSites,
  buyUrlProduct,
  updateProductInfoURL,
  getRegisterAccountRequests,
  /* site management */
  getNutritionInfo,
  getAssetFeedback,
  getAssetHealthInfo,
  /* Site management */
  /* Markrt apis */
  productItemStatus,
  unMapProductItem,
  mapProductItem,
  deleteProductItem,
  getProductItems,
  productVideo,
  updateLabCertificate,
  updateProductHistoryURL,
  decrementItemCount,
  incrementItemCount,
  updateProductItemCount,
  updateProductRating,
  updateProductCost,
  updateProductDiscountedPrice,
  updateStatusOfProduct,
  updateProductQRURL,
  updateProductCertificateURL,
  updateProductImageURL,
  uploadProductCertificate,
  uploadProductContents,
  /* End of market apis */
  /*Invoice api */
  documentDetailsApi,
  documentListApi,
  saveDocumentsApi,
  updateDocumentsApi,
  reviewDocumentsApi,
  deleteDocumentsApi,
  generateDocumentsApi,
  createQuotationApi,
  createProformaInvoiceApi,
  getOrgId,
  createPurchaseOrderApi,
  createTaxInvoiceApi,
  createDeliveryChallanApi,
  createDeliveryReceiptApi,
  /* End of invoic */
  // document request
  postRequestQuotation,
  getDocumentsById,
  // end of document request
  getSitesBasedOnEmail,

  //start of  LOGISTICS-MANAGER (BIPLOB)
  vehicleStatus,
  lastLocationDetails,
  getBookingDetailsApi,
  getAllBookingDetailsApi,
  postTripMapRequestApi,
  postDriverMapRequestApi,
  postTripUnmappingRequestApi,
  postDriverUnmappingRequestApi,
  delLocation,
  getMonthlyTrips,
  delRoute,
  delTrip,
  getLogisticManagerCount,
  getBookingReq,
  getAssetsCountInfo,
  getDeviceCountInfo,
  getDriversInfo,
  getTripPlannerInfo,
  getLocationInfo,
  addRouteApi,
  getRouteInfo,
  getTripInfo,
  getDrivers,
  getVehicleList,
  addTripApi,
  geofenceDetails,
  createGeofence,
  createBooking,
  getOrderListInfo,
  updateGeofenceOfAssets,
  getDetailsOfLocId,
  updateLocation,
  updateRouteName,
  getDetailsofRouteId,
  getDetailsOfTripId,
  tripSearch,
  getOrderStatus,
  getVehicleTripDetails,
//NEW ADDED APIs:
  updateGeofenceDetails,
  delGeofence,
  geofenceDetailOfAllVehicle,
  addLocationData,
  updateTripStatus,
  updateTripDetails,
  getVehicleSchedule,
  getDriverScheduleForLm,
  // end of (BIPLOB) LOGISTICS-MANAGER

  /*Start of transportUser*/

  // start Rakshit apis(version-1)
  getDriverOrder,
  getOrderStatusApi,
  getAllOrderCount,
  getBookingCount,
  getMyBooking,
  getOrderStatusDetails,
  updateOrderStatus,

// end rakshit apis(version-1)

  // start Rakshit apis(version-2)

  getAllBookingIds,
  updateBookingStatus,
  getBookingDetailsBasedOnBookingId,
  updateBookingStatusRakshitApi,
  getDocLinksForOrder,
  getLatestOrderStatus,
  
  // end of Rakshit apis(version-2)
  getTripDetailsDriver,
  getMyBooking,
  getDriverOrder,
  getDriverSchedule,
  getAllOrderCount,
  getMyorderDetails,
  PostMyOrderStatus,
  getMyRoute,
  getFinalTripStatus,
  getFinalTripStatus1,
  createModel,

  /* node management apis*/
  createNode,
  mappedAssetsWithNode,
  MapLegacyAssetToNode,
  getTuModels,
  getVehiclesBasedOnModel,
  // deleteNode,

  /*start of account upgradation apis*/
  postAccountUpgradationReq,
  getAccountUpgradationList,
  getAccountUpgradationReqStatus,
  

  /*end of account upgradation apis*/
  /*End of transportUser*/

  /* Start of product apis */
  generateQRCodeForProduct,
  deleteModelProductItem,
  addModelProductItem,
  getModelProducts,
  addModelProduct,
  getModelProductsBasedOnModel,
  getModelProductsBasedOnContentSubcategory,
  getAllAssetsBasedonModel,
  /* end ofProduct apis */
  siteVetVisitRequests,
  marketOwners,
  marketPriceRequests,
  AssetCertifiedConsultant,
  contentInfoBasedOnRequestId,
  deleteNode,
  updateNode,
  assetReports,
  getHealthStatusBasedOnAssetId,
  getAttendanceLocationInfo,
  assetFeedbacks,
  getAssetEnvInfo,
  getMortalityInfo,
  getSiteEnvInfo,
  unmapExistingResourceFromRole,
  getSHG,
  communityUsersInfo,
  assetCurrentLocation,
  getGlobalUsers,
  siteEnv,
  recordReport,
  recordCompliance,
  addAssetMortality,
  getAssetMortality,
  settingsInfo,
  operatorOption,
  getAllUserTypes,
  getConsumerUserTypeRole,
  getConsumerUserTypeRoleProfile,
  cancelAdvisoryRequest,
  cancelStoreRequest,
  cancelBuyInputRequest,
  addBuyInputRequest,
  addStoreRequest,
  addAdvisoryRequest,
  getQrCodeForAsset,
  addAssetFeedback,
  addAssetReport,
  dailyReport,
  getAssetIssues,
  addPatrol,
  addAssetHealth,
  createAssetIssue,
  addAttendanceLocation,
  getExecutiveDashboardData,
  getSiteReports,
  getSiteAlarms,
  createSiteIssue,
  addSiteAlarm,
  addAssetAlarm,
  getAssetAlarms,
  siteIssue,
  getRoleResourceAssets,
  getRoleResources,
  getRoleNameofOperator,
  updateOperatorProfileInfo,
  getAssetStatisticsEx,
  getProcessHealthStatusEx,
  getAssetHealthStatusEx,
  generateBulkQrCodes,
  generateQrCode,
  generateSiteQrCode,
  generateQrCodeWithId,
  downloadQrCode,
  getCategoryRelatedSubCategories,
  getListOfCategorys,
  getMyCategoryTender,
  createSiteWithNodeId,
  getNodeInfoBasedOnNodeId,
  getAssetIssueData,
  assetIssueCreate,
  siteCancelLoanReqStatus,
  siteLoanReqStatus,
  siteLoanRequests,
  siteCancelSellReqStatus,
  siteSellReqStatus,
  addSellRequest,
  addLoanRequest,
  addVetRequest,
  siteSellRequests,
  siteCancelGrowReqStatus,
  siteGrowReqStatus,
  addGrowRequest,
  siteGrowRequests,
  siteUpdateAttendanceLocation,
  siteDeleteNewAttendanceLocation,
  siteNewAttendanceLocation,
  siteAttendanceLocationHistory,
  updateGroupNutrition,
  deleteGroupNutrition,
  addNutritionData,
  getGroupNutritionData,
  createDailyReport,
  dailyReportDetails,
  getSiteIssueData,
  siteIssueCreate,
  cancelSellRequest,
  cancelVetRequest,
  cancelLoanRequest,
  cancelGrowRequest,
  viewNodesOfFarmer,
  getFarmerNodeInfo,
  addNodeToFarmer,
  addGrowerRequest,
  // addSellRequest,
  viewNodeStatus,
  getFarmerNodes,
  productionManagersFeedback,
  developerQAFeedback,
  executiveFeedback,
  operatorFeedback,
  /* Events */
  assetFeedbackDetails,
  siteFeedbackDetails,
  updateAssetPatrolingDetails,
  deleteAssetPatrolingDetails,
  addAssetPatrolingDetails,
  updateSitePatrolingDetails,
  deleteSitePatrolingDetails,
  addSitePatrolingDetails,
  addSellingCostDetails,
  getSellingCostDetails,
  getLegacyAssetInfo,
  getSitePatrollingDetails,
  getAssetPatrollingDetails,
  updateAssetOperationalStatusDetails,
  deleteAssetOperationalStatusDetails,
  addAssetOparationalStatusDetails,
  getAssetOptionalStatusDetails,
  updateMortalityDetails,
  deleteMortalityDetails,
  addMortalityDetails,
  getMortalityDetails,
  updateParturitionDetails,
  deleteParturitionDetails,
  addParturitionDetails,
  getParturitionDetails,
  updateWeighingDetails,
  deleteWeighingDetails,
  addWeighingDetails,
  getWeighingDetails,
  updateNutrition,
  deleteNutrition,
  addNutrition,
  getNutrition,
  updateHealthRecord,
  deleteHealthRecord,
  addHealthRecords,
  getHealthRecords,
  updateBirthInfo,
  deleteBirthInfo,
  addBirthInfo,
  getBirthDetails,
  UpdateAttendanceLocation,
  DeleteNewAttendanceLocation,
  NewAttendanceLocation,
  AttendanceLocationHistory,
  updateReadyForOnboardAssetStatus,
  ViewAssetOnboardings,
  /* End Of Events */
  deleteTenderSite,
  deleteContentSubCategory,
  deleteContent,
  getTendersByOrganization,
  getTendersByDistricts,
  /* poleswar */
  allSellRequests,
  allGrowRequests,
  allAdvisoryRequestsByNode,
  allApprovalRequests,
  allApprovalRequestsByNode,
  allLoanRequests,
  allGrowRequestsByNode,
  allVetVisitRequests,
  allSellRequestsByNode,
  allBuyInputRequests,
  allLoanRequestsByNode,
  allStoreRequests,
  allVetVisitRequestsByNode,
  allAdvisoryRequests,
  allBuyInputRequestsByNode,
  allStoreRequestsByNode,
  setNodeStatus,
  viewFarmerInfo,
  getRoleProductionManagersResourceId,
  getProductManagerRole,
  /* end  */
  getAllDistricts,
  viewListOfFarmers,
  viewListProductionManagers,
  getTeamRoleResourceId,
  getTeamRole,
  viewListOfProductionTeams,
  getConsumerInfoRequest,
  getStepperCounter,
  viewListOfExecutives,
  getDevAssetsOfSite,
  getDevRoleResourceId,
  getDevRole,
  viewListOfDeveloperQA,
  getAssetsOfSite,
  getRoleResourceId,
  getRole,
  viewListOfOperators,
  updateSiteInfo,
  siteInfo,
  addSiteRating,
  createSite,
  siteRating,
  siteDeleteHandler,
  assetMapingtoSite,
  getAllAssetsData,
  mappedLegacyAssets,
  viewAllSites,
  createAssetOEM,
  uploadKYC,
  getKYCDocuments,
  updateWhiteLabelStatus,
  getComplaints,
  sendEmail,
  getAssetOEMInfo,
  getAllWhiteLabelRequests,
  getAssetOEMSofConsumerAdmin,
  getAllContentsList,
  assetDelete,
  assetStatusUpdate,
  createMapping,
  createUnMapping,
  createContent,
  createContentSubCategory,
  getCategory,
  createLegacyAsset,
  getAllSites,
  getModelsList,
  getWhiteLabelAssets,
  getAssetTypesList,
  getCategoryTypesList,
  getAssetVerticalsIdList,
  viewListOfDeveloperQA,
  getAssetsOfSite,
  viewListOfOperators,
  updateConsumerUserInfoIndividual,
  resetConsumerUserPasswordIndividual,
  deleteConsumerUserIndividual,
  createConsumerUserOfIndividual,
  updateConsumerNodeStatus,
  getConsumerNodeInfo,
  viewFarmMapRelatedAssets,
  viewAllFarmRelatedAssets,
  StoreReqStatus,
  BuyReqStatus,
  VetReqStatus,
  LoanReqStatus,
  sellReqStatus,
  growReqStatus,
  approvalReqStatus,
  farmsDropDownList,
  viewAllFarms,
  createTenderSite,
  getAllTenderSiteTypes,
  assetMapingtoTenderSite,
  getAllTenderAssetsData,
  mappedTenderLegacyAssets,
  viewAllTenderSites,
  getSellerInfo,
  updateAssetRequestStatusForAd,
  getAssetSellers,
  getAllAssetRequests,
  uploadAgreementForAD,
  getAllSignedAgreementsForAD,
  getAllUnsignedAgreementsForAD,
  getMappedRoles,
  updateRoleOfUser,
  mapRole,
  updateRole,
  deleteRole,
  getRoleProfileBasedOnRoleName,
  createRole,
  getConsumerUserInfoForAD,
  getAllRoles,
  deleteCustomerCare,
  updateCustomerCare,
  createCustomerCare,
  settingsInfoBasedonParent,
  viewSettingsInfo,
  getAllSiteTypes,
  customerSupport,
  updateNodeStatus,
  getNodeInfo,
  trackTheOrder,
  getAssetDistributors,
  getClinicAdmins,
  sendSMS,
  getLogisticProviderInfo,
  getLogisticProviders,
  updateConsumerUserInfo,
  deleteConsumerUser,
  resetConsumerUserPassword,
  getAllConsumerUsers,
  createConsumerUser,
  getProcessHealthStatus,
  getAssetHealthStatus,
  getAllAssetsOfAssetDeveloper,
  createAssetOEM,
  uploadKYC,
  getKYCDocuments,
  updateWhiteLabelStatus,
  getComplaints,
  sendEmail,
  getAssetOEMInfo,
  getAllWhiteLabelRequests,
  getAssetOEMSofConsumerAdmin,
  getCategoryTender,
  assetDelete,
  assetStatusUpdate,
  getParentId,
  getSubCategoriesForAD,
  createContentSubCategory,
  createLegacyAsset,
  getAllSites,
  getWhiteLabelAssetsForAD,
  doLogin,
  doLogout,
  doLogoutAndRedirect,
  isLoggedIn,
  setLocalData,
  getLocalData,
  getToken,
  getConsumerUserType,
  doSignup,
  createAccessToken,
  doFarmerSignup,
  getCategoriesBasedOnOEM,
  getSubCategoriesForOEM,
  bankStatus,
  getUserJourney,
  bookNowPayment,
  whiteLabelAssetsBasedonModel,
  whiteLabelAssetsBasedonAssetDeveloper,
  makePayment,
  createPaymentLink,
  preMatureSale,
  getSitesDetails,
  getOrderList,
  getSignedAgreements,
  getUnsignedAgreements,
  withdrawMoneyFromWallet,
  addMoneyToWallet,
  getInvestedModels,
  schemeFeedback,
  getUserImag,
  getAllAssociatedSolutions,
  getAllAssociatedSolutionSites,
  modelFeedback,
  updateBankDetails,
  uploadUSerImage,
  updateNominee,
  likeCount,
  getFarmUpdatesFromAll,
  uploadAgreement,
  uploadKyc,
  getFarmUpdates,
  getApps,
  addToCart,
  getAppConfig,
  doLogin,
  isLoggedIn,
  getToken,
  getConsumerUserInfo,
  doResetPwd,
  doChangePwd,
  getGrowerList,
  getNomineeList,
  addNominee,
  deleteNominee,
  getGrowerIdList,
  getCategoryTypesList,
  myAssetsList,
  getAssetDetails,
  getSitesList,
  getSitesAssetsList,
  getAssetInfo,
  whiteLabelAssetsBasedonSite,
  sendWhitelabelRequest,
  getSolutionAssets,
  getSolutionFeedbacks,
  getSiteComplaints,
  getDashboardData,
  getAssetsCount,
  getWhiteLabelAsserts,
  getAssetOEMData,
  getProfitSummary,
  getStatementSummary,
  getProfitStatementSummary,
  getAllSolutionsSite,
  getAllSolutions,
  getWalletBalance,
  getBankDetails,
  getKycDocument,
  addBankDetails,
  updateUserInfo,
  paymentHistory,
  doFarmerSignup,
  getGrowerDetails,
  findCartItems,
  getModelDetails,
  deleteAssetFromCart,
  assetDeveloperFeedback,
  siteFeedback,
  assetFeedback,
  // storage manager
  GetStatusOfStorage,
  UpdateStorageStatus,
  getFeedbackManager,
  getAttendenceManager,
};
