import React, { useState, useEffect } from "react";
import Map from "../Map";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Typography, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MainService from "../../../../Common/MainService";
const ConsciousnessChecker = ({ location, markerPostion, cardData,trip1 }) => {
  const [randomNumber, setRandomNumber] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isConscious, setIsConscious] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [getMap, setGetMap] = useState(false);
  const [startGameButton, setStartGameButton] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [status, setStatus] = useState("Not Loaded");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially disable the button
  const [tripRoute, setTripRoute] = useState(null);
  const [secondApiData, setSecondApiData] = useState(null);
  const history = useHistory();

  const generateRandomNumber = () => {
    const random = Math.floor(Math.random() * 10000000000);
    setRandomNumber(random.toString().padStart(10, "0"));
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsCheckboxChecked(isChecked);
    if (isChecked) {
      setStatus("Loaded");
      setIsButtonDisabled(false); // Enable the button when checked and status is "Loaded"
    } else {
      setStatus("Not Loaded");
      setIsButtonDisabled(true); // Disable the button when unchecked
    }
  };

  const handleSubmit = () => {
    if (userInput === randomNumber) {
      setIsConscious(true);
      setIsActive(false);
      clearTimeout(timeoutId);

      setGetMap(true);
    }
    fetchRouteDetails ()
  };


  useEffect(() => {
    const fetchTripDetails = async () => {
      try {
        const data = await MainService.getTripDetailsDriver(trip1);
        console.log(data,"ddddddddddddddddddddddddddddddddddd")
        setTripRoute(data.routeId);


      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchTripDetails();
  }, []);

  const fetchRouteDetails = async () => {
    try {
      const data = await MainService.getMyRoute(tripRoute); // Make sure this API is correct
      console.log(data, "Route Details11111111111111111111111");
      setSecondApiData(data); // Save second API response
    } catch (error) {
      console.error('Error fetching route details:', error);
    }
  };



  const startGame = () => {


    setIsActive(true);
    setIsConscious(false);
    generateRandomNumber();
    setStartGameButton(false);
  };

  const [timer, setTimer] = useState(20);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setIsActive(false);
      clearInterval(intervalId);

      history.push("/main/dashboardForDriver");
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, timer, history]);

  useEffect(() => {
    if (isActive) {
      setTimer(20);
    }
  }, [isActive]);

  return (
    <>
      {startGameButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button variant="contained" color="primary" onClick={startGame}>
            Click here to Start the game
          </Button>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ maxWidth: "400px" }}>
          {isActive && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "2rem",
              }}
            >
              <Typography variant="body1">
                Type the following number within {timer} seconds:
              </Typography>
              <Typography variant="h4">{randomNumber}</Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  variant="outlined"
                  value={userInput}
                  onChange={handleUserInput}
                  style={{ marginRight: "1rem", width: "10rem" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "8rem" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>

      {isConscious && getMap && (
        <div style={{ marginTop: "2rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              />
            }
            label="Enable Get Route (Check to load status)"
          />
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Status: {status}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => console.log("Get Route Clicked")}
            disabled={isButtonDisabled} // Controlled by state
          >
            Get Route
          </Button>
          <Map
            location={location}
            MarkerMap={markerPostion}
            cardData={cardData}
            startTripBtn={true}
          />
        </div>
      )}
    </>
  );
};

export default ConsciousnessChecker;
