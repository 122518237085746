import { useState, useEffect } from "react";
import MainService from "../../Common/MainService";
import Map from "../TransportUser/components/Map";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  Modal
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ReceiptIcon from "@mui/icons-material/Receipt";
import OrderStatusIcon from "./MyIcons/delivery-status.png";
import InvoiceIcon from "./MyIcons/invoice_12266888 (1).png";
import MapView from "./MyIcons/map_717498.png";
import BookingStatus from "./MyIcons/online.png";
import ConsciousnessChecker from "../TransportUser/components/ConsiousnessChecker/ConsciousnessChecker";

const CenteredContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingContainer = styled(CenteredContainer)`
  flex-direction: column;
  background-color: #f2f2f2;
`;

const LoadingText = styled(Typography)`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #555555;
  text-align: center;
`;

const LoadingSpinner = styled(CircularProgress)`
  color: #2196f3;
`;

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  transition: "0.3s",
  "&:hover": {
    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
  },
  borderRadius: "16px",
  border: "2px solid #ECEFF1",
});

const Title = styled(Typography)({
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  background: "cornflowerblue",
  color: "white",
  padding: "10px",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
});

const ContentText = styled(Typography)({
  marginBottom: "10px",
  color: "#555555",
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "auto",
  marginBottom: "1rem",
});

const BookingstatusOptions = [
  "Booked",
  "Assigned",
  "Accepted",
  "Rejected",
  "Scheduled_For_Pickup",
  "Loaded",
  "Picked_Up",
  "In_Transit",
  "Arrived_At_Destination",
  "Unloaded",
  "Out_For_Delivery",
  "Delivered",
  "Failed_Delivery",
  "Returned",
];

const orderStatusOptions = [
  "UNLOADED",
  "DISPATCH",
  "LOADED",
  "IN_TRANSIT",
  "DELIVERED",
];

const MyBookings = ({ handleBack }) => {
  useEffect(() => {
    console.log(handleBack, "handleBack::::");
  }, [handleBack]);

  const history = useHistory();
  const [bookings, setBookings] = useState([]);
  const { user } = MainService.getLocalData();
  const { username, parentId } = user;

  // Initialize state variables for MarkerMap and center
  const [MarkerMap, setMarkerMap] = useState([]);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [getMap, setGetMap] = useState(false);
  const [bookClose, setBookClose] = useState(false);
  const [back, setBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [updatedTimestamp, setUpdatedTimestamp] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderLatitude, setOrderLatitude] = useState("");
  const [orderLongitude, setOrderLongitude] = useState("");
  const [orderUpdatedTimestamp, setOrderUpdatedTimestamp] = useState("");
  const [lastUpdatedOrderStatus, setlastUpdatedOrderStatus] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [trip1,setTrip1]=useState("")
  
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false); // Close the error modal
  };
const [check,setCheck]=useState(false)

const [showCheck,setShowCheck]=useState(true)
  useEffect(() => {
    if (orderDialogOpen) {
      // Get current date and time in IST (UTC+5:30)
      const currentISTDateTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 16);
      setOrderUpdatedTimestamp(currentISTDateTime);
    }
  }, [orderDialogOpen]);

  useEffect(() => {
    if (dialogOpen) {
      // Get current date and time in IST (UTC+5:30)
      const currentISTDateTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 16); // Format to 'YYYY-MM-DDTHH:mm'
      setUpdatedTimestamp(currentISTDateTime); // Set default value to IST date and time
    }
  }, [dialogOpen]); // Trigger this effect whenever the modal opens


  
  const handleMap = (props) => {
    setGetMap(true);
    setBookClose(true);
    setBack(true);
    const testMapData = [
      { lat: props.fromLocationLat, lng: props.fromLocationLng },
      { lat: props.toLocationLat, lng: props.toLocationLng },
    ];
    setMarkerMap(testMapData); // Set the MarkerMap state

    const centerLat = (props.fromLocationLat + props.toLocationLat) / 2;
    const centerLng = (props.fromLocationLng + props.toLocationLng) / 2;
    setCenter({ lat: centerLat, lng: centerLng }); // Set the center state
  };

  const getMyBooking = async () => {
    setLoading(true);
    try {
      const data = await MainService.getMyBooking(username, parentId);
      setBookings(data.bookings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching bookings:", error);
    }
  };

  const handleInvoiceClick = async (bpoId) => {
    try {
      const response = await MainService.getDocLinksForOrder(
        parentId,
        username,
        bpoId
      );
      if (response?.url) {
        window.open(response.url, "_blank");
      } else {
        console.error("No URL found in response");
      }
    } catch (error) {
      console.error("Error fetching document link:", error);
    }
  };

  const handleMapBack = () => {
    setBookClose(false);
    setBack(true);
    setGetMap(!getMap);
  };

  const handleStatusUpdate = async () => {
    if (selectedBooking && status) {
      try {
        await MainService.updateBookingStatusRakshitApi(
          username,
          selectedBooking.bookingId,
          {
            bookingStatus: status,
            latitude,
            longitude,
            updatedTimestamp,
            bookingId: selectedBooking.bookingId,
          }
        );
        setDialogOpen(false);
        setStatus(""); // Clear status after successful update
        setLatitude(""); // Clear latitude after update
        setLongitude(""); // Clear longitude after update
        setUpdatedTimestamp(""); // Clear timestamp after update
        setBookingId("");
        getMyBooking();
      } catch (error) {
        console.error("Error updating booking status:", error);
      }
    }
  };

  // Assuming you have imported HTTP_Client and getAppConfig functions
  const getLatestOrderStatus = async (parentId, username, bpoId) => {
    try {
      const response = await MainService.getLatestOrderStatus(
        parentId,
        username,
        bpoId
      );
      setOrderDialogOpen(true);
      return response.orderStatus; // Adjust this line according to your API response structure
    } catch (error) {
      console.error("Error fetching order status:", error);
      setErrorMessage("400 BAD REQUEST.");
      setOpenErrorModal(true); // Open error modal
      return null;
    }
  };

  const handleOrderStatusUpdate = async () => {
    if (selectedBooking && orderStatus) {
      try {
        const response = await MainService.updateOrderStatus(
          username,
          selectedBooking.bpoId,
          {
            orderStatus,
            orderId: selectedBooking.bpoId,
            latitude: orderLatitude,
            longitude: orderLongitude,
            updatedTimestamp: orderUpdatedTimestamp,
          }
        );

        setOrderDialogOpen(false);
        setOrderStatus("");

        setOrderId("");
        setOrderLatitude("");
        setOrderLongitude("");
        setOrderUpdatedTimestamp("");
        getMyBooking();
      } catch (error) {
        console.error("Error updating order status:", error);
      }
    }
  };

  const openOrderDialog = async (booking) => {
    try {
      setSelectedBooking(booking);
  
      setOrderStatus(booking.orderStatus || "");
      setOrderLatitude(booking.orderLatitude || ""); 
      setOrderLongitude(booking.orderLongitude || "");
      setOrderUpdatedTimestamp(""); 
  
      const latestOrderStatus = await getLatestOrderStatus(
        parentId,
        username,
        booking.bpoId
      );
      setlastUpdatedOrderStatus(latestOrderStatus || booking.orderStatus); // Use fetched status or existing one
  
      
    } catch (error) {
      console.error("404 bad", error);
    }
  }
  
  const handleCheck=(booking)=>{
  //  console.log(booking,"zeeeeeeeeeeeeeeee")
   setTrip1(booking.tripId)
  setCheck(true)
  setShowCheck(false)
  
  }


  const handleCloseOrderDialog = () => {
    setOrderDialogOpen(false);
    setOrderLatitude("");
    setOrderLongitude("");
    setOrderUpdatedTimestamp("");
    setOrderStatus("");
  };

  

  const openStatusDialog = async (booking) => {
    setSelectedBooking(booking);
    setStatus(booking.bookingStatus);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    getMyBooking();
  }, []);

  return (
    <>
   {showCheck&& <div style={{ padding: "10px" }}>
        {loading ? (
          <LoadingContainer>
            <LoadingSpinner />
            <LoadingText>Loading....</LoadingText>
          </LoadingContainer>
        ) : (
          !bookClose &&
          !loading && (
            <Grid container spacing={2}>
     {bookings.map((booking, index) => (
  <Grid item xs={12} sm={4} key={index}>
    <StyledCard>
      <CardContent>
        <Title color="textSecondary" variant="h6">
          BOOKING NO. {index + 1}
        </Title>
        <ContentText variant="body1">
          <strong>Booking ID:</strong> {booking.bookingId}
        </ContentText>
        <ContentText variant="body1">
          <strong>Order ID:</strong> {booking.bpoId}
        </ContentText>

        <ContentText variant="body1">
          <strong>Booking Status:</strong>{" "}
          <Button variant="contained" color="success">
            {booking.bookingStatus}
          </Button>
        </ContentText>

        <ContentText variant="body1">
          <strong>Trip ID:</strong> {booking.tripId}
        </ContentText>

        <ContentText variant="body1">
          <strong>From Location:</strong>{" "}
          {booking.fromLocationLat}, {booking.fromLocationLng}
        </ContentText>
        <ContentText variant="body1">
          <strong>To Location:</strong> {booking.toLocationLat},{" "}
          {booking.toLocationLng}
        </ContentText>

        <ContentText variant="body1">
          <strong>Consignor Address:</strong>{" "}
          {booking.consignorAddress}
        </ContentText>

        <ContentText variant="body1">
          <strong>Consignee Address:</strong>{" "}
          {booking.consigneeAddress}
        </ContentText>
        <ContentText variant="body1">
          <strong>Pickup Site Address:</strong>{" "}
          {booking.pickupSiteAddress}
        </ContentText>
        <ContentText variant="body1">
          <strong>Delivery Site Address:</strong>{" "}
          {booking.deliverySiteAddress}
        </ContentText>
        <ContentText variant="body1">
          <strong>Timestamp:</strong> {booking.timestamp}
        </ContentText>
      </CardContent>
      <ButtonContainer>
        <Tooltip
          title={<span style={{ fontSize: "15px" }}>Map View</span>}
        >
          <Button variant="text">
            <img
              src={MapView}
              alt="view map"
              style={{
                height: "3rem",
                width: "3rem",
                cursor: "pointer",
                transition: "transform 0.3s",
              }}
              onClick={() => handleMap(booking)}
            />
          </Button>
        </Tooltip>

        {/* Conditional Rendering for Update Booking Status */}
        <Tooltip
          title={
            <span style={{ fontSize: "15px" }}>
              Update Booking Status
            </span>
          }
        >
        <Button
  variant="text"
  disabled={booking.bookingStatus === "Rejected"} // Disable button when status is "Rejected"
  style={{
    opacity: booking.bookingStatus === "Rejected" ? 0.5 : 1, // Reduce opacity when disabled
    cursor: booking.bookingStatus === "Rejected" ? "not-allowed" : "pointer", // Change cursor when disabled
    pointerEvents: booking.bookingStatus === "Rejected" ? "none" : "auto", // Disable interaction when "Rejected"
  }}
>
  <img
    src={BookingStatus}
    alt="update booking status"
    style={{
      height: "3rem",
      width: "3rem",
      transition: "transform 0.3s",
      transform: booking.bookingStatus === "Rejected" ? "scale(0.9)" : "scale(1)", // Scale down image when disabled
    }}
    onClick={() => booking.bookingStatus !== "Rejected" && openStatusDialog(booking)} // Ensure the click only happens if not "Rejected"
  />
</Button>

        </Tooltip>

        <Tooltip
          title={<span style={{ fontSize: "15px" }}>View Invoice</span>}
        >
          <Button variant="text">
            <img
              src={InvoiceIcon}
              alt="view invoice"
              style={{
                height: "3rem",
                width: "3rem",
                cursor: "pointer",
                transition: "transform 0.3s",
              }}
              onClick={() => handleInvoiceClick(booking.bookingId)}
            />
          </Button>
        </Tooltip>

        <Tooltip
          title={
            <span style={{ fontSize: "15px" }}>
              Update Order Status
            </span>
          }
        >
          <Button variant="text">
            <img
              src={OrderStatusIcon}
              alt="update order status"
              style={{
                height: "3rem",
                width: "3rem",
                cursor: "pointer",
                transition: "transform 0.3s",
              }}
              onClick={() => openOrderDialog(booking)}
            />
          </Button>
        </Tooltip>
        <Button  
        onClick={()=>handleCheck(booking)}
        
        >track</Button>
      </ButtonContainer>
    </StyledCard>
  </Grid>
))}


            </Grid>
          )
        )}

        {getMap && (
          <Map
            location={[]}
            MarkerMap={MarkerMap}
            handleMapBack={handleMapBack}
            showBackButton={true}
          />
        )}

        {/* Update Order Status Dialog */}
        <Dialog open={orderDialogOpen} onClose={handleCloseOrderDialog}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "blue",
            }}
          >
            Update Order Status
          </DialogTitle>
          <DialogContent>
            <Typography>
              Last updated status:{" "}
              <b style={{ color: "green" }}>{lastUpdatedOrderStatus}</b>
            </Typography>
            <Select
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {orderStatusOptions.map((status) => (
                <MenuItem
                  key={status}
                  value={status}
                  disabled={status === lastUpdatedOrderStatus} // Disable the last updated status
                >
                  {status}
                </MenuItem>
              ))}
            </Select>

            {/* Latitude Field */}
            <TextField
              label="Latitude"
              type="number"
              value={orderLatitude}
              onChange={(e) => setOrderLatitude(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            {/* Longitude Field */}
            <TextField
              label="Longitude"
              type="number"
              value={orderLongitude}
              onChange={(e) => setOrderLongitude(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            {/* Timestamp Field */}
            <TextField
              id="datetime-local"
              type="datetime-local"
              label="Updated Timestamp"
              value={orderUpdatedTimestamp}
              onChange={(e) => setOrderUpdatedTimestamp(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button color="error" onClick={handleCloseOrderDialog}>
                Close
              </Button>

              <Button
                onClick={handleOrderStatusUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        {/* Update Booking Status Dialog */}
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{
              color: "blue",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Update Booking Status
          </DialogTitle>
          <DialogContent>
            <Typography>
              Last updated status: <b style={{ color: "green" }}>{status}</b>{" "}
            </Typography>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {BookingstatusOptions.filter((option) => option !== status) // Exclude the current status
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>

            {/* Latitude Field */}
            <TextField
              label="Latitude"
              type="number"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            {/* Longitude Field */}
            <TextField
              label="Longitude"
              type="number"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            {/* Timestamp Field */}
            <TextField
              id="datetime-local"
              type="datetime-local"
              label="Updated Timestamp"
              value={updatedTimestamp}
              onChange={(e) => setUpdatedTimestamp(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button color="error" onClick={handleCloseDialog}>
                Close
              </Button>

              <Button
                onClick={handleStatusUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>}   

      <div>
      {/* Your other components */}

      {/* Error Modal */}
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{
          position: 'absolute', top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', boxShadow: '0px 4px 6px rgba(0,0,0,0.1)', borderRadius: '8px'
        }}>
          <Typography variant="h6" id="error-modal-title">Error</Typography>
          <Typography variant="body2" id="error-modal-description">{errorMessage}</Typography>
          <Button onClick={handleCloseErrorModal} color="primary">Close</Button>
        </div>
      </Modal>

    </div>

    {check && <ConsciousnessChecker  trip1={trip1}  />}
    </>
  );
};

export default MyBookings;
